import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { passReset } from '../../../redux/actions/authActions';

const TabSecurity = (props) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);

  const [pass, setPass] = useState('');
  const [pass1, setPass1] = useState('');
  const [showPass, setShowPass] = useState(false);

  const handleShowPass = useCallback(() => {
    setShowPass(!showPass);
  }, [showPass]);

  const changePass = useCallback(() => {
    const data = {
      password: pass,
      password2: pass1,
      email: profile.profile.user.email,
    };
    dispatch(passReset(data));
  }, [pass, pass1, profile, dispatch]);
  return (
    <div className='card large'>
      <div className='card-content'>
        <div className='row'>
          <div className='col s12'>
            <h6>Cambiar Contraseña</h6>
          </div>
        </div>
        <div className='row'>
          <div className='col s12 m4 input-field'>
            <input
              type={showPass ? 'text' : 'password'}
              name='pass'
              id='pass'
              className='validate'
              onChange={(e) => setPass(e.target.value)}
            />
            <label htmlFor='pass'>Contraseña</label>
          </div>
          <div className='col s12 m4 input-field'>
            <input
              type={showPass ? 'text' : 'password'}
              name='pass1'
              id='pass1'
              className='validate'
              onChange={(e) => setPass1(e.target.value)}
            />
            <label htmlFor='pass1'>Cofirmar Contraseña</label>
          </div>
          <div className='input-field col s12 m2'>
            <button
              className='btn-flat white waves-effect'
              onClick={handleShowPass}
            >
              <i className='material-icons'>
                {showPass ? 'visibility' : 'visibility_off'}
              </i>
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col s12'>
            <p className='grey-text text-lighten-1'>
              La contraseña tiene que tener como minimo de 8 caracteres, una
              letra mayuscula y un digito.
            </p>
          </div>
        </div>
      </div>
      <div className='card-action'>
        <button className='btn-flat right blue white-text' onClick={changePass}>
          Guardar
        </button>
      </div>
    </div>
  );
};

export default TabSecurity;
