import React from 'react';

const MiniCard = ({
  appointment,
  handleAppointmentDetails,
  finishAppointment,
  toggleBusyHour,
}) => {
  return (
    <div className='card mini-card'>
      <div className='mini-card-free-busy'>
        <div className='switch'>
          <label>
            <input
              type='checkbox'
              onChange={() => toggleBusyHour(appointment)}
              checked={appointment.appointment === null ? true : false}
            />
            <span className='lever' />
          </label>
        </div>
      </div>
      <div className='mini-card-title'>
        <span className='truncate white-text'>
          {appointment.appointment === null
            ? 'Hora no disponible'
            : appointment.appointment.clientName}
        </span>
        <span className='white-text'>
          {appointment.appointment === null
            ? 'Hora no disponible'
            : appointment.appointment.hour}
        </span>
      </div>
      <div className='mini-card-content'>
        <div className='mini-card-content-title'>
          <span className='truncate'>
            {appointment.appointment === null
              ? 'Hora no disponible'
              : appointment.appointment.title}
          </span>
        </div>
        <div className='mini-card-content-text'>
          <span>
            {appointment.appointment === null
              ? 'Has desactivado esta hora para clientes.'
              : appointment.appointment.description}
          </span>
        </div>
      </div>
      {appointment.appointment !== null && (
        <div className='mini-card-action'>
          <button
            className='btn-flat white red-text waves-effect'
            onClick={() => handleAppointmentDetails(appointment.appointment)}
          >
            ver mas...
          </button>
          {appointment.appointment.isActive ? (
            <button
              className='btn-flat white red-text waves-effect'
              onClick={() => finishAppointment(appointment.appointment._id)}
            >
              finalizar
            </button>
          ) : (
            <button disabled className='btn-flat white red-text waves-effect'>
              finalizada
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default MiniCard;
