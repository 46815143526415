import React, { useEffect, useCallback, useState } from 'react';
import M from 'materialize-css';
import { useDispatch, useSelector } from 'react-redux';
import { closeUserAccount } from '../../../redux/actions/authActions';
import { updateNotif } from '../../../redux/actions/profileActions';
import isEmpty from 'is-empty';

const TabSettings = (props) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);

  const [receiveAds, setReceiveAds] = useState(false);
  const [receiveNotifications, setReceiveNotifications] = useState(false);

  useEffect(() => {
    const modal = document.querySelectorAll('.modal');
    M.Modal.init(modal, {});
  }, []);

  useEffect(() => {
    if (!isEmpty(profile.profile)) {
      setReceiveAds(profile.profile.notifications.ads);
      setReceiveNotifications(profile.profile.notifications.thirdParty);
    }
  }, [profile]);

  const saveNotifications = useCallback(() => {
    const data = {
      ads: receiveAds,
      thirdParty: receiveNotifications,
    };
    dispatch(updateNotif(data));
  }, [dispatch, receiveAds, receiveNotifications]);

  const closeAccount = useCallback(() => {
    dispatch(closeUserAccount());
  }, [dispatch]);
  return (
    <div className='card large'>
      <div className='card-content'>
        <div className='row'>
          <div className='col s12'>
            <h6>Notificaciones</h6>
          </div>
        </div>
        <div className='row'>
          <div className='col s12'>
            <p>
              <label>
                <input
                  type='checkbox'
                  checked={receiveAds}
                  onChange={() => setReceiveAds(!receiveAds)}
                />
                <span>Quiero recibir publicidad y ofertas especiales</span>
              </label>
            </p>
            <p>
              <label>
                <input
                  type='checkbox'
                  checked={receiveNotifications}
                  onChange={() =>
                    setReceiveNotifications(!receiveNotifications)
                  }
                />
                <span>
                  Quiero recibir notificaciones por correo sobre mis consultas
                </span>
              </label>
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col s12'>
            <h6>Eliminar cuenta</h6>
          </div>
          <div className='col s12'>
            <a
              href='#closeAccountModal'
              className='btn-flat red white-text waves-effect modal-trigger'
            >
              Eliminar cuenta
            </a>
            <div className='modal' id='closeAccountModal'>
              <div className='modal-content'>
                <div className='row'>
                  <div className='col s12 center'>
                    <h6>CERRAR CUENTA</h6>
                    <i className='material-icons red-text large'>info</i>
                    <p className='red-text'>
                      Recuerda que una vez eliminada tu cuenta no podras acceder
                      a tus ficheros o hacer ninguna gestion y SERAN ELIMINADOS
                      PARA SIEMPRE.
                    </p>
                    <p className='red-text'>
                      ESTAS SEGURO DE QUE QUIERES CERRAR TU CUENTA?
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col s12'>
                    <button className='btn-flat blue white-text left waves-effect modal-close'>
                      NO
                    </button>
                    <button
                      className='btn-flat red white-text right waves-effect modal-close'
                      onClick={closeAccount}
                    >
                      SI
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card-action'>
        <button
          className='btn-flat waves-effect blue white-text right'
          onClick={saveNotifications}
        >
          Guardar
        </button>
      </div>
    </div>
  );
};

export default TabSettings;
