import {
  GET_APPOINTMENT,
  GET_APPOINTMENTS,
  SET_LOADING_APPOINTMENT,
  GET_ERRORS,
  GET_CALENDAR,
  LOADING_CALENDAR,
  CREATE_NEW_APPOINTMENT,
  SET_SUCCESS_MSG,
  PAY_INTENT,
} from './types';
import axios from 'axios';

export const createAppointment4Client = (data) => (dispatch) => {
  dispatch(loadingAppointment());
  axios
    .post('/admin/4l1n4/appointments/appointment/add', data)
    .then((res) => dispatch({ type: SET_SUCCESS_MSG, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const createAppointment = (appointment) => (dispatch) => {
  dispatch(loadingAppointment());
  axios
    .post('/appointments/appointment/new', appointment)
    .then((res) => {
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
      dispatch({ type: PAY_INTENT, payload: res.data.payData });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const createNewAppointment = (data) => {
  return { type: CREATE_NEW_APPOINTMENT, payload: data };
};
export const getAppointment = (appointmentID) => (dispatch) => {
  dispatch(loadingAppointment());
  axios
    .get(`/appointments/appointment/${appointmentID}`)
    .then((res) => dispatch({ type: GET_APPOINTMENT, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
export const _getAppointment = (appointmentID) => (dispatch) => {
  dispatch(loadingAppointment());
  axios
    .get(`/admin/4l1n4/appointments/appointment/${appointmentID}`)
    .then((res) => dispatch({ type: GET_APPOINTMENT, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
export const _deleteAppointment = (appointmentID) => (dispatch) => {
  axios
    .get(`/admin/4l1n4/appointments/appointment/delete/${appointmentID}`)
    .then((res) => dispatch({ type: SET_SUCCESS_MSG, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
export const _updateAppointment = (data) => (dispatch) => {
  axios
    .post(`/admin/4l1n4/appointments/appointment/update`, data)
    .then((res) => dispatch({ type: SET_SUCCESS_MSG, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
export const _finishAppointment = (appointmentID) => (dispatch) => {
  axios
    .get(`/admin/4l1n4/appointments/appointment/finish/${appointmentID}`)
    .then((res) => dispatch({ type: SET_SUCCESS_MSG, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
export const getAppointments = () => (dispatch) => {
  dispatch(loadingAppointment());
  axios
    .get('/appointments/all')
    .then((res) => dispatch({ type: GET_APPOINTMENTS, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const _getAppointmentsByClientID = (clientID) => (dispatch) => {
  dispatch(loadingAppointment());
  axios
    .get(`/admin/4l1n4/appointments/${clientID}`)
    .then((res) => dispatch({ type: GET_APPOINTMENTS, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
export const _getAppointments = () => (dispatch) => {
  dispatch(loadingAppointment());
  axios
    .get('/admin/4l1n4/appointments/all')
    .then((res) => dispatch({ type: GET_APPOINTMENTS, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const _getCalendar = () => (dispatch) => {
  dispatch(loadingCalendar());
  axios
    .get('/admin/4l1n4/calendar')
    .then((res) => dispatch({ type: GET_CALENDAR, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const _getCalendarNext = (days) => (dispatch) => {
  dispatch(loadingCalendar());
  axios
    .get(`/admin/4l1n4/calendar/next/${days}`)
    .then((res) => dispatch({ type: GET_CALENDAR, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
export const _markDayOff = (dayID) => (dispatch) => {
  dispatch(loadingCalendar());
  axios
    .post(`/admin/4l1n4/calendar/dayoff`, dayID)
    .then((res) => {
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
      dispatch({ type: GET_CALENDAR, payload: res.data.calendar });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
export const _markHourBusy = (data) => (dispatch) => {
  dispatch(loadingCalendar());
  axios
    .post(`/admin/4l1n4/calendar/hour/busy`, data)
    .then((res) => {
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
      dispatch({ type: GET_CALENDAR, payload: res.data.calendar });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
export const getCalendarForDay = (day) => (dispatch) => {
  dispatch(loadingCalendar());
  axios
    .get(`/appointments/calendar/${day}`)
    .then((res) => dispatch({ type: GET_CALENDAR, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const loadingCalendar = () => {
  return { type: LOADING_CALENDAR };
};
export const loadingAppointment = () => {
  return { type: SET_LOADING_APPOINTMENT };
};
