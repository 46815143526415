import {
  ADD_CLIENT,
  GET_ALL_CLIENTS,
  GET_CLIENT,
  CLIENT_LOADING,
  DELETE_CLIENT,
} from '../actions/types';

const initialState = {
  client: {},
  clients: [],
  client_loading: false,
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CLIENT:
      return {
        ...state,
        client_loading: false,
        client: action.payload,
      };
    case GET_ALL_CLIENTS:
      return {
        ...state,
        client_loading: false,
        clients: action.payload,
      };
    case GET_CLIENT:
      return {
        ...state,
        client_loading: false,
        client: action.payload,
      };
    case DELETE_CLIENT:
      return {
        ...state,
        client_loading: false,
        client: action.payload,
      };
    case CLIENT_LOADING:
      return {
        ...state,
        client_loading: true,
      };
    default:
      return state;
  }
};
export default clientReducer;
