import React from 'react';
import ProgressStep1 from './ProgressStep1';
import ProgressStep2 from './ProgressStep2';
import ProgressStep3 from './ProgressStep3';
import ProgressStep4 from './ProgressStep4';

const PaymentProgress = ({ activeStep }) => {
  let result = <ProgressStep1 />;
  if (activeStep === 1) {
    result = <ProgressStep1 />;
  }
  if (activeStep === 2) {
    result = <ProgressStep2 />;
  }
  if (activeStep === 3) {
    result = <ProgressStep3 />;
  }
  if (activeStep === 4) {
    result = <ProgressStep4 />;
  }
  return result;
};

export default PaymentProgress;
