import React, { useEffect, useState } from 'react';
import MainContext from './MainContext';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, clearSuccess } from '../../redux/actions/miscActions';
import M from 'materialize-css';
import isEmpty from 'is-empty';
import { Caches } from '../../utils/Caches';

const MainContextProvider = (props) => {
  const dispatch = useDispatch();
  const [logosImages] = useState(Caches.logos);
  const [authImages] = useState(Caches.auth);
  const [landingImages] = useState(Caches.landing);
  const [despachoImages] = useState(Caches.despacho);
  const [areasImages] = useState(Caches.areas);
  const [consultaImages] = useState(Caches.consulta);
  const [accountImages] = useState(Caches.account);
  const [contactImages] = useState(Caches.contact);
  const success = useSelector((state) => state.success);
  const errors = useSelector((state) => state.errors);

  useEffect(() => {
    if (!isEmpty(success) && success.success) {
      M.toast({
        html: `${success.msg}`,
        classes: 'green white-text',
      });
      setTimeout(() => {
        dispatch(clearSuccess());
      }, 5000);
    }
    if (errors !== 'undefined') {
      if (!isEmpty(errors) && !errors.success && !errors.hidden) {
        M.toast({ html: `${errors.msg}`, classes: 'red white-text' });
        setTimeout(() => {
          dispatch(clearErrors());
        }, 5000);
      }
    }
  }, [success, dispatch, errors]);

  return (
    <MainContext.Provider
      value={{
        logosImages,
        authImages,
        landingImages,
        despachoImages,
        areasImages,
        consultaImages,
        accountImages,
        contactImages,
      }}
    >
      {props.children}
    </MainContext.Provider>
  );
};

export default MainContextProvider;
