import {
  GET_ERRORS,
  GET_ALL_CLIENTS,
  CLIENT_LOADING,
  GET_CLIENT_FILES,
  SET_SUCCESS_MSG,
} from './types';
import axios from 'axios';
import download from 'downloadjs';

export const createNewCase = (data) => (dispatch) => {
  axios
    .post('/admin/4l1n4/clients/client/case/new', data)
    .then((res) => {
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
      dispatch({ type: GET_ALL_CLIENTS, payload: res.data.clients });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const closeCase = (caseID) => (dispatch) => {
  axios
    .post('/admin/4l1n4/clients/client/case/close', caseID)
    .then((res) => {
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
      dispatch({ type: GET_ALL_CLIENTS, payload: res.data.clients });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const suspendUser = (userID) => (dispatch) => {
  axios
    .post('/admin/4l1n4/clients/client/suspend', userID)
    .then((res) => {
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
      dispatch({ type: GET_ALL_CLIENTS, payload: res.data.clients });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
export const deleteUser = (userID) => (dispatch) => {
  axios
    .post('/admin/4l1n4/clients/client/delete', userID)
    .then((res) => {
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
      dispatch({ type: GET_ALL_CLIENTS, payload: res.data.clients });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const convert2Client = (userID) => (dispatch) => {
  axios
    .post('/admin/4l1n4/clients/client/convert', userID)
    .then((res) => dispatch({ type: SET_SUCCESS_MSG, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const addClient = (clientData) => (dispatch) => {
  dispatch(setClientLoading());
  axios
    .post('/admin/4l1n4/clients/client/new', clientData)
    .then((res) => {
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
      dispatch({ type: GET_ALL_CLIENTS, payload: res.data.clients });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const getClients = () => (dispatch) => {
  dispatch(setClientLoading());
  axios
    .get('/admin/4l1n4/clients/all')
    .then((res) => dispatch({ type: GET_ALL_CLIENTS, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const downloadClientFile = (caseID, docID, docName) => (dispatch) => {
  axios
    .get(`/admin/4l1n4/clients/client/doc/download/${caseID}/${docID}`, {
      responseType: 'blob',
    })
    .then((res) => {
      const pdf = new Blob([res.data]);
      download(pdf, `${docName}`, 'application/pdf');
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
export const downloadFile = (caseID, docID, docName) => (dispatch) => {
  axios
    .get(`/profiles/profile/doc/download/${caseID}/${docID}`, {
      responseType: 'blob',
    })
    .then((res) => {
      const pdf = new Blob([res.data]);
      download(pdf, `${docName}`, 'application/pdf');
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const hideShowFile = (data) => (dispatch) => {
  axios
    .post('/admin/4l1n4/clients/client/doc/visibility', data)
    .then((res) => {
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
      dispatch({ type: GET_ALL_CLIENTS, payload: res.data.clients });
    })
    .catch((err) =>
      dispatch({ type: GET_CLIENT_FILES, payload: err.response.data })
    );
};

export const deleteClientFile = (data) => (dispatch) => {
  axios
    .post(`/admin/4l1n4/clients/client/doc/delete`, data)
    .then((res) => {
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
      dispatch({ type: GET_ALL_CLIENTS, payload: res.data.clients });
    })
    .catch((err) =>
      dispatch({ type: GET_CLIENT_FILES, payload: err.response.data })
    );
};

export const setClientLoading = () => {
  return {
    type: CLIENT_LOADING,
  };
};
