import React from 'react';

const MiniCardFree = ({
  appointment,
  selectedHour,
  currentDayAppointment,
  toggleBusyHour,
}) => {
  return (
    <div className='card mini-card-free'>
      <div className='mini-card-title'>
        <span className='red-text'>SIN CONSULTA {appointment.hour}</span>
      </div>
      <div className='mini-card-free-busy'>
        <div className='switch'>
          <label>
            <input
              type='checkbox'
              onChange={() => toggleBusyHour(appointment)}
              value={appointment.appointment === null ? true : false}
            />
            <span className='lever' />
          </label>
        </div>
      </div>
      <div className='mini-card-content-free'>
        <button
          className='btn-flat waves-effect red darken-2 white-text modal-trigger'
          data-target='modalConsulta'
          onClick={() =>
            selectedHour(currentDayAppointment.date, appointment.hour)
          }
        >
          nueva consulta
        </button>
      </div>
    </div>
  );
};

export default MiniCardFree;
