import axios from 'axios';
import { GET_ALL_PAYMENTS, LOADING_PAYMENTS, GET_ERRORS } from './types';

export const getAllPayments = (data) => (dispatch) => {
  dispatch(setLoadingPayments());
  axios
    .post('/admin/4l1n4/payment/payments/all', data)
    .then((res) => dispatch({ type: GET_ALL_PAYMENTS, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const setLoadingPayments = () => {
  return {
    type: LOADING_PAYMENTS,
  };
};
