import React from 'react';
import { Link } from 'react-router-dom';

const Extranjeria = () => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col s12 center'>
          <h6>
            <strong>EXTRANJERIA</strong>
          </h6>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 m6'>
          <div className='areas-items'>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Permiso Residencia</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Reagrupacion Familiar</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Certificacion de residente comunitario</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Cancelacion antecedentes penales</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Homologacion estudios</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Recursos Contencioso Administrativo</span>
              </label>
            </p>
          </div>
        </div>
        <div className='col s12 m6'>
          <p>
            <strong>
              Hacemos uso extensivo de las nuevas tecnologías para automatizar y
              acelerar el trabajo del despacho en los expedientes de
              extranjería.
            </strong>
          </p>
          <p>
            A cada cliente se le dará un trato personalizado y lo mantendremos
            al tanto, de forma clara y transparente, del estado de cada uno sus
            trámites.
          </p>
          <p>
            Si es usted un extranjero que quiere residir y vivir en España,
            estudiar, emprender un negocio, e invertir y comprar inmuebles,
            puede contar con nosotros para alcanzar sus objetivos.
          </p>
          <p>
            <strong>
              También podemos ayudarle si es usted residente comunitario.
            </strong>
          </p>
        </div>
        <div className='row'>
          <div className='col s12 center'>
            <h5>
              Para cualquier duda o consulta sobre <i>Derecho Civil</i> no dude
              en ponerse en contacto con nosotros
            </h5>
            <Link
              to='/contacto'
              className='btn-flat red white-text darken-1 waves-effect'>
              NECESITO MAS INFORMACION
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Extranjeria;
