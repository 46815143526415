import React, { useEffect } from 'react';
import M from 'materialize-css';

const ConsultasTab = ({ userInfo }) => {
  useEffect(() => {
    const modal = document.querySelectorAll('.modal');
    M.Modal.init(modal, {});
  }, []);
  return (
    <div className='tab-cli'>
      <div className='row'>
        <div className='col s12'>
          <div className='meetings-list-wrapper'>
            <table className='centered highlight'>
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Hora</th>
                  <th>Tipo</th>
                  <th>Pago</th>
                  <th>Categoria</th>
                  <th>Asunto</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {userInfo.appointments.map((app) => (
                  <tr key={app._id}>
                    <td>{app.date}</td>
                    <td>{app.start}</td>
                    <td>{app.type}</td>
                    <td>Pagado</td>
                    <td>{app.category}</td>
                    <td>
                      <span className='truncate'>{app.title}</span>
                    </td>
                    <td>
                      <a href='#meetModal' className='modal-trigger'>
                        <i className='material-icons blue-text'>visibility</i>
                      </a>
                      <div className='modal modal-fixed-footer' id='meetModal'>
                        <div className='modal-content'>
                          <div className='modal-title-container'>
                            <span>{app.category}</span>
                            <span>127</span>
                          </div>
                          <div className='row'>
                            <div className='col s12 center'>
                              <h6>{app.title}</h6>
                              <span>{app.description}</span>
                            </div>
                          </div>
                        </div>
                        <div className='modal-footer'>
                          <button className='btn-flat blue white-text waves-effect modal-close right'>
                            cerrar
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultasTab;
