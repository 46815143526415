import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const GoogleWidget = (props) => {
  return (
    <div className='google-badge'>
      <div>
        <FontAwesomeIcon
          icon={faGoogle}
          color='whitesmoke'
          size='6x'
          style={{
            marginRight: '5px',
          }}
        />
      </div>
      <div>
        <div className='center'>
          <div>
            <a
              href={`https://www.google.com/search?q=abogado+rumano+alcala+de+henares&rlz=1C5CHFA_enES713ES713&oq=abogado+rumano+alcala&aqs=chrome.1.69i57j0.5887j0j4&sourceid=chrome&ie=UTF-8#lrd=0xd42496920a5683d:0xdadaf8af06f7429d,1,,,`}>
              Google Reviews
            </a>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faStar}
              size='1x'
              className='yellow-text text-darken-2'
            />
            <FontAwesomeIcon
              icon={faStar}
              size='1x'
              className='yellow-text text-darken-2'
            />
            <FontAwesomeIcon
              icon={faStar}
              size='1x'
              className='yellow-text text-darken-2'
            />
            <FontAwesomeIcon
              icon={faStar}
              size='1x'
              className='yellow-text text-darken-2'
            />
            <FontAwesomeIcon
              icon={faStar}
              size='1x'
              className='yellow-text text-darken-2'
            />
            <span>5.0</span>
          </div>
          <div className='google-badge-r3'>
            <span>Abogado Recomendado</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleWidget;
