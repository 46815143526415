import React from 'react';
import { Link } from 'react-router-dom';

const Civil = () => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col s12 center'>
          <h6>
            <strong>DERECHO CIVIL</strong>
          </h6>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 m6'>
          <div className='areas-items'>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Compraventas</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Arrendamientos Urbanos</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Desahucios</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Reclamaciones Derivadas de Compraventa de Inmuebles</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Reclamaciones Judiciales Derivadas de Contratos</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Incumplimientos Contractuales</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Reclamaciones de todo tipo</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Reclamaciones de Pago de Deudas</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>DesahDaños y perjuiciosucios</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Derecho de Familia</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Separaciones y Divorcios</span>
              </label>
            </p>
          </div>
        </div>
        <div className='col s12 m6'>
          <p>
            Nuestra actividad abarca tanto la faceta del asesoramiento como la
            actividad judicial. La actividad extrajudicial es muy importante en
            esta rama del Derecho en la que impera los principios de autonomía y
            libertad de las personas a la hora de llegar a acuerdos lo que se
            traduce en una importante diversidad de contratos.
          </p>
          <p>
            En esta sección y de modo sucinto desglosamos algunas de las
            materias en las que tenemos especial experiencia.
          </p>
          <div className='row'>
            <div className='col s12 center'>
              <h5>
                Para cualquier duda o consulta sobre <i>Derecho Civil</i> no
                dude en ponerse en contacto con nosotros
              </h5>
              <Link
                to='/contacto'
                className='btn-flat red white-text darken-1 waves-effect'>
                NECESITO MAS INFORMACION
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Civil;
