import React from 'react';

const DatosTab = ({ userInfo }) => {
  return (
    <div className='tab-cli'>
      <div className='row'>
        <div className='input-field col s12 m3'>
          <input
            type='text'
            name='cli_firstName'
            id='cli_firstName'
            className='validate'
            value={userInfo.firstName}
            readOnly
            disabled
          />
          <label htmlFor='cli_firstName' className='active'>
            Nombre
          </label>
        </div>
        <div className='input-field col s12 m3'>
          <input
            type='text'
            name='cli_lastName'
            id='cli_lastName'
            className='validate'
            value={userInfo.lastName}
            readOnly
            disabled
          />
          <label htmlFor='cli_lastName' className='active'>
            Apellidos
          </label>
        </div>
        <div className='input-field col s12 m3'>
          <input
            type='email'
            name='cli_email'
            id='cli_email'
            className='validate'
            value={userInfo.user.email}
            readOnly
            disabled
          />
          <label htmlFor='cli_email' className='active'>
            Email
          </label>
        </div>
        <div className='input-field col s12 m3'>
          <input
            type='text'
            name='cli_phone'
            id='cli_phone'
            className='validate'
            maxLength={9}
            value={userInfo.phone}
            readOnly
            disabled
          />
          <label htmlFor='cli_phone' className='active'>
            Telefono
          </label>
        </div>
      </div>
      <div className='row'>
        <div className='input-field col s12 m4'>
          <input
            type='text'
            name='cli_street'
            id='cli_street'
            className='validate'
            value={userInfo.address.street}
            readOnly
            disabled
          />
          <label htmlFor='cli_street' className='active'>
            Calle
          </label>
        </div>
        <div className='input-field col s12 m1'>
          <input
            type='text'
            name='cli_number'
            id='cli_number'
            className='validate'
            value={userInfo.address.number}
            readOnly
            disabled
          />
          <label htmlFor='cli_number' className='active'>
            Numero
          </label>
        </div>
        <div className='input-field col s12 m2'>
          <input
            type='text'
            name='cli_city'
            id='cli_city'
            className='validate'
            value={userInfo.address.city}
            readOnly
            disabled
          />
          <label htmlFor='cli_city' className='active'>
            Ciudad
          </label>
        </div>
        <div className='input-field col s12 m2'>
          <input
            type='text'
            name='cli_postalCode'
            id='cli_postalCode'
            className='validate'
            value={userInfo.address.postalCode}
            readOnly
            disabled
          />
          <label htmlFor='cli_postalCode' className='active'>
            Codigo Postal
          </label>
        </div>
        <div className='input-field col s12 m3'>
          <input
            type='text'
            name='cli_ID'
            id='cli_ID'
            className='validate'
            value={userInfo.legalID}
            readOnly
            disabled
          />
          <label htmlFor='cli_ID' className='active'>
            NIE/NIF/CIF/DNI/Pasaporte
          </label>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 m4'>
          <p className='blue-text'>
            <strong>Fecha Alta: </strong>
            <span className='grey-text text-lighten-1'>
              {userInfo.user.createdAt}
            </span>
          </p>
        </div>
        <div className='col s12 m4'>
          <p className='blue-text'>
            <strong>IP: </strong>
            <span className='grey-text text-lighten-1'>NS</span>
          </p>
        </div>
        <div className='col s12 m4'>
          <p className='blue-text'>
            <strong>Ultimo Acceso: </strong>
            <span className='grey-text text-lighten-1'>NS</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DatosTab;
