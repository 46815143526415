import React, { useState, useEffect, useCallback } from 'react';
import AddToCalendar from 'react-add-to-calendar';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import isEmpty from 'is-empty';
import moment from 'moment';

const Step4 = (props) => {
  const appointment = useSelector((state) => state.appointment);
  const [date, setDate] = useState('');
  const [hour, setHour] = useState('');

  useEffect(() => {
    if (!isEmpty(appointment.newAppointment)) {
      setDate(appointment.newAppointment.date);
      setHour(appointment.newAppointment.start);
    }
  }, [appointment]);

  const modelCalEvent = useCallback(() => {
    return {
      title: 'Consulta Abogada Alina Wagner',
      description: `${appointment.newAppointment.title} tel: +34 642 767 633 email: abogada@ajiverdeanu.es `,
      location:
        appointment.newAppointment.type === 'office'
          ? 'C/ Carabaña nº8, 28806, Alcala de Henares, Madrid'
          : 'VIDEO-CONSULTA',
      startTime: moment(
        `${appointment.newAppointment.date}T${appointment.newAppointment.start}`,
        'DD-MM-YYYYTHH:mm'
      ),
      endTime: moment(
        `${appointment.newAppointment.date}T${appointment.newAppointment.end}`,
        'DD-MM-YYYYTHH:mm'
      ),
    };
  }, [appointment]);

  return (
    <div className='card'>
      <div className='card-content'>
        <div className='row'>
          <div className='col s12'>
            <div className='check-ok-wrapper'>
              <div className='check-ok-circle'>
                <i className='material-icons green-text large'>check</i>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col s12 center'>
            <h4 className='green-text'>ENHORABUENA</h4>
            <p className='grey-text'>
              Gracias por comprar una video consulta de 60 minutos con la
              abogada{' '}
              <Link to='/despacho' className='blue-text'>
                Alina Wagner
              </Link>
              .
            </p>
            <p className='grey-text'>
              Por favor tenga preparado las preguntas y las dudas para la video
              consulta con antelacion.
            </p>
            <p className='grey-text'>
              La fecha de la consulta es:{' '}
              <span className='blue-text'>
                <strong>{date}</strong>
              </span>
            </p>
            <p className='grey-text'>
              La hora de la consulta es:{' '}
              <span className='blue-text'>
                <strong>{hour}</strong>
              </span>
            </p>
            <p className='grey-text'>
              EN CASO DE NO PODER REALIZAR LA VIDEO CONSULTA EN LA FECHA O LA
              HORA SELECIONADA, POR FAVOR AVISAR CON ANTELACION!
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col s12 center'>
            <h5 className='grey-text'>Puede descargar el evento mas abajo</h5>
            <div className='cal-download-container'>
              <AddToCalendar
                event={modelCalEvent()}
                buttonLabel='Añadir al calendario'
                displayItemIcons={true}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col s12 center'>
            <Link className='btn-flat white-text blue' to='/dashboard'>
              Volver a la pagina principal
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step4;
