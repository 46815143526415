import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  SET_REQ_VERIFICATION,
  SET_USER_EMAIL_VERIFIED,
  SET_SUCCESS_MSG,
} from './types';

export const verifyEmail = (code) => (dispatch) => {
  axios
    .post('/users/auth/email/verify', code)
    .then((res) => {
      dispatch({
        type: SET_USER_EMAIL_VERIFIED,
        payload: res.data.emailVerified,
      });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const registerUser = (userData) => (dispatch) => {
  axios
    .post('/users/auth/register', userData)
    .then((res) =>
      dispatch({
        type: SET_REQ_VERIFICATION,
        payload: res.data.requiresVerification,
      })
    )
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const loginUser = (userData) => (dispatch) => {
  axios
    .post('/users/auth/login', userData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem('jwtToken', token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
      window.location.pathname = '/dashboard';
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const _login = (userData) => (dispatch) => {
  axios
    .post('/admin/4l1n4/login', userData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem('jwtToken', token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
      window.location.pathname = '/admin/dashboard';
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const passResetA = (data) => (dispatch) => {
  axios
    .post('/admin/4l1n4/account/security/update', data)
    .then((res) => dispatch({ type: SET_SUCCESS_MSG, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
export const passReset = (data) => (dispatch) => {
  axios
    .post('/users/auth/pass/change', data)
    .then((res) => dispatch({ type: SET_SUCCESS_MSG, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('jwtToken');
  setAuthToken(false);
  dispatch(setCurrentUser({}));
};

export const closeUserAccount = () => (dispatch) => {
  axios
    .post('/users/auth/account/close')
    .then((res) => {
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
      dispatch(logoutUser());
      window.location.pathname = '/';
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
