import { SET_SUCCESS_MSG, CLEAR_SUCCESS_MSG } from '../actions/types';

const initialState = {};

const successReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUCCESS_MSG:
      return action.payload;

    case CLEAR_SUCCESS_MSG:
      return {};
    default:
      return state;
  }
};
export default successReducer;
