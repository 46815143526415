import React, { useEffect, useState, useCallback } from 'react';
import M from 'materialize-css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faTwitter,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { updateSocial } from '../../../redux/actions/profileActions';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'is-empty';

const SocialCard = (props) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const [facebook, setFacebook] = useState('');
  const [twitter, setTwitter] = useState('');
  const [linkedin, setLinkedin] = useState('');

  useEffect(() => {
    setTimeout(() => {
      M.updateTextFields();
    }, 300);
  }, []);

  useEffect(() => {
    if (!isEmpty(profile.profile)) {
      setFacebook(profile.profile.social.facebook);
      setTwitter(profile.profile.social.twitter);
      setLinkedin(profile.profile.social.linkedin);
    }
  }, [profile]);

  const handleSocialChange = useCallback((e, field) => {
    switch (field) {
      case 'facebook':
        setFacebook(e.target.value);
        break;
      case 'twitter':
        setTwitter(e.target.value);
        break;
      case 'linkedin':
        setLinkedin(e.target.value);
        break;
      default:
        break;
    }
  }, []);

  const saveData = useCallback(() => {
    const data = {
      social: {
        facebook: facebook.trim(),
        twitter: twitter.trim(),
        linkedin: linkedin.trim(),
      },
    };
    dispatch(updateSocial(data));
  }, [facebook, twitter, linkedin, dispatch]);

  return (
    <div className='acc-r-wrapper'>
      <div className='row'>
        <div className='input-field col s12 m4'>
          <FontAwesomeIcon
            className='prefix blue-text text-darken-2'
            icon={faFacebook}
            size='1x'
          />
          <input
            type='text'
            name='acc_facebook'
            id='acc_facebook'
            className='validate'
            defaultValue={facebook}
            onChange={(e) => handleSocialChange(e, 'facebook')}
          />
          <label htmlFor='acc_facebook' className='active'>
            Facebook
          </label>
        </div>
        <div className='input-field col s12 m4'>
          <FontAwesomeIcon
            className='prefix blue-text'
            icon={faTwitter}
            size='1x'
          />
          <input
            type='text'
            name='acc_twitter'
            id='acc_twitter'
            className='validate'
            defaultValue={twitter}
            onChange={(e) => handleSocialChange(e, 'twitter')}
          />
          <label htmlFor='acc_twitter' className='active'>
            Twitter
          </label>
        </div>
      </div>
      <div className='row'>
        <div className='input-field col s12 m4'>
          <FontAwesomeIcon
            className='prefix blue-text text-darken-3'
            icon={faLinkedin}
            size='1x'
          />
          <input
            type='text'
            name='acc_linkedin'
            id='acc_linkedin'
            className='validate'
            defaultValue={linkedin}
            onChange={(e) => handleSocialChange(e, 'linkedin')}
          />
          <label htmlFor='acc_linkedin' className='active'>
            Linkedin
          </label>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <button
            className='btn-flat blue white-text waves-effect'
            onClick={saveData}
          >
            guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default SocialCard;
