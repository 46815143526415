import {
  GET_APPOINTMENT,
  GET_APPOINTMENTS,
  SET_LOADING_APPOINTMENT,
  CREATE_APPOINTMENT,
  LOADING_CALENDAR,
  GET_CALENDAR,
  CREATE_NEW_APPOINTMENT,
} from '../actions/types';

const initialState = {
  appointment: {},
  appointments: [],
  loadingAppointment: false,
  newAppointment: {},
  calendar: [],
  loadingCalendar: false,
};

const appointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEW_APPOINTMENT:
      return {
        ...state,
        loadingCalendar: false,
        loadingAppointment: false,
        newAppointment: { ...state.newAppointment, ...action.payload },
      };
    case LOADING_CALENDAR:
      return {
        ...state,
        loadingCalendar: true,
        loadingAppointment: false,
      };
    case GET_CALENDAR:
      return {
        ...state,
        loadingAppointment: false,
        loadingCalendar: false,
        calendar: action.payload,
      };
    case SET_LOADING_APPOINTMENT:
      return {
        ...state,
        loadingAppointment: true,
      };
    case GET_APPOINTMENT:
      return {
        ...state,
        appointment: action.payload,
        loadingAppointment: false,
      };
    case GET_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload,
        loadingAppointment: false,
      };
    case CREATE_APPOINTMENT:
      return {
        ...state,
        newAppointment: action.payload,
        loadingAppointment: false,
      };
    default:
      return state;
  }
};
export default appointmentReducer;
