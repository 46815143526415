import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../redux/actions/authActions';
import { Link } from 'react-router-dom';

const AuthModal = ({ modalID }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const logMeIn = (e) => {
    e.preventDefault();
    const data = {
      email: email.trim(),
      password: password.trim(),
    };
    dispatch(loginUser(data));
  };

  return (
    <div id={modalID} className='modal auth-modal-login'>
      <div className='login-container'>
        <div className='auth-title'>
          <h5>Soy Cliente</h5>
          <span>
            No tienes cuenta? <Link to='/register'>REGISTRATE YA</Link>
          </span>
        </div>
        <div className='input-field'>
          <input
            name='email'
            type='email'
            id='email'
            className='validate'
            onChange={(event) => setEmail(event.target.value)}
          />
          <label htmlFor='email'>Email</label>
        </div>
        <div className='input-field'>
          <input
            name='password'
            type='password'
            id='password'
            className='validate'
            onChange={(event) => setPassword(event.target.value)}
          />
          <label htmlFor='password'>Contraseña</label>
        </div>
        <button className='btn waves-effect red' onClick={logMeIn}>
          Entra
        </button>
        <div className='auth-footer'>
          <span className='grey-text'>Tienes problemas para entrar?</span>
          <Link to='/contacto'>Contacto</Link>
        </div>
      </div>
    </div>
  );
};

export default AuthModal;
