import React, { useState, useEffect, useCallback } from 'react';
import M from 'materialize-css';
import { useDispatch } from 'react-redux';
import { createNewAppointment } from '../../../redux/actions/appointmentActions';

const Step1 = ({ handleActiveStep }) => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([
    { id: '0', name: 'PENAL', checked: false },
    { id: '1', name: 'CIVIL', checked: false },
    { id: '2', name: 'FAMILIA', checked: false },
    { id: '3', name: 'LABORAL', checked: false },
    { id: '4', name: 'EXTRANJERIA', checked: false },
    { id: '5', name: 'INFORMACION GENERAL', checked: false },
    { id: '6', name: 'NO LO SE AUN', checked: false },
  ]);
  const [enableNextBTN, setEnableNextBTN] = useState(false);
  const [office, setOffice] = useState(false);
  const [video, setVideo] = useState(false);
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [selectedCategories, setSelectedCategories] = useState('');

  useEffect(() => {
    M.updateTextFields();
    const txtArea = document.querySelectorAll('.materialize-textarea');
    M.CharacterCounter.init(txtArea);
  }, []);

  const handleCheckbox = (cat) => {
    let foundCat = categories.filter((c) => c.id === cat.id);
    let restCats = categories.filter((c) => c.id !== cat.id);
    let updatedRestCats = [];
    restCats.map(
      (c) => (updatedRestCats = [...updatedRestCats, { ...c, checked: false }])
    );

    let updatedCat = [
      ...updatedRestCats,
      { ...foundCat[0], checked: !foundCat[0].checked },
    ].sort((a, b) => (a.id > b.id ? 1 : -1));
    setCategories(updatedCat);
    setSelectedCategories(foundCat.map((c) => c.name)[0]);
  };

  const handleTextChange = useCallback((e, field) => {
    switch (field) {
      case 'subject':
        setSubject(e.target.value);
        break;
      case 'description':
        if (e.target.value.length > 124) {
          setEnableNextBTN(true);
          setDescription(e.target.value);
        } else {
          setEnableNextBTN(false);
        }
        break;
      default:
        break;
    }
  }, []);
  const addNewAppointment = () => {
    const data = {
      type: video ? 'video' : 'office',
      title: subject,
      description,
      category: selectedCategories,
    };
    dispatch(createNewAppointment(data));
    handleActiveStep('2');
  };
  const handleTypeCheckbox = useCallback(
    (field) => {
      switch (field) {
        case 'video':
          setVideo(!video);
          setOffice(false);
          break;
        case 'office':
          setVideo(false);
          setOffice(!office);
          break;
        default:
          break;
      }
    },
    [video, office]
  );
  return (
    <div className='card step-h6'>
      <div className='card-content'>
        <div className='row'>
          <div className='col s12 center'>
            <h5>Formulario Consulta</h5>
          </div>
        </div>
        <div>
          <h6>*Tipo de Consulta</h6>
          <div className='cat-selection-wrapper'>
            <p>
              <label className='active'>
                <input
                  type='checkbox'
                  checked={video}
                  onChange={() => handleTypeCheckbox('video')}
                />
                <span>Video Consulta</span>
              </label>
            </p>
            <p>
              <label className='active'>
                <input
                  type='checkbox'
                  checked={office}
                  onChange={() => handleTypeCheckbox('office')}
                />
                <span>Presencial(en oficina)</span>
              </label>
            </p>
          </div>
        </div>
        <div>
          <h6>
            *Elige la categoria que mejor describe su caso. Si no lo sabe, por
            favor seleciona{' '}
            <span>
              <strong>NO LO SE AUN.</strong>
            </span>
          </h6>
          <div className='cat-selection-wrapper'>
            {categories.map((cat) => {
              return (
                <p key={cat.id}>
                  <label className='active'>
                    <input
                      type='checkbox'
                      checked={cat.checked}
                      onChange={() => handleCheckbox(cat)}
                    />
                    <span>{cat.name}</span>
                  </label>
                </p>
              );
            })}
          </div>
          <div>
            <h6>*Escribe el asunto de la consulta</h6>
          </div>
          <div className='input-field'>
            <input
              type='text'
              id='asunto'
              name='asunto'
              onChange={(e) => handleTextChange(e, 'subject')}
            />
            <label htmlFor='asunto' className='active'>
              Asunto
            </label>
          </div>
          <div>
            <h6>
              *Por favor, intenta describir en pocas palabras el motivo de la
              consulta. Por ejemplo decriba brevemente porque nos ha contactado
              y en que le podemos ayudar. Minimo 124 characteres.
            </h6>
          </div>
          <div className='input-field'>
            <textarea
              className='materialize-textarea'
              id='descriptionTA'
              onChange={(e) => handleTextChange(e, 'description')}
              data-length='2400'
            />
            <label htmlFor='descriptionTA' className='active'>
              Descripcion
            </label>
          </div>
          <div className='field-required'>
            <span>*Campos obligatorios</span>
          </div>
          <div className='step-footer'>
            <button
              className={`btn-flat waves-effect blue white-text ${
                !enableNextBTN && 'disabled'
              }`}
              onClick={addNewAppointment}
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
