import React, { useEffect, useState, useCallback } from 'react';
import M from 'materialize-css';
import { useDispatch, useSelector } from 'react-redux';
import { editProfileA } from '../../../redux/actions/profileActions';
import isEmpty from 'is-empty';

const AccountCard = (props) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    setTimeout(() => {
      M.updateTextFields();
    }, 300);
  }, []);

  useEffect(() => {
    if (!isEmpty(profile.profile)) {
      setFirstName(profile.profile.firstName);
      setLastName(profile.profile.lastName);
      setEmail(profile.profile.user.email);
      setPhone(profile.profile.phone);
      setStreet(profile.profile.address.street);
      setNumber(profile.profile.address.number);
      setCity(profile.profile.address.city);
      setPostalCode(profile.profile.address.postalCode);
    }
  }, [profile.profile]);

  const handleText = useCallback((e, field) => {
    switch (field) {
      case 'firstName':
        setFirstName(e.target.value);
        break;
      case 'lastName':
        setLastName(e.target.value);
        break;
      case 'phone':
        setPhone(e.target.value);
        break;
      case 'street':
        setStreet(e.target.value);
        break;
      case 'number':
        setNumber(e.target.value);
        break;
      case 'city':
        setCity(e.target.value);
        break;
      case 'postalCode':
        setPostalCode(e.target.value);
        break;
      default:
        break;
    }
  }, []);

  const saveData = useCallback(() => {
    const data = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      phone: phone.trim(),
      address: {
        street: street.trim(),
        number: number.trim(),
        city: city.trim(),
        postalCode: postalCode.trim(),
      },
    };
    dispatch(editProfileA(data));
  }, [firstName, lastName, phone, street, number, city, postalCode, dispatch]);

  return (
    <div className='acc-r-wrapper'>
      <div className='row'>
        <div className='input-field col s12 m4'>
          <input
            type='text'
            name='acc_firstName'
            id='acc_firstName'
            className='validate'
            defaultValue={firstName}
            onChange={(e) => handleText(e, 'firstName')}
          />
          <label htmlFor='acc_firstName' className='active'>
            Nombre
          </label>
        </div>
        <div className='input-field col s12 m4'>
          <input
            type='text'
            name='acc_lastName'
            id='acc_lastName'
            className='validate'
            defaultValue={lastName}
            onChange={(e) => handleText(e, 'lastName')}
          />
          <label htmlFor='acc_lastName' className='active'>
            Apellidos
          </label>
        </div>
      </div>
      <div className='row'>
        <div className='input-field col s12 m4'>
          <input
            type='email'
            name='acc_email'
            id='acc_email'
            className='validate'
            disabled
            defaultValue={email}
          />
          <label htmlFor='acc_email' className='active'>
            Email
          </label>
        </div>
        <div className='input-field col s12 m4'>
          <input
            type='text'
            name='acc_phone'
            id='acc_phone'
            className='validate'
            maxLength={9}
            defaultValue={phone}
            onChange={(e) => handleText(e, 'phone')}
          />
          <label htmlFor='acc_phone' className='active'>
            Telefono
          </label>
        </div>
      </div>
      <div className='row'>
        <div className='input-field col s12 m3'>
          <input
            type='text'
            name='acc_street'
            id='acc_street'
            className='validate'
            defaultValue={street}
            onChange={(e) => handleText(e, 'street')}
          />
          <label htmlFor='acc_street' className='active'>
            Calle
          </label>
        </div>
        <div className='input-field col s2 m1'>
          <input
            type='text'
            name='acc_number'
            id='acc_number'
            className='validate'
            defaultValue={number}
            onChange={(e) => handleText(e, 'number')}
          />
          <label htmlFor='acc_number' className='active'>
            Numero
          </label>
        </div>
        <div className='input-field col s5 m2'>
          <input
            type='text'
            name='acc_city'
            id='acc_city'
            className='validate'
            defaultValue={city}
            onChange={(e) => handleText(e, 'city')}
          />
          <label htmlFor='acc_city' className='active'>
            Ciudad
          </label>
        </div>
        <div className='input-field col s5 m2'>
          <input
            type='text'
            name='acc_cp'
            id='acc_cp'
            className='validate'
            defaultValue={postalCode}
            onChange={(e) => handleText(e, 'postalCode')}
          />
          <label htmlFor='acc_cp' className='active'>
            Codigo Postal
          </label>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <button
            className='btn-flat blue white-text waves-effect'
            onClick={saveData}
          >
            guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountCard;
