import {
  UPDATE_UPLOAD_PROGRESS,
  FILE_UPLOADING,
  RESET_UPLOAD_PROGRESS,
} from '../actions/types';

const initialState = {
  uploading: false,
  uploadProgress: 0,
  uploadFile: {},
};

const uploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILE_UPLOADING:
      return {
        ...state,
        uploading: true,
      };
    case UPDATE_UPLOAD_PROGRESS:
      return {
        ...state,
        uploading:
          action.payload.loaded === action.payload.totalSize ? false : true,
        uploadProgress: action.payload.progress,
        totalSize: action.payload.totalSize,
      };
    case RESET_UPLOAD_PROGRESS:
      return {
        ...state,
        uploading: false,
        uploadProgress: 0,
        totalSize: 0,
      };

    default:
      return state;
  }
};

export default uploadReducer;
