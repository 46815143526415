import {
  GET_PROFILE,
  EDIT_PROFILE,
  PROFILE_LOADING,
  GET_ERRORS,
  SET_SUCCESS_MSG,
} from './types';
import axios from 'axios';

export const editProfile = (data) => (dispatch) => {
  dispatch(setProfileLoading());
  axios
    .post(`/profiles/profile/edit`, data)
    .then((res) => {
      dispatch({ type: EDIT_PROFILE, payload: res.data.profile });
      dispatch({
        type: SET_SUCCESS_MSG,
        payload: res.data,
      });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const editProfileA = (data) => (dispatch) => {
  dispatch(setProfileLoading());
  axios
    .post('/admin/4l1n4/account/update', data)
    .then((res) => {
      dispatch({ type: EDIT_PROFILE, payload: res.data.profile });
      dispatch({
        type: SET_SUCCESS_MSG,
        payload: res.data,
      });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const getProfile = (username) => (dispatch) => {
  dispatch(setProfileLoading());
  axios
    .get(`/profiles/profile/${username}`)
    .then((res) => dispatch({ type: GET_PROFILE, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const getProfileA = () => (dispatch) => {
  dispatch(setProfileLoading());
  axios
    .get('/admin/4l1n4/profile')
    .then((res) => dispatch({ type: GET_PROFILE, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const updateNotifications = (data) => (dispatch) => {
  dispatch(setProfileLoading());
  axios
    .post('/admin/4l1n4/account/notifications/update', data)
    .then((res) => {
      dispatch({ type: GET_PROFILE, payload: res.data.profile });
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const updateSocial = (data) => (dispatch) => {
  dispatch(setProfileLoading());
  axios
    .post('/admin/4l1n4/account/social/update', data)
    .then((res) => {
      dispatch({ type: GET_PROFILE, payload: res.data.profile });
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const getCurrentProfile = () => (dispatch) => {
  dispatch(setProfileLoading());
  axios
    .get(`/profiles/profile`)
    .then((res) => dispatch({ type: GET_PROFILE, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const updateNotif = (data) => (dispatch) => {
  dispatch(setProfileLoading());
  axios
    .post('/profiles/profile/notifications/update', data)
    .then((res) => {
      dispatch({ type: EDIT_PROFILE, payload: res.data.profile });
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const setProfileLoading = () => {
  return {
    type: PROFILE_LOADING,
  };
};
