import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'is-empty';
import { getAllPayments } from '../../../redux/actions/paymentActions';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';

const Payments = (props) => {
  const dispatch = useDispatch();
  const payment = useSelector((state) => state.payment);

  const [payments, setPayments] = useState([]);
  const [year] = useState(moment().format('YYYY'));
  const [selectedMonth, setSelectedMonth] = useState(moment().format('MMM'));
  const [selectedMonthLabel, setSelectedMonthLabel] = useState('');
  const [selMonthData, setSelMonthData] = useState([]);

  useEffect(() => {
    dispatch(getAllPayments({ year }));
  }, [dispatch, year]);

  useEffect(() => {
    if (payment.payments.length !== 0) {
      setPayments(payment.payments);
    }
  }, [payment]);

  useEffect(() => {
    if (payments.length !== 0) {
      let selMonth = payments.filter((p) => p.label === selectedMonth)[0];
      setSelMonthData(selMonth.payments);
      setSelectedMonthLabel(selMonth.month.toUpperCase());
    }
  }, [selectedMonth, payments]);

  const handleMouseClickLabel = useCallback((e) => {
    setSelectedMonth(e.value);
  }, []);

  return (
    <div className='pay-container'>
      <div className='row'>
        <div className='col s12 center'>
          <h5 className='use-oswald-font'>PAGOS EN {year}</h5>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          {!isEmpty(payments) && (
            <ResponsiveContainer width='100%' height={300}>
              <AreaChart data={payments}>
                <Tooltip cursor={{ fill: 'transparent' }} />
                <Area
                  type='monotone'
                  dataKey='total'
                  fill='#2196f3'
                  stroke='#3f84e5'
                  dot={{ r: 4 }}
                />
                <XAxis dataKey='label' onClick={handleMouseClickLabel} />
                <YAxis />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col s12 center'>
          <h6 className='use-oswald-font'>{selectedMonthLabel}</h6>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <table className='centered highlight responsive-table'>
            <thead>
              <tr>
                <th>Cliente</th>
                <th>Fecha</th>
                <th>Hora</th>
                <th>Tipo</th>
                <th>Categoria</th>
                <th>Precio</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {selMonthData.map((pay, i) => (
                <tr key={i}>
                  <td>{pay.clientName}</td>
                  <td>{pay.date}</td>
                  <td>{pay.hour}</td>
                  <td>{pay.type}</td>
                  <td>{pay.category}</td>
                  <td>{pay.price}</td>
                  <td>{pay.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Payments;
