import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentProfile } from '../../redux/actions/profileActions';
import { Link } from 'react-router-dom';
import { getAppointments } from '../../redux/actions/appointmentActions';
import isEmpty from 'is-empty';
import moment from 'moment';
import TabAccount from './AccountTabs/TabAccount';
import TabConsultas from './AccountTabs/TabConsultas';
import TabPagos from './AccountTabs/TabPagos';
import TabSecurity from './AccountTabs/TabSecurity';
import TabSettings from './AccountTabs/TabSettings';
import M from 'materialize-css';
import ModalConsultaInfo from './AccountTabs/ModalConsultaInfo';
import { downloadFile } from '../../redux/actions/clientActions';
import MainContext from '../context/MainContext';

const Dashboard = (props) => {
  const mainContext = useContext(MainContext);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);

  const [appointment, setAppointment] = useState({});
  const [showAccount, setShowAccount] = useState(true);
  const [showConsultas, setShowConsultas] = useState(false);
  const [showPagos, setShowPagos] = useState(false);
  const [showSecurity, setShowSecurity] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [cases, setCases] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [selectedCase, setSelectedCase] = useState({});
  const [isExpired, setIsExpired] = useState(false);

  const modalsREF = useRef();
  const modalConsultaInfoREF = useRef();

  useEffect(() => {
    const modal = document.querySelectorAll('.modal');
    modalsREF.current = M.Modal.init(modal, {});
    modalConsultaInfoREF.current = modalsREF.current.filter(
      (mod) => mod.id === 'modalConsultaInfo'
    )[0];
  }, []);

  useEffect(() => {
    dispatch(getCurrentProfile());
    dispatch(getAppointments());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(profile.profile)) {
      if (profile.profile.appointments.length !== 0) {
        let appoint = profile.profile.appointments.filter((a) => a.isActive);
        if (appoint.length !== 0) {
          setAppointment(appoint[0]);
          let now = moment();
          let date = moment(appoint[0].date, 'DD-MM-YYYY');
          if (moment(now).isAfter(date, 'day')) {
            setIsExpired(true);
          }
        }
      }
      if (profile.profile.cases.length !== 0) {
        setCases(profile.profile.cases);
      }
    }
  }, [profile]);

  const handleConsultaSel = useCallback((app) => {
    setSelectedAppointment(app);
    modalConsultaInfoREF.current.open();
  }, []);

  const handleTabChange = useCallback((field) => {
    switch (field) {
      case 'account':
        setShowAccount(true);
        setShowConsultas(false);
        setShowPagos(false);
        setShowSecurity(false);
        setShowSettings(false);
        break;
      case 'consultas':
        setShowAccount(false);
        setShowConsultas(true);
        setShowPagos(false);
        setShowSecurity(false);
        setShowSettings(false);
        break;
      case 'pagos':
        setShowAccount(false);
        setShowConsultas(false);
        setShowPagos(true);
        setShowSecurity(false);
        setShowSettings(false);
        break;
      case 'security':
        setShowAccount(false);
        setShowConsultas(false);
        setShowPagos(false);
        setShowSecurity(true);
        setShowSettings(false);
        break;
      case 'settings':
        setShowAccount(false);
        setShowConsultas(false);
        setShowPagos(false);
        setShowSecurity(false);
        setShowSettings(true);
        break;
      default:
        break;
    }
  }, []);
  const handleTabRender = useCallback(() => {
    let tab = <TabAccount />;

    if (showAccount) {
      tab = <TabAccount />;
    } else if (showConsultas) {
      tab = <TabConsultas handleConsultaSel={handleConsultaSel} />;
    } else if (showPagos) {
      tab = <TabPagos />;
    } else if (showSecurity) {
      tab = <TabSecurity />;
    } else {
      tab = <TabSettings />;
    }
    return tab;
  }, [showAccount, showConsultas, showPagos, showSecurity, handleConsultaSel]);

  const handleShowDocs = useCallback((c) => {
    setFilesList(c.documents);
    setSelectedCase(c);
  }, []);

  const handleFileDownload = useCallback(
    (f) => {
      dispatch(downloadFile(selectedCase._id, f._id, f.fileName));
    },
    [dispatch, selectedCase]
  );

  return (
    <div className='dashboard-container'>
      <div className='dashboard-tabs-container'>
        <button
          onClick={() => handleTabChange('account')}
          className={`${
            showAccount ? 'dashboard-tab-active' : 'dashboard-tab'
          }`}
        >
          <i className='material-icons'>account_box</i>
          <span>CUENTA</span>
          <span />
        </button>
        <button
          onClick={() => handleTabChange('consultas')}
          className={`${
            showConsultas ? 'dashboard-tab-active' : 'dashboard-tab'
          }`}
        >
          <i className='material-icons'>assignment</i>
          <span>CONSULTAS</span>
          <span />
        </button>
        <button
          onClick={() => handleTabChange('pagos')}
          className={`${showPagos ? 'dashboard-tab-active' : 'dashboard-tab'}`}
        >
          <i className='material-icons'>account_balance_wallet</i>
          <span>PAGOS</span>
          <span />
        </button>
        <button
          onClick={() => handleTabChange('security')}
          className={`${
            showSecurity ? 'dashboard-tab-active' : 'dashboard-tab'
          }`}
        >
          <i className='material-icons'>security</i>
          <span>SEGURIDAD</span>
          <span />
        </button>
        <button
          onClick={() => handleTabChange('settings')}
          className={`${
            showSettings ? 'dashboard-tab-active' : 'dashboard-tab'
          }`}
        >
          <i className='material-icons'>settings</i>
          <span>AJUSTES</span>
          <span />
        </button>
      </div>
      <div className='row'>
        <div className='col s12 m8'>{handleTabRender()}</div>
        <div className='col s12 m4'>
          <div className='card large'>
            {!isEmpty(appointment) && !isExpired ? (
              <div className='card-content reminder-consulta-container'>
                <img src={mainContext.accountImages[0]} alt='reminder' />
                <div>
                  <p>RECUERDA</p>
                  <p>
                    Tu cita el dia <span>{appointment.date}</span> a las
                    <span> {appointment.start}</span>
                  </p>
                  <p>
                    Si no puedes acudir POR FAVOR LLAMA con antelacion a
                    <span> (+34) 642 767 633</span>
                  </p>
                </div>
              </div>
            ) : (
              <div className='card-content no-event-container'>
                <img src={mainContext.accountImages[1]} alt='no-event' />
                <div>
                  <Link
                    to='/compra/videoconsulta'
                    className='btn-flat blue white-text waves-effect'
                  >
                    Nueva Cita
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 center'>
          <h5 className='use-oswald-font'>MIS CASOS</h5>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='card large'>
            {cases.length !== 0 ? (
              <div className='client-cases-container'>
                <div className='row'>
                  <div className='col s12 m8'>
                    <table className='highlight centered responsive-table'>
                      <thead>
                        <tr>
                          <th>fecha</th>
                          <th>estado</th>
                          <th>nombre</th>
                          <th>categoria</th>
                          <th>ficheros</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cases.map((c) => (
                          <tr key={c._id}>
                            <td>{c.date}</td>
                            <td>
                              <span
                                className={
                                  c.status === 'abierto'
                                    ? 'green-text'
                                    : 'red-text'
                                }
                              >
                                {c.status}
                              </span>
                            </td>
                            <td>{c.title}</td>
                            <td>{c.category}</td>
                            <td>
                              <button
                                className='btn-flat blue-text waves-effect'
                                onClick={() => handleShowDocs(c)}
                              >
                                <i className='material-icons'>folder</i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className='col s12 m4'>
                    <div className='client-cases-right'>
                      {filesList.length === 0 ? (
                        <div className='no-docs'>
                          <span>no hay ficheros!</span>
                        </div>
                      ) : (
                        <ul>
                          {filesList.map((f) => (
                            <li key={f._id}>
                              <span className='truncate'>{f.fileName}</span>
                              <button
                                className='btn-flat blue-text waves-effect'
                                onClick={() => handleFileDownload(f)}
                              >
                                <i className='material-icons'>cloud_download</i>
                              </button>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='card-content no-data'>
                <img src={mainContext.accountImages[2]} alt='no-data' />
                <div>
                  <span>
                    <strong>NO HAY NINGUN CASO ACTIVO</strong>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalConsultaInfo appointment={selectedAppointment} />
    </div>
  );
};

export default Dashboard;
