import React, { useEffect, useState, useRef, useCallback } from 'react';
import { io } from 'socket.io-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMicrophone,
  faMicrophoneSlash,
  faStop,
  faVideo,
  faVideoSlash,
  faPlay,
} from '@fortawesome/free-solid-svg-icons';
import isEmpty from 'is-empty';
import { useHistory } from 'react-router-dom';

const VideoCall = (props) => {
  const history = useHistory();
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [iceServers] = useState([{ urls: 'stun:stun.l.google.com:19302' }]);
  const [connectionInfo, setConnectionInfo] = useState('');
  const [errors, setErrors] = useState({});
  const [muteAudio, setMuteAudio] = useState(false);
  const [hideVideo, setHideVideo] = useState(false);
  const [incomingCall, setIncomingCall] = useState(false);

  const localStreamRef = useRef(null);
  const remoteStreamRef = useRef(null);
  const socketRef = useRef(null);
  const peerRef = useRef(null);

  useEffect(() => {
    (async () => await getMediaDevices())();
  }, []);

  useEffect(() => {
    if (connectionInfo === 'disconnected') {
      if (localStream !== null) {
        let tracks = localStream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    }
  }, [connectionInfo, localStream]);

  useEffect(() => {
    const socket = io('https://www.ajiverdeanu.es');
    socketRef.current = socket;
    socket.on('offer', (data) => {
      if (peerRef.current === null)
        peerRef.current = new RTCPeerConnection({ iceServers });
      peerRef.current.setRemoteDescription(data);
      setIncomingCall(true);
    });
    socket.on('answer', (data) => {
      peerRef.current.setRemoteDescription(data);
    });
    socket.on('candidate', (data) => {
      peerRef.current.addIceCandidate(new RTCIceCandidate(data));
    });
    socket.on('endCall', () => {
      setConnectionInfo('disconnected');
      peerRef.current.close();
      peerRef.current = null;
      if (localStream !== null) {
        let tracks = localStream.getTracks();
        tracks.forEach((track) => track.stop());
      }
      if (remoteStream !== null) {
        let tracks = remoteStream.getTracks();
        tracks.forEach((track) => track.stop());
      }
      history.goBack();
    });

    return () => socket.close();
  }, [iceServers, history, localStream, remoteStream]);

  useEffect(() => {
    peerRef.current = new RTCPeerConnection({ iceServers });
    peerRef.current.oniceconnectionstatechange = () => {
      // console.log('connection state: ', peerRef.current.iceConnectionState);
      setConnectionInfo(peerRef.current.iceConnectionState);
    };
    peerRef.current.ontrack = handleRemoteStream;
    peerRef.current.onicecandidate = handleIceCandidates;
  }, [iceServers]);

  const handleIceCandidates = ({ candidate }) => {
    if (candidate) {
      socketRef.current.emit('candidate', candidate);
    }
  };

  const call = useCallback(async () => {
    try {
      if (peerRef.current === null) {
        peerRef.current = new RTCPeerConnection({ iceServers });
        await getMediaDevices();
        const sdp = await peerRef.current.createOffer({
          iceRestart: true,
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        });
        peerRef.current.setLocalDescription(sdp);
        socketRef.current.emit('offer', sdp);
      } else {
        const sdp = await peerRef.current.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        });
        peerRef.current.setLocalDescription(sdp);
        socketRef.current.emit('offer', sdp);
      }
    } catch (error) {
      console.log(error);
    }
  }, [iceServers]);

  const answer = async () => {
    try {
      setIncomingCall(false);
      const sdp = await peerRef.current.createAnswer();
      peerRef.current.setLocalDescription(sdp);
      socketRef.current.emit('answer', sdp);
    } catch (error) {
      console.log(error);
    }
  };

  const endCall = useCallback(() => {
    socketRef.current.emit('endCall');
    peerRef.current.close();
    setConnectionInfo('disconnected');
    peerRef.current = null;
    if (localStream !== null) {
      let tracks = localStream.getTracks();
      tracks.forEach((track) => track.stop());
    }
    if (remoteStream !== null) {
      let tracks = remoteStream.getTracks();
      tracks.forEach((track) => track.stop());
    }
    history.goBack();
  }, [history, localStream, remoteStream]);

  const getMediaDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      if (devices.length !== 0) {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: { echoCancellation: true },
          video: {
            width: { min: 640, ideal: 1280, max: 1920 },
            height: { min: 360, ideal: 720, max: 1080 },
          },
        });
        if (stream) {
          localStreamRef.current.srcObject = stream;
          setLocalStream(stream);
          for (const track of stream.getTracks()) {
            peerRef.current.addTrack(track, stream);
          }
        }
      } else {
        setErrors({
          type: 'devmiss',
          title:
            'Vaya, perece que no cumples con los requisitos minimos para la video consulta.',
          details:
            'Falta microfono o camara web. Por favor revisa o vuelve a conectarlos!',
        });
      }
    } catch (error) {
      setErrors({
        type: 'permission',
        title:
          'Se ha producido un error al intentar acceder a la camara o el microfono',
        details:
          'Para realizar una video consulta debes activar la camara y el microfono. De este modo, las personas podran verte y oirte durante la video consulta. Puedes desactivarlas mas tarde si lo prefieres.',
      });
    }
  };
  const handleRemoteStream = (event) => {
    if (event.streams.length !== 0) {
      remoteStreamRef.current.srcObject = event.streams[0];
      setRemoteStream(event.streams[0]);
    }
  };

  const handleMute = useCallback(() => {
    const audioTracks = localStream.getTracks();
    audioTracks.forEach((track) => {
      if (track.kind === 'audio') {
        track.enabled = !track.enabled;
        setMuteAudio(!track.enabled);
      }
    });
  }, [localStream]);

  const handleHideVideo = useCallback(() => {
    const audioTracks = localStream.getTracks();
    audioTracks.forEach((track) => {
      if (track.kind === 'video') {
        track.enabled = !track.enabled;
        setHideVideo(!track.enabled);
      }
    });
  }, [localStream]);

  const handleCamRender = useCallback(() => {
    let node;
    if (remoteStream !== null) {
      node = (
        <>
          <video ref={remoteStreamRef} autoPlay />
          <div className='self-cam-container'>
            <video ref={localStreamRef} autoPlay muted={true} />
          </div>
        </>
      );
    } else {
      node = (
        <>
          <video ref={remoteStreamRef} autoPlay />
          <div className='self-cam-container'>
            <video ref={localStreamRef} autoPlay muted={true} />
          </div>
        </>
      );
    }
    return node;
  }, [remoteStream]);
  return (
    <div className='video-call-wrapper'>
      {incomingCall && (
        <div className='video-call-answer-container'>
          <span>
            Video consulta preparada. Abogada Alina Wagner esta esperando tu
            conexión.
          </span>
          <span>Por favor pulsa EMPEZAR CONSULTA!</span>
          <button className='btn btn-flat red white-text' onClick={answer}>
            empezar consulta
          </button>
        </div>
      )}
      {handleCamRender()}
      <div className='controls-wrapper'>
        <div className='controls-container'>
          <button
            className='btn-call-common transparent white-text'
            onClick={handleMute}
          >
            <FontAwesomeIcon
              icon={muteAudio ? faMicrophoneSlash : faMicrophone}
              size='2x'
            />
          </button>
          <button
            className='btn-end-call transparent white-text'
            onClick={connectionInfo === 'connected' ? endCall : call}
          >
            <FontAwesomeIcon
              icon={connectionInfo === 'connected' ? faStop : faPlay}
              size='3x'
              color={connectionInfo === 'connected' ? 'red' : 'white'}
            />
          </button>
          <button
            className='btn-call-common transparent white-text'
            onClick={handleHideVideo}
          >
            <FontAwesomeIcon
              icon={hideVideo ? faVideoSlash : faVideo}
              size='2x'
            />
          </button>
        </div>
      </div>
      {/* <div className='video-call-stats-wrapper'>
        <span>
          Connection State:
          {connectionInfo}
        </span>
      </div> */}
      {!isEmpty(errors) && (
        <div className='video-status-info'>
          {errors.type === 'devmiss' && (
            <div>
              <div>
                <FontAwesomeIcon
                  icon={faMicrophoneSlash}
                  size='2x'
                  color='red'
                />
                <FontAwesomeIcon icon={faVideoSlash} size='2x' color='red' />
              </div>
              <span>{errors.title}</span>
              <span>{errors.details}</span>
            </div>
          )}
          {errors.type === 'permission' && (
            <div>
              <div>
                <FontAwesomeIcon
                  icon={faMicrophoneSlash}
                  size='2x'
                  color='red'
                />
                <FontAwesomeIcon icon={faVideoSlash} size='2x' color='red' />
              </div>
              <span>{errors.title}</span>
              <span>{errors.details}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VideoCall;
