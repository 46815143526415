import React, { useEffect, useState, useCallback } from 'react';
import M from 'materialize-css';
import DatosTab from './DataTabs/DatosTab';
import CasosTab from './DataTabs/CasosTab';
import ConsultasTab from './DataTabs/ConsultasTab';
import SettingsTab from './DataTabs/SettingsTab';
import isEmpty from 'is-empty';

const Client = ({ userInfo }) => {
  const [categories] = useState([
    'Otro',
    'Penal',
    'Laboral',
    'Familia',
    'Civil',
    'Extranjeria',
  ]);
  const [showDatos, setShowDatos] = useState(true);
  const [showCasos, setShowCasos] = useState(false);
  const [showConsultas, setShowConsultas] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    const modal = document.querySelectorAll('.modal');
    M.Modal.init(modal, {});
    setTimeout(() => {
      M.updateTextFields();
    }, 300);
  }, []);

  const renderTabs = useCallback(() => {
    let result = <DatosTab userInfo={userInfo} />;
    if (showDatos) {
      result = <DatosTab userInfo={userInfo} />;
    } else if (showCasos) {
      result = <CasosTab userInfo={userInfo} categories={categories} />;
    } else if (showConsultas) {
      result = <ConsultasTab userInfo={userInfo} />;
    } else {
      result = <SettingsTab userInfo={userInfo} />;
    }
    return result;
  }, [showDatos, showCasos, showConsultas, categories, userInfo]);

  const selectTab = useCallback((tab) => {
    switch (tab) {
      case 'datos':
        setShowDatos(true);
        setShowCasos(false);
        setShowConsultas(false);
        setShowSettings(false);
        break;
      case 'casos':
        setShowDatos(false);
        setShowCasos(true);
        setShowConsultas(false);
        setShowSettings(false);
        break;
      case 'consultas':
        setShowDatos(false);
        setShowCasos(false);
        setShowConsultas(true);
        setShowSettings(false);
        break;
      case 'settings':
        setShowDatos(false);
        setShowCasos(false);
        setShowConsultas(false);
        setShowSettings(true);
        break;
      default:
        break;
    }
  }, []);
  return (
    <li>
      <div className='collapsible-header custom-collapsible'>
        <div>
          <i className='material-icons'>account_circle</i>
          <span className='truncate'>{`${userInfo.firstName} ${userInfo.lastName}`}</span>
        </div>
        <div>
          <span>
            {!isEmpty(userInfo) && userInfo.user.isClient
              ? 'cliente'
              : 'usuario'}
          </span>
        </div>
      </div>
      <div className='collapsible-body enable-card-overflow-y'>
        <div className='row center'>
          <div className='col s3'>
            <button
              className={showDatos ? 'tab-btn-cli-active' : 'tab-btn-cli'}
              onClick={() => selectTab('datos')}
            >
              <span>Datos</span>
            </button>
          </div>
          <div className='col s3'>
            <button
              className={showCasos ? 'tab-btn-cli-active' : 'tab-btn-cli'}
              onClick={() => selectTab('casos')}
            >
              <span>Casos</span>
            </button>
          </div>
          <div className='col s3'>
            <button
              className={showConsultas ? 'tab-btn-cli-active' : 'tab-btn-cli'}
              onClick={() => selectTab('consultas')}
            >
              <span>Consultas</span>
            </button>
          </div>
          <div className='col s3'>
            <button
              className={showSettings ? 'tab-btn-cli-active' : 'tab-btn-cli'}
              onClick={() => selectTab('settings')}
            >
              <span>Opciones</span>
            </button>
          </div>
        </div>
        <div className='row'>{renderTabs()}</div>
      </div>
    </li>
  );
};

export default Client;
