import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
import GoogleWidget from '../misc/GoogleWidget';
import { useSelector } from 'react-redux';

const Footer = (props) => {
  const auth = useSelector((state) => state.auth);
  return (
    !auth.isAuthenticated && (
      <footer
        className='page-footer footer-container-custom'
        style={{ backgroundColor: 'rgb(26,26,26)' }}
      >
        <div className='row'>
          <div className='col l4 s12'>
            <h5 className='white-text'>Contacto</h5>
            <p>
              <a
                href='https://www.google.com/maps/place/ABOGADOS+ALCAL%C3%81+DE+HENARES/@40.491299,-3.3652754,17z/data=!4m13!1m7!3m6!1s0xd4249692315f36b:0xa355a019d7762005!2sCalle+Caraba%C3%B1a,+8,+28806+Alcal%C3%A1+de+Henares,+Madrid!3b1!8m2!3d40.491299!4d-3.3630867!3m4!1s0xd42492d16d85733:0x1bd1828bf37d0eaf!8m2!3d40.491299!4d-3.363087'
                className='contact-links'
              >
                {`C/ Carabaña nº8, Oficina 1, Alcala de Henares Madrid`}
              </a>
            </p>
            {/* <p>
                <a
                  href='tel:+34911404777'
                  className='contact-links'>{`TEL: +34 911 404 777`}</a>
              </p> */}
            <p>
              <a
                href='tel:+34642767633'
                className='contact-links'
              >{`Movil: +34 642 767 633`}</a>
            </p>
            <p>
              <a href='mailto:abogada@ajiverdeanu.es' className='contact-links'>
                abogada@ajiverdeanu.es
              </a>
            </p>
          </div>
          <div className='col l4 s12'>
            <h5 className='white-text'>Redes Sociales</h5>
            <div className='social-footer-icons-wrapper'>
              <div className='social-footer-icons'>
                <a href='https://www.facebook.com/avocatromanalcala'>
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    size='2x'
                    className='blue-text text-darken-2'
                  />
                </a>
                <a href='https://www.twitter.com'>
                  <FontAwesomeIcon
                    icon={faTwitter}
                    size='2x'
                    className='blue-text text-lighten-1'
                  />
                </a>
                <a href='https://www.linkedin.com/in/despachoabogados/'>
                  <FontAwesomeIcon
                    icon={faLinkedinIn}
                    size='2x'
                    className='blue-text text-darken-2'
                  />
                </a>
                <a href='https://www.elabogado.com/alina-jiverdeanu/'>
                  <img
                    src={require('../../assets/mockups/elabogado.webp')}
                    alt=''
                    style={{ width: '140px', marginTop: '10px' }}
                  />
                </a>
              </div>
            </div>
            <div style={{ marginTop: '10px' }}>
              <p>© {new Date().getFullYear()} Copyright Alina Wagner</p>
              <p>
                Diseño web{' '}
                <a
                  href='https://www.dejolt.com'
                  style={{ fontWeight: 'bold' }}
                  className='blue-text darken-4'
                >
                  Dejolt
                </a>
              </p>
            </div>
          </div>
          <div className='col l4 s12'>
            <div className='center'>
              <GoogleWidget />
              <p style={{ fontSize: '8px', color: 'rgb(26,26,26)' }}>
                abogado rumano madrid avocat roman
              </p>
            </div>
          </div>
        </div>
        <div className='bottom-menu'>
          <Link to='/' className='grey-text text-lighten-4'>
            {`INICIO`}
          </Link>
          <Link to='/despacho' className='grey-text text-lighten-4'>
            {`DESPACHO`}
          </Link>
          <Link to='/areas' className='grey-text text-lighten-4'>
            {`AREAS`}
          </Link>
          <Link to='/blog' className='grey-text text-lighten-4'>
            {`BLOG`}
          </Link>
          <Link to='/contacto' className='grey-text text-lighten-4'>
            {`CONTACTO`}
          </Link>
          <Link to='/consultas' className='grey-text text-lighten-4'>
            {`CONSULTAS`}
          </Link>
          <Link to='/legal' className='grey-text text-lighten-4'>
            {`POLITICA DE PRIVACIDAD`}
          </Link>
          <Link to='/cookies' className='grey-text text-lighten-4'>
            {`COOKIES`}
          </Link>
        </div>
      </footer>
    )
  );
};
export default Footer;
