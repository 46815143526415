import React from 'react';
import { Link } from 'react-router-dom';

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <ul className='pagination'>
      <li className='disabled'>
        <a href='#!'>
          <i className='material-icons'>chevron_left</i>
        </a>
      </li>
      {pageNumbers.map((page) => {
        if (page === currentPage) {
          return (
            <li key={page} className='waves-effect active'>
              <Link to={`/blog/pag/${page}`} onClick={() => paginate(page)}>
                {page}
              </Link>
            </li>
          );
        } else {
          return (
            <li key={page} className='waves-effect'>
              <Link to={`/blog/pag/${page}`} onClick={() => paginate(page)}>
                {page}
              </Link>
            </li>
          );
        }
      })}
      <li className='disabled'>
        <a href='#!'>
          <i className='material-icons'>chevron_right</i>
        </a>
      </li>
    </ul>
  );
};

export default Pagination;
