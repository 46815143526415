import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { zoomIn } from 'react-animations';

const ScrollToTopBtn = (props) => {
  const gotoTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className={css(styles.topBtn)}>
      <div className={css(styles.btnWrapper)}>
        <a href='#!' className={css(styles.btn)} onClick={gotoTop}>
          Subir
        </a>
        <div className={css(styles.line)} />
      </div>
    </div>
  );
};
const styles = StyleSheet.create({
  topBtn: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: '1',
    animationName: zoomIn,
    animationDuration: '2s',
  },
  line: {
    marginTop: '10px',
    height: '100px',
    borderLeft: '2px solid red',
    zIndex: '1',
  },
  btn: {
    color: 'red',
    transform: 'rotate(-90deg)',
    marginRight: '5px',
  },
  btnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});
export default ScrollToTopBtn;
