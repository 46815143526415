import {
  RESET_UPLOAD_PROGRESS,
  UPDATE_UPLOAD_PROGRESS,
  FILE_UPLOADING,
  SET_SUCCESS_MSG,
  GET_ALL_CLIENTS,
  ADD_POST_COVER,
  GET_ERRORS,
} from './types';
import axios from 'axios';

const handleUploadProgress = (progress) => {
  let prog = {};
  prog.progress = Math.round((progress.loaded * 100) / progress.total) / 100;
  prog.totalSize = progress.total;
  prog.loaded = progress.loaded;
  return prog;
};

export const uploadFile2Client = (data) => (dispatch) => {
  dispatch(setFileUploading());
  axios
    .post('/admin/4l1n4/clients/client/doc/upload', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (event) =>
        dispatch({
          type: UPDATE_UPLOAD_PROGRESS,
          payload: handleUploadProgress(event),
        }),
    })
    .then((res) => {
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
      dispatch({ type: GET_ALL_CLIENTS, payload: res.data.clients });
      dispatch(resetUploadProgress());
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
export const uploadPostPic = (data) => (dispatch) => {
  dispatch(setFileUploading());
  axios
    .post('/admin/4l1n4/posts/post/cover/upload', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (event) =>
        dispatch({
          type: UPDATE_UPLOAD_PROGRESS,
          payload: handleUploadProgress(event),
        }),
    })
    .then((res) => {
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
      dispatch({ type: ADD_POST_COVER, payload: res.data.clients });
      dispatch(resetUploadProgress());
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const setFileUploading = () => {
  return {
    type: FILE_UPLOADING,
  };
};
export const resetUploadProgress = () => {
  return {
    type: RESET_UPLOAD_PROGRESS,
  };
};
