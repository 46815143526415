import {
  ADD_POST,
  DELETE_POST,
  ADD_POST_COVER,
  DELETE_POST_COVER,
  GET_ALL_POSTS,
  GET_POST,
  GET_FEATURED_POSTS,
  POST_LOADING,
  GET_ALL_POSTS_BY_DATE_LIMITED,
  GET_ALL_CATEGORIES,
  EDIT_POST,
  GET_ALL_POSTS_BY_CATEGORY,
  PUBLISH_POST,
  CLEAR_POST,
  TOGGLE_HIDE_SHOW_POST,
} from '../actions/types';

const initialState = {
  post: {},
  posts: [],
  postsByDateLimited: [],
  featured: [],
  post_loading: false,
  categories: [],
};

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_POST:
      return {
        ...state,
        post: action.payload,
      };
    case TOGGLE_HIDE_SHOW_POST:
      return {
        ...state,
        posts: action.payload,
      };
    case CLEAR_POST:
      return {
        ...state,
        post: {},
      };
    case PUBLISH_POST:
      return {
        ...state,
        post: action.payload,
      };
    case EDIT_POST:
      return {
        ...state,
        post: action.payload,
      };
    case ADD_POST_COVER:
      return {
        ...state,
        post: action.payload,
      };
    case GET_ALL_POSTS:
      return {
        ...state,
        posts: action.payload,
      };
    case GET_ALL_POSTS_BY_DATE_LIMITED:
      return {
        ...state,
        postsByDateLimited: action.payload,
      };
    case GET_ALL_POSTS_BY_CATEGORY:
      return {
        ...state,
        postsByDateLimited: action.payload,
      };
    case GET_POST:
      return {
        ...state,
        post: action.payload,
      };
    case GET_ALL_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case GET_FEATURED_POSTS:
      return {
        ...state,
        featured: action.payload,
      };
    case POST_LOADING:
      return {
        ...state,
        post_loading: true,
      };
    case DELETE_POST:
      return {
        ...state,
        posts: action.payload,
      };
    case DELETE_POST_COVER:
      return {
        ...state,
        post: action.payload,
      };
    default:
      return state;
  }
};
export default blogReducer;
