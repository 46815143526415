import React, { useState, useEffect, useContext } from 'react';
import isEmpty from 'is-empty';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { sendNewContactMessage } from '../../redux/actions/contactActions';
import { MainInfoBanner } from '../misc/Messages';
import { Helmet } from 'react-helmet';
import MainContext from '../context/MainContext';

const Contacto = (props) => {
  const mainContext = useContext(MainContext);
  const dispatch = useDispatch();
  const contact = useSelector((state) => state.contact);

  const [position] = useState({
    lat: 40.491353,
    lng: -3.363178,
  });
  const [zoom] = useState(10);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [agreePublicity, setAgreePublicity] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [messageSentMsg, setMessageSentMsg] = useState('');
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (contact.message) {
      if (contact.message.success === true) {
        setMessageSent(true);
        setMessageSentMsg(contact.message.msg);
        setSendButtonDisabled(true);
      }
      if (contact.message.success === false) {
        setMessageSent(false);
        setMessageSentMsg(contact.message.msg);
        setSendButtonDisabled(true);
      } else {
        setErrors(contact.message);
      }
    }
  }, [contact]);
  const clearErrors = () => {
    setErrors({});
    setMessageSentMsg('');
  };
  const handleSubjectElection = (value) => {
    setSubject(value);
  };

  const handleAgreePrivacy = () => {
    setAgreePrivacy(!agreePrivacy);
  };
  const handlePublicity = () => {
    setAgreePublicity(!agreePublicity);
  };
  const sendMessage = () => {
    const newMessage = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      phone: phone.trim(),
      subject: subject.trim(),
      message: message,
      acceptedPrivacyPolicy: agreePrivacy,
      acceptedPublicity: agreePublicity,
    };
    dispatch(sendNewContactMessage(newMessage));
  };
  return (
    <div className='contact-container'>
      <Helmet>
        <title>
          Contacto | Avocat Roman in Madrid Alina Wagner | Abogado Rumano en
          Madrid
        </title>
      </Helmet>
      <div className='row'>
        <div className='col s12 l6'>
          <div className='card'>
            <div className='car-container'>
              <div className='map-container'>
                <MapContainer
                  center={position}
                  zoom={zoom}
                  style={{ height: '50vh' }}
                >
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                  />
                  <Marker position={position}>
                    <Popup>
                      <div>
                        <img
                          src={mainContext.contactImages[0]}
                          alt=''
                          className='responsive-img'
                        />
                        <p style={{ fontWeight: 'bold' }}>
                          Abogada Alina Wagner
                        </p>
                        <p>{`C/ Carabaña nº8, oficina 1`}</p>
                        <p>Alcala de Henares, Madrid</p>
                        <p>Tel: +34 642 767 633</p>
                        <div className='center'>
                          <a
                            href={`https://www.google.com/maps/place/ABOGADOS+ALCAL%C3%81+DE+HENARES/@40.491299,-3.3652754,17z/data=!4m13!1m7!3m6!1s0xd4249692315f36b:0xa355a019d7762005!2sCalle+Caraba%C3%B1a,+8,+28806+Alcal%C3%A1+de+Henares,+Madrid!3b1!8m2!3d40.491299!4d-3.3630867!3m4!1s0xd42492d16d85733:0x1bd1828bf37d0eaf!8m2!3d40.491299!4d-3.363087`}
                            className='btn waves-effect blue lighten-2 white-text'
                          >
                            Como llegar
                          </a>
                        </div>
                      </div>
                    </Popup>
                  </Marker>
                </MapContainer>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col l4 s12'>
              <img
                src={mainContext.contactImages[0]}
                alt=''
                className='responsive-img'
              />
            </div>
            <div className='col s12 l8'>
              <h5>Estamos en la direccion:</h5>
              <p>{`C/ Carabaña nº8, oficina 1`}</p>
              <p>Alcala de Henares, 28806, Madrid</p>
              <p>
                Email:{' '}
                <a href='mailto:abogada@ajiverdeanu.es'>
                  abogada@ajiverdeanu.es
                </a>
              </p>
              <p>Telefono: +34 642 767 633</p>
              <p style={{ color: 'white', fontSize: '6' }}>
                Avocat roman Alina Jiverdeanu Wagner Madrid Abogado Rumano
              </p>
            </div>
          </div>
        </div>
        <div className='col s12 l6'>
          <div className='card'>
            <div className='card-content'>
              <span
                className='card-title center'
                style={{ fontWeight: 'bold' }}
              >
                Formulario de contacto
              </span>
              <div className='row'>
                <div className='input-field col s12 l6'>
                  <input
                    type='text'
                    className='validate'
                    name='firstName'
                    id='firstName'
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  {errors.firstName ? (
                    <label htmlFor='firstName' className='red-text'>
                      Nombre*
                    </label>
                  ) : (
                    <label htmlFor='firstName'>Nombre*</label>
                  )}
                </div>
                <div className='input-field col s12 l6'>
                  <input
                    type='text'
                    className='validate'
                    name='lastName'
                    id='lastName'
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  {errors.lastName ? (
                    <label htmlFor='lastName' className='red-text'>
                      Apellido*
                    </label>
                  ) : (
                    <label htmlFor='lastName'>Apellido*</label>
                  )}
                </div>
                <div className='input-field col s12 l6'>
                  <input
                    type='email'
                    className='validate'
                    name='email'
                    id='email'
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email ? (
                    <label htmlFor='email' className='red-text'>
                      Email*
                    </label>
                  ) : (
                    <label htmlFor='email'>Email*</label>
                  )}
                </div>
                <div className='input-field col s12 l6'>
                  <input
                    type='text'
                    className='validate'
                    name='phone'
                    id='phone'
                    maxLength={9}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  {errors.phone ? (
                    <label htmlFor='phone' className='red-text'>
                      Telefono*
                    </label>
                  ) : (
                    <label htmlFor='phone'>Telefono*</label>
                  )}
                </div>
                <div className='input-field col s12'>
                  <textarea
                    type='text'
                    className='materialize-textarea'
                    name='message'
                    id='msgTextArea'
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  {errors.message ? (
                    <label htmlFor='msgTextArea' className='red-text'>
                      Mensaje*
                    </label>
                  ) : (
                    <label htmlFor='msgTextArea'>Mensaje*</label>
                  )}
                </div>
                <p className='grey-text lighten-2 right'>
                  *Campos obligatorios
                </p>
              </div>
              <div className='row'>
                <div className='col s12 l7'>
                  <div className='checkbox-container'>
                    <p>Elije un tema relacionado con la consulta*</p>
                    <br />
                    <p>
                      <label>
                        <input
                          type='checkbox'
                          onChange={() => handleSubjectElection('civil')}
                        />
                        <span>Civil</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type='checkbox'
                          onChange={() => handleSubjectElection('familia')}
                        />
                        <span>Familia</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type='checkbox'
                          onChange={() => handleSubjectElection('laboral')}
                        />
                        <span>Laboral</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type='checkbox'
                          onChange={() => handleSubjectElection('penal')}
                        />
                        <span>Penal</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type='checkbox'
                          onChange={() => handleSubjectElection('extranjeria')}
                        />
                        <span>Extranjeria</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type='checkbox'
                          onChange={() => handleSubjectElection('otros')}
                        />
                        <span>Informacion General</span>
                      </label>
                    </p>
                  </div>
                  <br />
                  <div className='agree-checkbox'>
                    <p>
                      <label>
                        <input
                          type='checkbox'
                          checked={agreePrivacy}
                          onChange={handleAgreePrivacy}
                        />
                        <span>
                          He leido y acepto la{' '}
                          <Link to='/'>Politica de Privacidad</Link>
                        </span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          type='checkbox'
                          checked={agreePublicity}
                          onChange={handlePublicity}
                        />
                        <span>{`Quiero recibir publicidad y novedades `}</span>
                      </label>
                    </p>
                  </div>
                </div>
                <div className='col s12 l5'>
                  <span
                    className='grey-text lighten-2'
                    style={{ fontSize: '10px' }}
                  >
                    Conforme a lo establecido en la Ley Orgánica 15/1999 de
                    Protección de Datos de Carácter Personal, le informamos que
                    los datos que usted nos facilite se incorporarán a un
                    fichero de AJiverdeanu denominado CLIENTES Y/O PROVEEDORES
                    cuya finalidad es la de ofrecer servicios a nuestros
                    clientes, tales como acceso y/o consultas a sus expedientes,
                    etc... Puede ejercitar sus derechos de acceso rectificación
                    cancelación y oposición mediante un escrito dirigido a C/
                    Carabaña 8, oficina 1, Alcalá de Henares 28806 (Madrid)
                    junto con una fotocopia de su DNI.
                  </span>
                </div>
              </div>
              <div className='row'>
                <div className='col s12'>
                  <div className='center'>
                    {!sendButtonDisabled ? (
                      <button
                        className={`btn waves-effect red ${
                          agreePrivacy ? 'enabled' : 'disabled'
                        }`}
                        onClick={sendMessage}
                      >
                        Enviar
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col s12'>
                  {messageSent ? (
                    <MainInfoBanner
                      message={messageSentMsg}
                      color='green lighten-2'
                      clearMessage={clearErrors}
                    />
                  ) : !isEmpty(messageSentMsg) && messageSent === false ? (
                    <MainInfoBanner
                      message={messageSentMsg}
                      color='red'
                      clearMessage={clearErrors}
                    />
                  ) : null}
                  {!isEmpty(errors) && messageSent === false ? (
                    <MainInfoBanner
                      message='Por favor revisa los campos en rojo!'
                      color='red'
                      clearMessage={clearErrors}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacto;
