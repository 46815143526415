import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { _getAppointments } from '../../redux/actions/appointmentActions';
import AccountCard from '../dashboard/accountCards/AccountCard';
import SecurityCard from '../dashboard/accountCards/SecurityCard';
import NotificationsCard from '../dashboard/accountCards/NotificationsCard';
import SocialCard from '../dashboard/accountCards/SocialCard';
import { getProfileA } from '../../redux/actions/profileActions';

const AdminDashboard = (props) => {
  const dispatch = useDispatch();
  const [showAccount, setShowAccount] = useState(true);
  const [showSecurity, setShowSecurity] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showSocial, setShowSocial] = useState(false);

  useEffect(() => {
    dispatch(_getAppointments());
    dispatch(getProfileA());
  }, [dispatch]);

  const handleTabRender = useCallback(() => {
    let tab = null;

    if (showAccount) {
      tab = <AccountCard />;
    }
    if (showSecurity) {
      tab = <SecurityCard />;
    }
    if (showNotifications) {
      tab = <NotificationsCard />;
    }
    if (showSocial) {
      tab = <SocialCard />;
    }
    return tab;
  }, [showAccount, showSecurity, showNotifications, showSocial]);

  const handleTabSelection = useCallback((selection) => {
    switch (selection) {
      case 'account':
        setShowAccount(true);
        setShowSecurity(false);
        setShowNotifications(false);
        setShowSocial(false);
        break;
      case 'security':
        setShowAccount(false);
        setShowSecurity(true);
        setShowNotifications(false);
        setShowSocial(false);
        break;
      case 'notification':
        setShowAccount(false);
        setShowSecurity(false);
        setShowNotifications(true);
        setShowSocial(false);
        break;
      case 'social':
        setShowAccount(false);
        setShowSecurity(false);
        setShowNotifications(false);
        setShowSocial(true);
        break;
      default:
        break;
    }
  }, []);
  return (
    <div className='dashboard-container'>
      <div className='row'>
        <div className='col s12 center'>
          <h5 className='use-oswald-font'>CUENTA</h5>
          <p className='grey-text'>Ajustes de cuenta y perfil</p>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='card acc-card'>
            <div className='row'>
              <div className='col m2 s12 acc-l-wrapper'>
                <ul>
                  <li>
                    <button
                      onClick={(e) => handleTabSelection('account')}
                      className={`${showAccount && 'acc-l-wrapper-btn-active'}`}
                    >
                      <i className='material-icons'>account_circle</i>
                      <span>Cuenta</span>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={(e) => handleTabSelection('security')}
                      className={`${
                        showSecurity && 'acc-l-wrapper-btn-active'
                      }`}
                    >
                      <i className='material-icons'>security</i>
                      <span>Seguridad</span>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={(e) => handleTabSelection('notification')}
                      className={`${
                        showNotifications && 'acc-l-wrapper-btn-active'
                      }`}
                    >
                      <i className='material-icons'>notifications</i>
                      <span>Notificaciones</span>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={(e) => handleTabSelection('social')}
                      className={`${showSocial && 'acc-l-wrapper-btn-active'}`}
                    >
                      <i className='material-icons'>widgets</i>
                      <span>Redes Sociales</span>
                    </button>
                  </li>
                </ul>
              </div>
              <div className='col m10 s12'>{handleTabRender()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
