import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import contactReducer from './contactReducer';
import clientReducer from './clientReducer';
import blogReducer from './blogReducer';
import profileReducer from './profileReducer';
import appointmentReducer from './appointmentReducer';
import successReducer from './successReducer';
import uploadReducer from './uploadReducer';
import miscReducer from './miscReducer';
import paymentReducer from './paymentReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  errors: errorReducer,
  success: successReducer,
  upload: uploadReducer,
  misc: miscReducer,
  contact: contactReducer,
  client: clientReducer,
  blog: blogReducer,
  profile: profileReducer,
  appointment: appointmentReducer,
  payment: paymentReducer,
});

export default rootReducer;
