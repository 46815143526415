import {
  SET_SUCCESS_MSG,
  CLEAR_SUCCESS_MSG,
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_STATS,
} from './types';
import axios from 'axios';

export const getStats = () => (dispatch) => {
  axios
    .get('/admin/4l1n4/stats')
    .then((res) => dispatch({ type: GET_STATS, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const setSuccess = (msg) => {
  return {
    type: SET_SUCCESS_MSG,
    payload: msg,
  };
};
export const clearSuccess = () => {
  return {
    type: CLEAR_SUCCESS_MSG,
  };
};

export const setErrors = (msg) => {
  return {
    type: GET_ERRORS,
    payload: msg,
  };
};
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
