import React from 'react';

const AdminCalHours = ({ appointment, handleSelectedDate, day }) => {
  return (
    <div className='admin-cal-hour'>
      {day.dayOff ? (
        <div className='day-off'>
          <i className='material-icons red-text'>close</i>
        </div>
      ) : (
        <label>
          <input
            type='checkbox'
            checked={appointment.busy}
            onChange={() => handleSelectedDate(appointment, day)}
          />
          <span></span>
        </label>
      )}
    </div>
  );
};

export default AdminCalHours;
