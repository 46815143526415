import React from 'react';
import { Link } from 'react-router-dom';

const Laboral = () => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col s12 center'>
          <h6>
            <strong>DERECHO LABORAL</strong>
          </h6>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 m6'>
          <div className='areas-items'>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Acciones de reclamación de salarios</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Acciones de despido</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Infracciones y sanciones</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Acciones indemnizatorias</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>
                  Acciones de extinción de contrato por causas objetivas,
                  económicas, etc.
                </span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>
                  Vacaciones, clasificación profesional, movilidad geográfica y
                  funcional
                </span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>
                  Tramitación de expedientes de extinción de contratos de
                  trabajo.
                </span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>
                  {`Modificación de las condiciones individuales de trabajo (jornada, horario, turnos, retribución, etc.)`}
                </span>
              </label>
            </p>
          </div>
        </div>
        <div className='col s12 m6'>
          <p>
            Rama del Derecho que regula las relaciones de las disposiciones
            laborales, ya sea como trabajador por cuenta ajena o por cuenta
            propia, entre otras áreas importantes.
          </p>
          <p>
            Junto con los textos legales que regulan el Derecho del Trabajo,
            especialmente en el Estatuto de los Trabajadores, en esta rama del
            Derecho se incluye la regulación de las relaciones laborales
            especiales como los ejecutivos o los empleados domésticos, así como
            el importante tema que implica la Seguridad Social y, finalmente, la
            regulación dedicada a la libertad de asociación.
          </p>
          <p>
            Materias como contratos, accidentes de trabajo, Seguridad Social,
            riesgos laborales, constituyen un material constante y reiterado que
            en bufetes como el nuestro tienen esta rama del derecho de frecuente
            ejercicio.
          </p>
        </div>
        <div className='row'>
          <div className='col s12'>
            <p>
              El abogado del despacho te explicará los diferentes procedimientos
              y vías por las que puedes optar. Otras areas del derecho laboral
              en el que te podemos ayudar:
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col s12 m6'>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Suspensión o reducción de jornada</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>{`Movilidad funcional (superior/inferior categoría) y movilidad geográfica (desplazamientos y traslados)`}</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Redacción de adendas y acuerdos</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>{`Tiempo de trabajo: reducción de jornada, excedencias, horas extras y registro horario tras la entrada en vigor del RD Ley 8/2019, etc`}</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>{`Cambio de empresario: Cesión ilegal de mano de obra, subcontratación y sucesión de empresas o plantillas`}</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>
                  {`Dirección letrada ante los Juzgados y Tribunales de la jurisdicción social, en todas sus instancias en pleitos de carácter individual: reclamaciones de derechos y cantidad, despidos, movilidad funcional, protección de derechos fundamentales, modificación de condiciones de trabajo, etc`}
                </span>
              </label>
            </p>
          </div>
          <div className='col s12 m6'>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>{`Extinción del contrato de trabajo por motivos disciplinarios o por causas objetivas. Intervención en procesos disciplinarios a trabajadores, redacción de comunicaciones de sanción o despido`}</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>{`Vulneración de derechos fundamentales. Derecho a la intimidad, uso de dispositivos digitales en el ámbito laboral, control del correo, instalación de cámaras, protección de datos (LOPD), vestuario y aspecto físico, etc`}</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>{`Orientación en materia de Seguridad Social: INSS, TGSS, SEPE, FOGASA, etc`}</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>{`Inspección de Trabajo y de la Seguridad Social: asesoramiento, recursos, etc`}</span>
              </label>
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col s12 center'>
            <h5>
              Para cualquier duda o consulta sobre <i>Derecho Laboral</i> no
              dude en ponerse en contacto con nosotros
            </h5>
            <Link
              to='/contacto'
              className='btn-flat red white-text darken-1 waves-effect'>
              NECESITO MAS INFORMACION
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Laboral;
