import React from 'react';

export const MainInfoBanner = ({ message, color, clearMessage }) => {
  return (
    <div className={`main-info-banner ${color}`}>
      <span className='white-text'>{message}</span>
      <a href='#!' onClick={() => clearMessage()}>
        <i className='material-icons white-text'>close</i>
      </a>
    </div>
  );
};
