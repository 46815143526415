//logo
import logo from '../assets/logo/logo_wagner.png';
import logoWhite from '../assets/logo/logo_white.png';

//landing
import img1 from '../assets/mockups/alina_4.webp';
import img2 from '../assets/mockups/alina_4_small.webp';
import img3 from '../assets/mockups/justice_copy.webp';
import img4 from '../assets/mockups/family_5.webp';
import img5 from '../assets/mockups/work.webp';
import img6 from '../assets/mockups/criminal_copy.webp';
import img7 from '../assets/mockups/passport_3.webp';

//despacho
import img8 from '../assets/mockups/7.webp';
import img9 from '../assets/mockups/5.webp';

//areas
import img10 from '../assets/mockups/zamora.webp';

//consulta
import img11 from '../assets/mockups/mac.webp';
import img12 from '../assets/mockups/v_chat.svg';
import img13 from '../assets/mockups/pay.svg';
import img14 from '../assets/mockups/secured.svg';

//auth
import img15 from '../assets/mockups/register.svg';

//account
import img16 from '../assets/mockups/reminder.svg';
import img17 from '../assets/mockups/no_cita.webp';
import img18 from '../assets/mockups/no_data.webp';
import img19 from '../assets/mockups/online_cal.svg';

//contacto
import img20 from '../assets/mockups/oficina.webp';

export const Caches = {
  logos: [logo, logoWhite],
  landing: [img1, img2, img3, img4, img5, img6, img7],
  despacho: [img8, img9],
  areas: [img10],
  consulta: [img11, img12, img13, img14],
  auth: [img15],
  account: [img16, img17, img18, img19],
  contact: [img20],
};
