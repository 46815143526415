import {
  DELETE_POST,
  GET_ALL_POSTS_BY_CATEGORY,
  GET_ALL_POSTS,
  GET_POST,
  GET_FEATURED_POSTS,
  POST_LOADING,
  GET_ALL_POSTS_BY_DATE_LIMITED,
  GET_ALL_CATEGORIES,
  SET_SUCCESS_MSG,
  GET_ERRORS,
  CLEAR_POST,
  PUBLISH_POST,
  ADD_POST,
  TOGGLE_HIDE_SHOW_POST,
} from './types';
import axios from 'axios';

export const deletePost = (data) => (dispatch) => {
  dispatch(setPostLoading());
  axios
    .post('/admin/4l1n4/posts/post/delete', data)
    .then((res) => {
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
      dispatch({ type: DELETE_POST, payload: res.data.posts });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const publishPost = (data) => (dispatch) => {
  dispatch(setPostLoading());
  axios
    .post('/admin/4l1n4/posts/post/publish', data)
    .then((res) => {
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
      dispatch({ type: PUBLISH_POST, payload: res.data.post });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const addPost = (data) => (dispatch) => {
  dispatch(setPostLoading());
  axios
    .post('/admin/4l1n4/posts/post/add', data)
    .then((res) => {
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
      dispatch({ type: ADD_POST, payload: res.data.post });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const getAllPostsUnfiltered = () => (dispatch) => {
  dispatch(setPostLoading());
  axios
    .get('/admin/4l1n4/posts/all')
    .then((res) => dispatch({ type: GET_ALL_POSTS, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const toggleShowHidePost = (data) => (dispatch) => {
  dispatch(setPostLoading());
  axios
    .post('/admin/4l1n4/posts/post/showhide/toggle', data)
    .then((res) => {
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
      dispatch({ type: TOGGLE_HIDE_SHOW_POST, payload: res.data.posts });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const getAllPosts = () => (dispatch) => {
  dispatch(setPostLoading());
  axios
    .get('/blog/posts/all')
    .then((res) => dispatch({ type: GET_ALL_POSTS, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
export const getAllCategories = () => (dispatch) => {
  dispatch(setPostLoading());
  axios
    .get('/blog/posts/all/categories')
    .then((res) => dispatch({ type: GET_ALL_CATEGORIES, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
export const getAllPostsByDateLimited = (limit) => (dispatch) => {
  dispatch(setPostLoading());
  axios
    .get(`/blog/posts/latest/${limit}`)
    .then((res) =>
      dispatch({ type: GET_ALL_POSTS_BY_DATE_LIMITED, payload: res.data })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const getAllPostsByCategory = (cat, limit) => (dispatch) => {
  dispatch(setPostLoading());
  axios
    .get(`/blog/posts/latest/cat/${cat}/${limit}`)
    .then((res) =>
      dispatch({ type: GET_ALL_POSTS_BY_CATEGORY, payload: res.data })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const getFeaturedPosts = () => (dispatch) => {
  dispatch(setPostLoading());
  axios
    .get('/blog/posts/all/featured')
    .then((res) => dispatch({ type: GET_FEATURED_POSTS, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const _getPostById = (postId) => (dispatch) => {
  dispatch(setPostLoading());
  axios
    .get(`/admin/4l1n4/posts/post/${postId}`)
    .then((res) => dispatch({ type: GET_POST, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const getPostById = (postId) => (dispatch) => {
  dispatch(setPostLoading());
  axios
    .get(`/blog/posts/${postId}`)
    .then((res) => dispatch({ type: GET_POST, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const clearThePost = () => {
  return { type: CLEAR_POST };
};
export const setPostLoading = () => {
  return {
    type: POST_LOADING,
  };
};
