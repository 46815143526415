import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  convert2Client,
  suspendUser,
  deleteUser,
} from '../../../../redux/actions/clientActions';

const SettingsTab = ({ userInfo }) => {
  const dispatch = useDispatch();

  const convertClient = useCallback(() => {
    dispatch(convert2Client({ userID: userInfo.user._id }));
  }, [dispatch, userInfo]);

  const suspendTheUser = useCallback(() => {
    dispatch(suspendUser({ userID: userInfo.user._id }));
  }, [dispatch, userInfo]);

  const delUser = useCallback(() => {
    dispatch(deleteUser({ userID: userInfo.user._id }));
  }, [dispatch, userInfo]);
  return (
    <div className='tab-cli'>
      <div className='row'>
        <div className='col s12 m4'>
          <p className='blue-text'>Administrar</p>
          <p>
            <label>
              <input
                type='checkbox'
                checked={userInfo.user.isSuspended}
                onChange={suspendTheUser}
              />
              <span>Usuario bloqueado</span>
            </label>
          </p>
        </div>
        <div className='col s12 m4'>
          <p className='blue-text'>Publicidad</p>
          <p>
            <label>
              <input
                type='checkbox'
                checked={userInfo.notifications.ads}
                readOnly
              />
              <span>Accepta recibir publicidad</span>
            </label>
          </p>
          <p>
            <label>
              <input
                type='checkbox'
                checked={userInfo.notifications.thirdParty}
                readOnly
              />
              <span>Accepta compartir datos con terceros</span>
            </label>
          </p>
        </div>
        <div className='col s12 m4'>
          <p className='blue-text'>Ayuda</p>
          <p>
            <strong>Usuario bloqueado: </strong>
            <i>
              el usuario esta bloqueado y no puede acceder a su cuenta mientras
              esta opcion esta activada.
            </i>
          </p>
          <p className='blue-text'>
            <strong>CONVERTIR EN CLIENTE: </strong>
            <i>
              convierte el usuario en en cliente sin la necesidad de crear uno
              nuevo.
            </i>
          </p>
          <p className='red-text'>
            <strong>ELIMINAR USUARIO: </strong>
            <i>elimina completamente el usuario del sistema.</i>
            <strong>
              Usar con cuidado porque los datos no se pueden recuperar.
            </strong>
          </p>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <button
            className='btn-flat blue waves-effect white-text'
            onClick={convertClient}
          >
            convertir en cliente
          </button>
          <button
            className='btn-flat red waves-effect white-text right'
            onClick={delUser}
          >
            eliminar usuario
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsTab;
