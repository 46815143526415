import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { registerUser } from '../../redux/actions/authActions';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import MainContext from '../context/MainContext';

const Register = (props) => {
  const mainContext = useContext(MainContext);
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [rEmail, setREmail] = useState('');
  const [phone, setPhone] = useState('');
  const [rPassword, setRPassword] = useState('');
  const [rPassword2, setRPassword2] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [agreeTOS, setAgreeTOS] = useState(false);

  const registerMe = (e) => {
    e.preventDefault();
    const data = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: rEmail.trim(),
      phone,
      password: rPassword,
      password2: rPassword2,
    };
    dispatch(registerUser(data));
  };
  return (
    <div className='register-container'>
      <img
        src={mainContext.authImages[0]}
        className='hide-on-small-and-down'
        alt='background'
      />
      <div className='register-card-wrapper'>
        <div className='auth-title'>
          <h5>Nuevo Cliente</h5>
          <span>
            Ya tienes cuenta?{' '}
            <a href='#loginModal' className='modal-trigger'>
              ENTRA
            </a>
          </span>
        </div>
        <div className='input-fields'>
          <div className='custom-input-field'>
            <input
              name='firstName'
              type='text'
              id='firstName'
              placeholder='Nombre'
              className='validate'
              onChange={(event) => setFirstName(event.target.value)}
            />
          </div>
          <div className='custom-input-field'>
            <input
              name='lastName'
              type='text'
              id='lastName'
              placeholder='Apellidos'
              className='validate'
              onChange={(event) => setLastName(event.target.value)}
            />
          </div>
          <div className='custom-input-field'>
            <input
              name='rEmail'
              type='email'
              id='rEmail'
              placeholder='Email'
              className='validate'
              onChange={(event) => setREmail(event.target.value)}
            />
          </div>
          <div className='custom-input-field'>
            <input
              name='phone'
              type='text'
              id='phone'
              placeholder='Telefono'
              className='validate'
              maxLength={9}
              onChange={(event) => setPhone(event.target.value)}
            />
          </div>
          <div className='custom-input-field'>
            <input
              name='rPassword'
              type={showPass ? 'text' : 'password'}
              id='rPassword'
              placeholder='Contraseña'
              className='validate'
              onChange={(event) => setRPassword(event.target.value)}
            />
          </div>
          <div className='custom-input-field'>
            <input
              name='rPassword2'
              type={showPass ? 'text' : 'password'}
              id='rPassword2'
              placeholder='Verificar Contraseña'
              className='validate'
              onChange={(event) => setRPassword2(event.target.value)}
            />
          </div>
          <button
            className='btn-flat eye-btn'
            onClick={() => setShowPass(!showPass)}
          >
            <FontAwesomeIcon icon={showPass ? faEye : faEyeSlash} size='1x' />
            Ver Contraseña
          </button>
        </div>
        <div className='row center'>
          <p>
            <label>
              <input
                type='checkbox'
                checked={agreeTOS}
                onChange={() => setAgreeTOS(!agreeTOS)}
              />
              <span>
                Estoy de acuerdo con los{' '}
                <Link to='/legal'>Terminos de Servicio</Link>
              </span>
            </label>
          </p>
          <button
            className={`btn waves-effect red ${!agreeTOS && 'disabled'}`}
            onClick={registerMe}
          >
            Crear Cuenta
          </button>
          <div className='auth-footer'>
            <span className='grey-text'>
              Tienes problemas para crear tu cuenta?
            </span>
            <Link to='/contacto'>Contacto</Link>
          </div>
          <div className='auth-footer'>
            <a href='#verifyEmailModal' className='modal-trigger'>
              Activar cuenta
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
