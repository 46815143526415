import React, { useCallback, useEffect, useState } from 'react';
import M from 'materialize-css';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'is-empty';
import { editProfile } from '../../../redux/actions/profileActions';

const TabAccount = (props) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);

  const [enableEdit, setEnableEdit] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [legalID, setLegalID] = useState('');
  const [phone, setPhone] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('España');
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (!isEmpty(profile.profile)) {
      setFirstName(profile.profile.firstName);
      setLastName(profile.profile.lastName);
      setLegalID(
        !isEmpty(profile.profile.legalID) ? profile.profile.legalID : ''
      );
      setPhone(profile.profile.phone);
      setEmail(profile.profile.user.email);
      setStreet(profile.profile.address.street);
      setNumber(profile.profile.address.number);
      setPostalCode(profile.profile.address.postalCode);
      setCity(profile.profile.address.city);
      // setCountry(profile.profile.address.country);
    }
  }, [profile]);

  useEffect(() => {
    setTimeout(() => {
      M.updateTextFields();
    }, 300);
  }, []);

  const handleEnableEdit = useCallback(() => {
    setEnableEdit(!enableEdit);
  }, [enableEdit]);

  const handleTextChange = useCallback((e, field) => {
    switch (field) {
      case 'firstName':
        setFirstName(e.target.value);
        break;
      case 'lastName':
        setLastName(e.target.value);
        break;
      case 'legalID':
        setLegalID(e.target.value);
        break;
      case 'phone':
        setPhone(e.target.value);
        break;
      case 'street':
        setStreet(e.target.value);
        break;
      case 'number':
        setNumber(e.target.value);
        break;
      case 'postalCode':
        setPostalCode(e.target.value);
        break;
      case 'city':
        setCity(e.target.value);
        break;
      case 'country':
        setCountry(e.target.value);
        break;
      default:
        break;
    }
  }, []);

  const saveData = useCallback(() => {
    const data = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      legalID: legalID.trim(),
      phone,
      address: {
        street: street.trim(),
        number: number.trim(),
        postalCode: postalCode.trim(),
        city: city.trim(),
        country: country.trim(),
      },
    };
    dispatch(editProfile(data));
  }, [
    firstName,
    lastName,
    legalID,
    phone,
    street,
    number,
    postalCode,
    city,
    country,
    dispatch,
  ]);
  return (
    <div className='card large'>
      <div className='card-content enable-card-overflow-y'>
        <div className='row'>
          <div className='input-field col s12 m6'>
            <input
              type='text'
              name='firstNameAcc'
              id='firstNameAcc'
              className='validate'
              disabled={enableEdit}
              value={firstName}
              onChange={(e) => handleTextChange(e, 'firstName')}
            />
            <label htmlFor='firstNameAcc' className='active'>
              Nombre
            </label>
          </div>
          <div className='input-field col s12 m6'>
            <input
              type='text'
              name='lastNameAcc'
              id='lastNameAcc'
              className='validate'
              disabled={enableEdit}
              value={lastName}
              onChange={(e) => handleTextChange(e, 'lastName')}
            />
            <label htmlFor='lastNameAcc' className='active'>
              Apellidos
            </label>
          </div>
          <div className='input-field col s12 m6'>
            <input
              type='text'
              name='legalIDAcc'
              id='legalIDAcc'
              className='validate'
              disabled={enableEdit}
              value={legalID}
              onChange={(e) => handleTextChange(e, 'legalID')}
            />
            <label htmlFor='legalIDAcc' className='active'>
              DNI/NIE/CIF/Pasaporte
            </label>
          </div>
          <div className='input-field col s12 m6'>
            <input
              type='text'
              name='phoneAcc'
              id='phoneAcc'
              className='validate'
              disabled={enableEdit}
              value={phone}
              onChange={(e) => handleTextChange(e, 'phone')}
              maxLength={9}
            />
            <label htmlFor='phoneAcc' className='active'>
              Telefono
            </label>
          </div>
          <div className='input-field col s12 m6'>
            <input
              type='text'
              name='emailAcc'
              id='emailAcc'
              className='validate'
              disabled
              value={email}
            />
            <label htmlFor='emailAcc' className='active'>
              Email
            </label>
          </div>
          <div className='input-field col s12 m6'>
            <input
              type='text'
              name='addressAcc'
              id='addressAcc'
              className='validate'
              disabled={enableEdit}
              value={street}
              onChange={(e) => handleTextChange(e, 'street')}
            />
            <label htmlFor='addressAcc' className='active'>
              Direccion
            </label>
          </div>
          <div className='input-field col s12 m2'>
            <input
              type='text'
              name='numberAcc'
              id='numberAcc'
              className='validate'
              disabled={enableEdit}
              value={number}
              onChange={(e) => handleTextChange(e, 'number')}
            />
            <label htmlFor='numberAcc' className='active'>
              Numero
            </label>
          </div>
          <div className='input-field col s12 m2'>
            <input
              type='text'
              name='postalCodeAcc'
              id='postalCodeAcc'
              className='validate'
              disabled={enableEdit}
              value={postalCode}
              onChange={(e) => handleTextChange(e, 'postalCode')}
            />
            <label htmlFor='postalCodeAcc' className='active'>
              Codigo Postal
            </label>
          </div>
          <div className='input-field col s12 m4'>
            <input
              type='text'
              name='cityAcc'
              id='cityAcc'
              className='validate'
              disabled={enableEdit}
              value={city}
              onChange={(e) => handleTextChange(e, 'city')}
            />
            <label htmlFor='cityAcc' className='active'>
              Ciudad
            </label>
          </div>
          <div className='input-field col s12 m4'>
            <input
              type='text'
              name='countryAcc'
              id='countryAcc'
              className='validate'
              disabled={enableEdit}
              value={country}
              onChange={(e) => handleTextChange(e, 'country')}
            />
            <label htmlFor='countryAcc' className='active'>
              Pais
            </label>
          </div>
        </div>
      </div>
      <div className='card-action'>
        <button
          className='btn-flat blue white-text waves-effect left'
          onClick={handleEnableEdit}
        >
          Modificar
        </button>
        <button
          className='btn-flat blue white-text waves-effect right'
          onClick={saveData}
        >
          Guardar
        </button>
      </div>
    </div>
  );
};

export default TabAccount;
