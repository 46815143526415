import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { passResetA } from '../../../redux/actions/authActions';
import generator from 'generate-password';
import M from 'materialize-css';

const SecurityCard = (props) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [password1, setPassword1] = useState('');
  const [showPass, setShowPass] = useState(false);

  const saveData = useCallback(() => {
    const data = {
      password,
      password1,
    };
    dispatch(passResetA(data));
  }, [password, password1, dispatch]);

  const handleTextChange = useCallback((e, field) => {
    switch (field) {
      case 'pass':
        setPassword(e.target.value);
        break;
      case 'pass1':
        setPassword1(e.target.value);
        break;
      default:
        break;
    }
  }, []);

  const handleShowPass = useCallback(() => {
    setShowPass(!showPass);
  }, [showPass]);

  const createPass = useCallback(() => {
    let pass = generator.generate({ length: 16, strict: true });
    setPassword(pass);
    setPassword1(pass);
    setTimeout(() => {
      M.updateTextFields();
    }, 300);
  }, []);
  return (
    <div className='acc-r-wrapper'>
      <div className='row'>
        <div className='input-field col s12 m4'>
          <input
            type={showPass ? 'text' : 'password'}
            name='acc_password'
            id='acc_password'
            className='validate'
            value={password}
            onChange={(e) => handleTextChange(e, 'pass')}
          />
          <label htmlFor='acc_password' className='active'>
            Nueva Contraseña
          </label>
        </div>
        <div className='input-field col s12 m4'>
          <input
            type={showPass ? 'text' : 'password'}
            name='acc_password1'
            id='acc_password1'
            className='validate'
            value={password1}
            onChange={(e) => handleTextChange(e, 'pass1')}
          />
          <label htmlFor='acc_password1' className='active'>
            Confirmar Contraseña
          </label>
        </div>
        <div className='input-field col 12 m4'>
          <button
            className='btn-flat white waves-effect'
            onClick={handleShowPass}
          >
            <i className='material-icons'>
              {showPass ? 'visibility' : 'visibility_off'}
            </i>
          </button>
          <button
            className='btn-flat blue waves-effect white-text'
            onClick={createPass}
          >
            generar Contraseña
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <button
            className='btn-flat blue waves-effect white-text'
            onClick={saveData}
          >
            guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default SecurityCard;
