import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Consent = (props) => {
  const [showConsent, setShowConsent] = useState(true);

  const onAccept = () => {
    setShowConsent(!showConsent);
    const cookieContent = 'true';
    localStorage.setItem('AJ_SES_SID', cookieContent);
  };
  const onDeny = () => {
    setShowConsent(!showConsent);
    const cookieContent = 'false';
    localStorage.setItem('AJ_SES_SID', cookieContent);
  };
  return (
    <div className='consent'>
      {showConsent && (
        <div className='row'>
          <div className='col l8 s12'>
            <div className='consent-msg'>
              <span>
                Las cookies de este sitio se usan para personalizar el contenido
                y los anuncios para ofrecer funciones de medios sociales y para
                analizar el tráfico. Además, compartimos información sobre el
                uso que haga del sitio web con nuestros partners de medios
                sociales de publicidad y de análisis web.
                <Link to='/cookies'> Mas informacion</Link>
              </span>
            </div>
          </div>
          <div className='col l4 s12'>
            <div className='consent-btn'>
              <button className='btn waves-effect red' onClick={onDeny}>
                No, Accepto!
              </button>
              <button className='btn waves-effect green' onClick={onAccept}>
                Accepto
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Consent;
