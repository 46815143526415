import {
  SEND_NEW_CONTACT_MESSAGE,
  NEW_CONTACT_MESSAGE_SENDING,
  GET_ALL_CONTACTS,
  GET_ERRORS,
  SET_SUCCESS_MSG,
} from './types';
import axios from 'axios';

export const sendNewContactMessage = (msg) => (dispatch) => {
  dispatch(setNewContactMessageSending());

  axios
    .post('/contact/message/new', msg)
    .then((res) =>
      dispatch({ type: SEND_NEW_CONTACT_MESSAGE, payload: res.data })
    )
    .catch((err) =>
      dispatch({ type: SEND_NEW_CONTACT_MESSAGE, payload: err.response.data })
    );
};

export const getAllContacts = () => (dispatch) => {
  axios
    .get('/admin/4l1n4/contacts/all')
    .then((res) => dispatch({ type: GET_ALL_CONTACTS, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
export const deleteContact = (data) => (dispatch) => {
  axios
    .post('/admin/4l1n4/contacts/contact/delete', data)
    .then((res) => {
      dispatch({ type: SET_SUCCESS_MSG, payload: res.data });
      dispatch({ type: GET_ALL_CONTACTS, payload: res.data.contacts });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const setNewContactMessageSending = () => {
  return {
    type: NEW_CONTACT_MESSAGE_SENDING,
  };
};
