import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TablePosts from '../TablePosts';
import { getAllPostsUnfiltered } from '../../../../redux/actions/blogActions';

const AllPostsTab = ({ selectedPost, deletePost, toggleHidePost }) => {
  const dispatch = useDispatch();
  const blog = useSelector((state) => state.blog);

  useEffect(() => {
    dispatch(getAllPostsUnfiltered());
  }, [dispatch]);

  return (
    <div className='tab-admin-all'>
      <div className='all-posts-wrapper'>
        <table className='responsive-table centered highlight'>
          <thead>
            <tr>
              <th>Color</th>
              <th>Featured</th>
              <th>Fecha</th>
              <th>Hora</th>
              <th>Categoria</th>
              <th>Titulo</th>
              <th>Descripcion</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            {blog.posts.map((post) => {
              return (
                <TablePosts
                  post={post}
                  key={post._id}
                  deletePost={deletePost}
                  selectPost={selectedPost}
                  toggleHidePost={toggleHidePost}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllPostsTab;
