import React, { useState, useEffect } from 'react';
import isEmpty from 'is-empty';
import { useDispatch } from 'react-redux';
import { verifyEmail } from '../../redux/actions/authActions';

const EmailVerificationModal = ({ modalID }) => {
  const dispatch = useDispatch();
  const [code1, setCode1] = useState('');
  const [code2, setCode2] = useState('');
  const [code3, setCode3] = useState('');
  const [code4, setCode4] = useState('');
  const [code5, setCode5] = useState('');
  const [code6, setCode6] = useState('');
  const [code, setCode] = useState([]);

  useEffect(() => {
    if (!isEmpty(code6)) {
      dispatch(verifyEmail({ code }));
    }
  }, [dispatch, code, code6]);

  const handleOnChange = (e, n) => {
    let codes = [];
    switch (n) {
      case '1':
        !isEmpty(e.target.value) && setCode1(e.target.value);
        codes = [e.target.value, code2, code3, code4, code5, code6];
        !isEmpty(e.target.value) && setCode(codes);
        break;
      case '2':
        !isEmpty(e.target.value) && setCode2(e.target.value);
        codes = [code1, e.target.value, code3, code4, code5, code6];
        !isEmpty(e.target.value) && setCode(codes);
        break;
      case '3':
        !isEmpty(e.target.value) && setCode3(e.target.value);
        codes = [code1, code2, e.target.value, code4, code5, code6];
        !isEmpty(e.target.value) && setCode(codes);
        break;
      case '4':
        !isEmpty(e.target.value) && setCode4(e.target.value);
        codes = [code1, code2, code3, e.target.value, code5, code6];
        !isEmpty(e.target.value) && setCode(codes);
        break;
      case '5':
        !isEmpty(e.target.value) && setCode5(e.target.value);
        codes = [code1, code2, code3, code4, e.target.value, code6];
        !isEmpty(e.target.value) && setCode(codes);
        break;
      case '6':
        !isEmpty(e.target.value) && setCode6(e.target.value);
        codes = [code1, code2, code3, code4, code5, e.target.value];
        !isEmpty(e.target.value) && setCode(codes);
        break;
      default:
        break;
    }
  };
  return (
    <div id={modalID} className='modal'>
      <div className='modal-title'>
        <h5>Introduzca el codigo</h5>
        <span>Introduzca el codigo que ha recibido por correo</span>
      </div>
      <div className='code-input-container'>
        <input
          type='text'
          maxLength={1}
          placeholder='X'
          onChange={(e) => handleOnChange(e, '1')}
        />
        <input
          type='text'
          maxLength={1}
          placeholder='X'
          onChange={(e) => handleOnChange(e, '2')}
        />
        <input
          type='text'
          maxLength={1}
          placeholder='X'
          onChange={(e) => handleOnChange(e, '3')}
        />
        <input
          type='text'
          maxLength={1}
          placeholder='X'
          onChange={(e) => handleOnChange(e, '4')}
        />
        <input
          type='text'
          maxLength={1}
          placeholder='X'
          onChange={(e) => handleOnChange(e, '5')}
        />
        <input
          type='text'
          maxLength={1}
          placeholder='X'
          onChange={(e) => handleOnChange(e, '6')}
        />
      </div>
    </div>
  );
};

export default EmailVerificationModal;
