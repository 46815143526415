import {
  PAY_INTENT,
  PAY_PROCESSING,
  GET_ALL_PAYMENTS,
  LOADING_PAYMENTS,
} from '../actions/types';

const initialState = {
  payment: {},
  payments: [],
  processing: false,
  loading: false,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAY_INTENT:
      return {
        ...state,
        payment: action.payload,
        processing: false,
      };
    case PAY_PROCESSING:
      return {
        ...state,
        processing: true,
      };
    case GET_ALL_PAYMENTS:
      return {
        ...state,
        payments: action.payload,
        processing: false,
      };
    case LOADING_PAYMENTS:
      return {
        ...state,
        loading: true,
        processing: false,
      };
    default:
      return state;
  }
};
export default paymentReducer;
