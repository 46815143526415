import React from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

const StepsContainer = ({ activeStep, handleActiveStep }) => {
  return activeStep === 1 ? (
    <Step1 handleActiveStep={handleActiveStep} />
  ) : activeStep === 2 ? (
    <Step2 handleActiveStep={handleActiveStep} />
  ) : activeStep === 3 ? (
    <Step3 handleActiveStep={handleActiveStep} />
  ) : activeStep === 4 ? (
    <Step4 handleActiveStep={handleActiveStep} />
  ) : null;
};

export default StepsContainer;
