import React, { useCallback, useState } from 'react';

const UploadPic = ({ savePostPicture }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [hasFile, setHasFile] = useState(false);
  const [draggedFile, setDraggedFile] = useState({});

  const handleOnDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);
  const handleOnDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);
  const handleOnDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);
  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length !== 0) {
      setHasFile(true);
      setDraggedFile(e.dataTransfer.files[0]);
    }
  }, []);

  const removeDraggedFile = useCallback(() => {
    setDraggedFile({});
    setHasFile(false);
  }, []);

  return (
    <div
      className={isDragging ? 'drag-n-drop-active' : 'drag-n-drop-container'}
      onDragEnter={handleOnDragEnter}
      onDragOver={handleOnDrag}
      onDragLeave={handleOnDragLeave}
      onDrop={handleDrop}
    >
      {hasFile ? (
        <div className='drag-n-drop-action'>
          <button className='btn-flat' onClick={removeDraggedFile}>
            <i className='material-icons red-text'>delete_forever</i>
          </button>
          <p className='black-text'>{draggedFile.name}</p>
        </div>
      ) : (
        <>
          <p>ARRASTRA AQUI LA IMAGEN DEL LA PUBLICACION.</p>
          <p>FORMATOS ACCEPTADOS</p>
          <p>JPG | JPEG | PNG</p>
        </>
      )}
      {hasFile && (
        <button
          className='btn-flat blue white-text waves-effect'
          onClick={(e) => savePostPicture(e, draggedFile)}
        >
          guardar
        </button>
      )}
    </div>
  );
};

export default UploadPic;
