import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'is-empty';
import {
  _updateAppointment,
  _getCalendar,
} from '../../../redux/actions/appointmentActions';
import { getStats } from '../../../redux/actions/miscActions';

const ModalCalendar = ({ modalID }) => {
  const dispatch = useDispatch();
  const success = useSelector((state) => state.success);
  const appointment = useSelector((state) => state.appointment);
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [isVideo, setIsVideo] = useState(false);
  const [isOffice, setIsOffice] = useState(false);
  const [appDate, setAppDate] = useState('');
  const [appHour, setAppHour] = useState('');
  const [clientName, setClientName] = useState('');
  const [categories, setCategories] = useState([
    { id: '0', value: 'CIVIL', checked: false },
    { id: '1', value: 'PENAL', checked: false },
    { id: '2', value: 'LABORAL', checked: false },
    { id: '3', value: 'FAMILIA', checked: false },
    { id: '4', value: 'EXTRANJERIA', checked: false },
    { id: '5', value: 'OTROS', checked: false },
  ]);
  const [appointmentInfo, setAppointmentInfo] = useState({});
  const [showDateDropDown, setShowDateDropDown] = useState(false);
  const [showHourDropDown, setShowHourDropDown] = useState(false);
  const [availableHours, setAvailableHours] = useState([]);

  useEffect(() => {
    if (!isEmpty(appointment.appointment)) {
      setAppointmentInfo(appointment.appointment);
      setAppDate(appointment.appointment.appointment.date);
      setAppHour(appointment.appointment.appointment.start);
      setClientName(appointment.appointment.name);
      setTitle(appointment.appointment.appointment.title);
      setDescription(appointment.appointment.appointment.description);
      appointment.appointment.appointment.type === 'video'
        ? setIsVideo(true)
        : setIsOffice(true);
    }
  }, [appointment.appointment]);

  useEffect(() => {
    if (!isEmpty(appointment.appointment)) {
      let updatedCats = [
        ...categories.filter(
          (cat) => cat.value !== appointment.appointment.appointment.category
        ),
        {
          ...categories.filter(
            (cat) => cat.value === appointment.appointment.appointment.category
          )[0],
          checked: true,
        },
      ].sort((a, b) => parseInt(a.id) > parseInt(b.id));
      setCategories(updatedCats);
    }
  }, [appointment.appointment]);

  useEffect(() => {
    if (!isEmpty(appointment.calendar && appDate)) {
      const selDate = appointment.calendar.days.filter(
        (day) => day.date === appDate
      )[0];
      const availHours = selDate.appointments
        .filter((h) => !h.busy)
        .map((ah) => ah.hour);
      setAvailableHours(availHours);
    }
  }, [appDate, appointment]);

  useEffect(() => {
    if (success.success) {
      dispatch(_getCalendar());
      dispatch(getStats());
    }
  }, [dispatch, success.success]);

  const handleTextArea = useCallback((e) => {
    setDescription(e.target.value);
  }, []);
  const handleTitle = useCallback((e) => {
    setTitle(e.target.value);
  }, []);
  const createNewAppointment = useCallback(() => {
    const data = {
      clientID: appointmentInfo.appointment.user,
      oldDate: appointmentInfo.appointment.date,
      oldHour: appointmentInfo.appointment.start,
      newDate: appDate,
      newHour: appHour,
      category: categories.filter((cat) => cat.checked)[0].value,
      type: isOffice ? 'office' : 'video',
      title,
      description,
    };
    dispatch(_updateAppointment(data));
  }, [
    dispatch,
    appDate,
    appHour,
    appointmentInfo,
    description,
    title,
    categories,
    isOffice,
  ]);

  const handleCheckCat = useCallback(
    (cat) => {
      let foundCat = categories.filter((c) => c.value === cat.value);
      let restCats = categories.filter((c) => c.value !== cat.value);
      let updatedRestCats = [];
      restCats.map(
        (ucat) =>
          (updatedRestCats = [...updatedRestCats, { ...ucat, checked: false }])
      );
      let updatedFoundCat = { ...foundCat[0], checked: !foundCat[0].checked };
      let updatedCats = [...updatedRestCats, updatedFoundCat].sort(
        (a, b) => parseInt(a.id) > parseInt(b.id)
      );
      setCategories(updatedCats);
    },
    [categories]
  );
  const handleTypeSelection = useCallback((t) => {
    switch (t) {
      case 'video':
        setIsVideo(true);
        setIsOffice(false);
        break;
      case 'office':
        setIsVideo(false);
        setIsOffice(true);
        break;
      default:
        break;
    }
  }, []);

  const handleShowDateDropDown = useCallback(() => {
    setShowDateDropDown(true);
  }, []);
  const handleShowHourDropDown = useCallback(() => {
    setShowHourDropDown(true);
  }, []);
  const handleDateSelect = useCallback((date) => {
    setShowDateDropDown(false);
    setAppDate(date);
  }, []);
  const handleHourSelect = useCallback((hour) => {
    setShowHourDropDown(false);
    setAppHour(hour);
  }, []);
  return (
    <div className='modal modal-fixed-footer' id={modalID}>
      <div className='modal-content'>
        <div className='row'>
          <div className='col s12 center'>
            <h5>Modificar consulta {clientName}</h5>
            <div className='subtitle-nueva-consulta'>
              <div className='subtitle-details'>
                <span>Fecha</span>
                <div>
                  <button
                    className='btn-flat blue-text'
                    onClick={handleShowDateDropDown}
                  >
                    {appDate}
                  </button>
                  {showDateDropDown && (
                    <div className='dropdown-date'>
                      {appointment.calendar.days.map((day) => (
                        <button
                          key={day._id}
                          className='btn-flat'
                          onClick={() => handleDateSelect(day.date)}
                        >
                          {day.date}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
                <span>Hora</span>
                <div>
                  <button
                    className='btn-flat blue-text'
                    onClick={handleShowHourDropDown}
                  >
                    {appHour}
                  </button>
                  {showHourDropDown && (
                    <div className='dropdown-date'>
                      {availableHours.map((hour) => (
                        <button
                          key={hour}
                          className='btn-flat'
                          onClick={() => handleHourSelect(hour)}
                        >
                          {hour}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='input-field col s12'>
            <input
              type='text'
              name='n_title'
              id='n_title'
              className='validate'
              value={title}
              onChange={handleTitle}
            />
            <label htmlFor='n_title' className='active'>
              Asunto
            </label>
          </div>
          <div className='input-field col s12'>
            <textarea
              name='description'
              id='desc'
              className='materialize-textarea'
              value={description}
              onChange={handleTextArea}
            />
            <label htmlFor='desc' className='active'>
              Descripcion
            </label>
          </div>
        </div>
        <div className='row'>
          <div className='col s12 m6'>
            <h6>Categoria</h6>
            {categories.map((cat) => (
              <p key={cat.id}>
                <label>
                  <input
                    type='checkbox'
                    checked={cat.checked}
                    onChange={() => handleCheckCat(cat)}
                  />
                  <span>{cat.value}</span>
                </label>
              </p>
            ))}
          </div>
          <div className='col s12 m6'>
            <h6>Tipo de Consulta</h6>
            <p>
              <label>
                <input
                  type='checkbox'
                  checked={isVideo}
                  onChange={() => handleTypeSelection('video')}
                />
                <span>Video</span>
              </label>
            </p>
            <p>
              <label>
                <input
                  type='checkbox'
                  checked={isOffice}
                  onChange={() => handleTypeSelection('office')}
                />
                <span>Presencial</span>
              </label>
            </p>
          </div>
        </div>
      </div>
      <div className='modal-footer'>
        <button
          className='btn-flat blue white-text left'
          onClick={createNewAppointment}
        >
          guardar
        </button>
        <button className='btn-flat blue white-text right modal-close'>
          cerrar
        </button>
      </div>
    </div>
  );
};

export default ModalCalendar;
