import React, { useEffect, useState, useCallback } from 'react';
import { Calendar as RCalendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCalendarForDay,
  createNewAppointment,
} from '../../../redux/actions/appointmentActions';
import moment from 'moment';
import isEmpty from 'is-empty';

const Calendar = ({ handleActiveStep }) => {
  const dispatch = useDispatch();
  const appointment = useSelector((state) => state.appointment);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedHour, setSelectedHour] = useState('');
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    if (!isEmpty(appointment.calendar)) {
      //if today check the hour
      let today = moment().format('DD-MM-YYYY');
      let currentHour = moment().format('HH:mm');
      let selDate = moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
      if (today === selDate) {
        let validHours = appointment.calendar.appointments.filter((app) =>
          moment(app.hour, 'HH:mm').isAfter(moment(currentHour, 'HH:mm'))
        );
        setAppointments(validHours);
      }
      //check if sunday
      else if (moment(selectedDate, 'DD-MM-YYYY').format('dddd') === 'Sunday') {
        setAppointments([]);
      } else {
        setAppointments(appointment.calendar.appointments);
      }
    }
  }, [appointment, selectedDate]);

  const handleDateSelection = useCallback(
    (date) => {
      setSelectedDate(date);
      dispatch(getCalendarForDay(moment(date).format('DD-MM-YYYY')));
    },
    [dispatch]
  );

  const handleSelectedHour = useCallback(
    (a) => {
      setSelectedHour(a.hour);
      const data = {
        date: moment(selectedDate).format('DD-MM-YYYY'),
        start: a.hour,
        end: moment(a.hour, 'HH:mm').add(1, 'hour').format('HH:mm'),
      };
      dispatch(createNewAppointment(data));
      handleActiveStep('3');
    },
    [dispatch, handleActiveStep, selectedDate]
  );
  return (
    <div className='calendar-container'>
      <div className='cal-header-container'>
        <span>Elige fecha y hora</span>
      </div>
      <div className='calendar'>
        <div className='calendar-date'>
          <RCalendar
            locale='es'
            onClickDay={handleDateSelection}
            value={selectedDate}
            minDate={new Date()}
          />
        </div>
        <div className='calendar-hour-container'>
          <div className='calendar-hour-title'>
            <span>Hora</span>
          </div>
          {appointments.length !== 0 &&
            appointments.map((a) =>
              a.busy ? (
                <button key={a._id} className='calendar-hour-busy'>
                  <span>{a.hour}</span>
                </button>
              ) : (
                <button
                  key={a._id}
                  className={
                    selectedHour === a.hour
                      ? 'calendar-hour-selected'
                      : 'calendar-hour-free'
                  }
                  onClick={() => handleSelectedHour(a)}
                >
                  <span>{a.hour}</span>
                </button>
              )
            )}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
