import React from 'react';
import { Link } from 'react-router-dom';

const Penal = () => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col s12 center'>
          <h6>
            <strong>DERECHO PENAL</strong>
          </h6>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <p>
            Actuamos en defensa de quienes hayan podido resultar perjudicados
            por cualquier actuación considerada delictiva por la Ley.
          </p>
          <p>
            Así redactamos el escrito de querella o de denuncia siempre
            iniciadores de la fase instructora cuando debemos defender a un
            cliente frente a la perpetración de un delito en su contra; nos
            personamos en el procedimiento y le defendemos y asistimos durante
            la fase instructora así como durante la vista oral.
          </p>
          <p>
            El derecho a la asistencia legal tanto al perjudicado como al
            presunto culpable implica el desarrollo de una actividad probatoria
            necesaria para lograr la defensa de los derechos de nuestros
            clientes.
          </p>
          <p>
            Actuamos en procedimientos abreviados, procedimientos ordinarios y
            de cualquier tipo que contempla nuestra Ley.
          </p>
          <p>
            Sin perjuicio de las actuaciones en materias generales dentro del
            Derecho Penal nos referimos a los delitos tradicionales, queremos
            hacer especial mención a nuestra actividad en una rama del Derecho
            Penal que está adquiriendo cada día más envergadura como es el{' '}
            <span>
              <i>
                <strong>
                  Derecho Informatico, delito informático, delitos en la red.
                </strong>
              </i>
            </span>
          </p>
          <p>
            <strong>
              Somos los especialistas en separaciones que estás buscando, para
              asesorarte de forma personalizada sobre los distintos modelos de
              divorcio y las opciones que tienes. Contamos con profesionales
              expertos en el derecho matrimonial. Así pues, en caso de que
              quieras poner fin a tu matrimonio, no dudes en acudir para que te
              ayudemos a negociar un acuerdo beneficioso.
            </strong>
          </p>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 center'>
          <h5>
            Para cualquier duda o consulta sobre <i>Derecho Penal</i> no dude en
            ponerse en contacto con nosotros
          </h5>
          <Link
            to='/contacto'
            className='btn-flat red white-text darken-1 waves-effect'>
            NECESITO MAS INFORMACION
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Penal;
