import { GET_PROFILE, EDIT_PROFILE, PROFILE_LOADING } from '../actions/types';

const initialState = {
  profile: {},
  profile_loading: false,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        profile: action.payload,
        profile_loading: false,
      };
    case EDIT_PROFILE:
      return {
        ...state,
        profile: action.payload,
        profile_loading: false,
      };
    case PROFILE_LOADING:
      return {
        ...state,
        profile_loading: true,
      };
    default:
      return state;
  }
};
export default profileReducer;
