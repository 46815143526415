//errors
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

//user
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLOSE_ACCOUNT = 'CLOSE_ACCOUNT';

//contact form
export const SEND_NEW_CONTACT_MESSAGE = 'SEND_NEW_CONTACT_MESSAGE';
export const NEW_CONTACT_MESSAGE_SENDING = 'NEW_CONTACT_MESSAGE_SENDING';

//admin
export const ADD_CLIENT = 'ADD_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';
export const GET_CLIENT = 'GET_CLIENT';
export const GET_ALL_CONTACTS = 'GET_ALL_CONTACTS';
export const CLIENT_LOADING = 'CLIENT_LOADING';
export const GET_CLIENT_FILES = 'GET_CLIENT_FILES';
export const GET_CLIENT_FILE = 'GET_CLIENT_FILE';
export const CONVERT_2_CLIENT = 'CONVERT_2_CLIENT';
export const SUSPEND_USER = 'SUSPEND_USER';
export const GET_STATS = 'GET_STATS';

//upload
export const ADD_FILE_TO_CLIENT = 'ADD_FILE_TO_CLIENT';
export const FILE_UPLOADING = 'FILE_UPLOADING';
export const UPDATE_UPLOAD_PROGRESS = 'UPDATE_UPLOAD_PROGRESS';
export const RESET_UPLOAD_PROGRESS = 'RESET_UPLOAD_PROGRESS';

//case
export const CREATE_NEW_CASE = 'CREATE_NEW_CASE';
export const GET_ALL_CASES_BY_CLIENT = 'GET_ALL_CASES_BY_CLIENT';
export const GET_ALL_CASES = 'GET_ALL_CASES';

//blog admin
export const ADD_POST = 'ADD_POST';
export const DELETE_POST = 'DELETE_POST';
export const EDIT_POST = 'EDIT_POST';
export const ADD_POST_COVER = 'ADD_POST_COVER';
export const DELETE_POST_COVER = 'DELETE_POST_COVER';
export const GET_ALL_POSTS = 'GET_ALL_POSTS';
export const GET_ALL_POSTS_BY_CATEGORY = 'GET_ALL_POSTS_BY_CATEGORY';
export const GET_ALL_POSTS_BY_DATE_LIMITED = 'GET_ALL_POSTS_BY_DATE_LIMITED';
export const GET_POST = 'GET_POST';
export const GET_FEATURED_POSTS = 'GET_FEATURED_POSTS';
export const POST_LOADING = 'POST_LOADING';
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const PUBLISH_POST = 'PUBLISH_POST';
export const CLEAR_POST = 'CLEAR_POST';
export const TOGGLE_HIDE_SHOW_POST = 'TOGGLE_HIDE_SHOW_POST';

//profile
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const GET_PROFILE = 'GET_PROFILE';

//login/register
export const SET_REQ_VERIFICATION = 'SET_REQ_VERIFICATION';
export const SET_USER_EMAIL_VERIFIED = 'SET_USER_EMAIL_VERIFIED';

//security
export const PASS_RESET = 'PASS_RESET';

//CLIENT
export const ADD_CLIENT_APPOINTMENT = 'ADD_CLIENT_APPOINTMENT';
export const GET_CLIENT_APPOINTMENTS = 'GET_CLIENT_APPOINTMENTS';
export const GET_ALL_CLIENTS_APPOINTMENTS = 'GET_ALL_CLIENTS_APPOINTMENTS';
export const DELETE_CLIENT_APPOINTMENT = 'DELETE_CLIENT_APPOINTMENT';
export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const SET_LOADING_APPOINTMENT = 'SET_LOADING_APPOINTMENT';
export const GET_APPOINTMENT = 'GET_APPOINTMENT';

//payment
export const PAY_INTENT = 'PAY_INTENT';
export const PAY_PROCESSING = 'PAY_PROCESSING';
export const GET_ALL_PAYMENTS = 'GET_ALL_PAYMENTS';
export const LOADING_PAYMENTS = 'LOADING_PAYMENTS';

//success
export const SET_SUCCESS_MSG = 'SET_SUCCESS_MSG';
export const CLEAR_SUCCESS_MSG = 'CLEAR_SUCCESS_MSG';

//appointements & calendar
export const GET_CALENDAR = 'GET_CALENDAR';
export const LOADING_CALENDAR = 'LOADING_CALENDAR';

//create new appointment
export const CREATE_NEW_APPOINTMENT = 'CREATE_NEW_APPOINTMENT';
