import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'is-empty';
import M from 'materialize-css';
import { getClients } from '../../../redux/actions/clientActions';
import {
  createAppointment4Client,
  _getCalendar,
} from '../../../redux/actions/appointmentActions';
import { getStats } from '../../../redux/actions/miscActions';

const ModalConsulta = ({ modalID, selectedDate }) => {
  const dispatch = useDispatch();
  const success = useSelector((state) => state.success);
  const clients = useSelector((state) => state.client);
  const [allClients, setAllClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});
  const [selectedClientText, setSelectedClientText] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [isVideo, setIsVideo] = useState(false);
  const [isOffice, setIsOffice] = useState(false);
  const [categories, setCategories] = useState([
    { id: '0', value: 'CIVIL', checked: false },
    { id: '1', value: 'PENAL', checked: false },
    { id: '2', value: 'LABORAL', checked: false },
    { id: '3', value: 'FAMILIA', checked: false },
    { id: '4', value: 'EXTRANJERIA', checked: false },
    { id: '5', value: 'OTROS', checked: false },
  ]);

  useEffect(() => {
    if (success.success) {
      dispatch(_getCalendar());
      dispatch(getStats());
    }
  }, [dispatch, success]);

  useEffect(() => {
    dispatch(getClients());
  }, [dispatch]);

  useEffect(() => {
    if (clients.clients.length !== 0) {
      setAllClients(clients.clients);
    }
  }, [clients]);

  useEffect(() => {
    setTimeout(() => {
      M.updateTextFields();
    }, 300);
  }, []);

  const handleTextChangeSearch = useCallback(
    (e) => {
      setSelectedClientText(e.target.value);
      if (isEmpty(e.target.value) || e.target.value.length < 3) {
        setSelectedClient({});
        setShowResults(false);
      } else if (!isEmpty(e.target.value) || e.target.value.length > 3) {
        const foundClient = clients.clients.filter(
          (cli) =>
            cli.firstName
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            cli.lastName.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setShowResults(true);
        setAllClients(foundClient);
      }
    },
    [clients]
  );
  const handleSelectedClient = useCallback((client) => {
    setSelectedClient(client);
    setSelectedClientText(`${client.firstName} ${client.lastName}`);
    setShowResults(false);
  }, []);

  const handleTextArea = useCallback((e) => {
    setDescription(e.target.value);
  }, []);
  const handleTitle = useCallback((e) => {
    setTitle(e.target.value);
  }, []);
  const createNewAppointment = useCallback(() => {
    const data = {
      clientID: selectedClient._id,
      date: selectedDate.date,
      hour: selectedDate.hour,
      category: categories.filter((cat) => cat.checked)[0].value,
      type: isOffice ? 'office' : 'video',
      title,
      description,
    };
    // console.log(data);
    dispatch(createAppointment4Client(data));
  }, [
    dispatch,
    selectedClient,
    selectedDate,
    description,
    title,
    categories,
    isOffice,
  ]);

  const handleCheckCat = useCallback(
    (cat) => {
      let foundCat = categories.filter((c) => c.value === cat.value);
      let restCats = categories.filter((c) => c.value !== cat.value);
      let updatedRestCats = [];
      restCats.map(
        (ucat) =>
          (updatedRestCats = [...updatedRestCats, { ...ucat, checked: false }])
      );
      let updatedFoundCat = { ...foundCat[0], checked: !foundCat[0].checked };
      let updatedCats = [...updatedRestCats, updatedFoundCat].sort((a, b) =>
        a.id > b.id ? 0 : -1
      );
      setCategories(updatedCats);
    },
    [categories]
  );
  const handleTypeSelection = useCallback((t) => {
    switch (t) {
      case 'video':
        setIsVideo(true);
        setIsOffice(false);
        break;
      case 'office':
        setIsVideo(false);
        setIsOffice(true);
        break;
      default:
        break;
    }
  }, []);
  return (
    <div className='modal modal-fixed-footer' id={modalID}>
      <div className='modal-content'>
        <div className='row'>
          <div className='col s12 center'>
            <h5>Crear nueva consulta</h5>
            <p className='subtitle-nueva-consulta'>
              <span>Fecha</span> <span>{selectedDate.date} </span>
              <span>Hora</span> <span>{selectedDate.hour}</span>
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='input-field col s12'>
            <input
              type='text'
              name='searchClient'
              id='searchClient'
              className='validate'
              value={selectedClientText}
              onChange={handleTextChangeSearch}
            />
            <label htmlFor='searchClient' className='active'>
              Buscar cliente
            </label>
            {showResults && (
              <div className='results-container'>
                {allClients.map((cli) => (
                  <button
                    key={cli._id}
                    onClick={() => handleSelectedClient(cli)}
                  >{`${cli.firstName} ${cli.lastName}`}</button>
                ))}
              </div>
            )}
          </div>
          <div className='input-field col s12'>
            <input
              type='text'
              name='n_title'
              id='n_title'
              className='validate'
              onChange={handleTitle}
            />
            <label htmlFor='n_title' className='active'>
              Asunto
            </label>
          </div>
          <div className='input-field col s12'>
            <textarea
              name='description'
              id='desc'
              className='materialize-textarea'
              onChange={handleTextArea}
            />
            <label htmlFor='desc' className='active'>
              Descripcion
            </label>
          </div>
        </div>
        <div className='row'>
          <div className='col s12 m6'>
            <h6>Categoria</h6>
            {categories.map((cat) => (
              <p key={cat.id}>
                <label>
                  <input
                    type='checkbox'
                    checked={cat.checked}
                    onChange={() => handleCheckCat(cat)}
                  />
                  <span>{cat.value}</span>
                </label>
              </p>
            ))}
          </div>
          <div className='col s12 m6'>
            <h6>Tipo de Consulta</h6>
            <p>
              <label>
                <input
                  type='checkbox'
                  checked={isVideo}
                  onChange={() => handleTypeSelection('video')}
                />
                <span>Video</span>
              </label>
            </p>
            <p>
              <label>
                <input
                  type='checkbox'
                  checked={isOffice}
                  onChange={() => handleTypeSelection('office')}
                />
                <span>Presencial</span>
              </label>
            </p>
          </div>
        </div>
      </div>
      <div className='modal-footer'>
        <button
          className='btn-flat blue white-text left'
          onClick={createNewAppointment}
        >
          guardar
        </button>
        <button className='btn-flat blue white-text right modal-close'>
          cerrar
        </button>
      </div>
    </div>
  );
};

export default ModalConsulta;
