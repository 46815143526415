import React from 'react';

const Contact = ({ contactInfo, delContact }) => {
  return (
    <li>
      <div className='collapsible-header custom-collapsible'>
        <div>
          <i className='material-icons'>account_circle</i>
          <span className='truncate'>{`${contactInfo.firstName} ${contactInfo.lastName}`}</span>
        </div>
        <div>
          <span>contacto</span>
        </div>
      </div>
      <div className='collapsible-body enable-card-overflow-y'>
        <div className='tab-cli'>
          <div className='row'>
            <div className='input-field col s12 m3'>
              <input
                type='text'
                name='cli_firstName'
                id='cli_firstName'
                className='validate'
                value={contactInfo.firstName}
                readOnly
                disabled
              />
              <label htmlFor='cli_firstName' className='active'>
                Nombre
              </label>
            </div>
            <div className='input-field col s12 m3'>
              <input
                type='text'
                name='cli_lastName'
                id='cli_lastName'
                className='validate'
                value={contactInfo.lastName}
                readOnly
                disabled
              />
              <label htmlFor='cli_lastName' className='active'>
                Apellidos
              </label>
            </div>
            <div className='input-field col s12 m3'>
              <input
                type='email'
                name='cli_email'
                id='cli_email'
                className='validate'
                value={contactInfo.email}
                readOnly
                disabled
              />
              <label htmlFor='cli_email' className='active'>
                Email
              </label>
            </div>
            <div className='input-field col s12 m3'>
              <input
                type='text'
                name='cli_phone'
                id='cli_phone'
                className='validate'
                maxLength={9}
                value={contactInfo.phone}
                readOnly
                disabled
              />
              <label htmlFor='cli_phone' className='active'>
                Telefono
              </label>
            </div>
          </div>
          <div className='row'>
            <div className='col s12 center'>
              <h5 className='grey-text'>Consulta</h5>
            </div>
          </div>
          <div className='row'>
            <h6 style={{ fontWeight: 'bold' }}>{contactInfo.subject}</h6>
            <p>{contactInfo.message}</p>
          </div>
          <div className='row'>
            <div className='col s12 m4'>
              <p className='blue-text'>
                <strong>Fecha Consulta: </strong>
                <span className='grey-text text-lighten-1'>
                  {contactInfo.date}
                </span>
              </p>
            </div>
            <div className='col s12 m4'>
              <p className='blue-text'>
                <strong>IP: </strong>
                <span className='grey-text text-lighten-1'>NS</span>
              </p>
            </div>
            <div className='col s12 m4'>
              <button
                className='btn red white-text waves-effect'
                onClick={() => delContact(contactInfo)}
              >
                Eliminar contacto
              </button>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default Contact;
