import React from 'react';
import isEmpty from 'is-empty';
import { Link } from 'react-router-dom';

const ModalConsultaInfo = ({ appointment }) => {
  return (
    <div className='modal modal-fixed-footer' id='modalConsultaInfo'>
      {!isEmpty(appointment) && (
        <div className='modal-content'>
          <div className='modal-consulta-info-head'>
            <h5>Detalles Consulta</h5>
            <div>
              <p>
                Fecha consulta<span>{` ${appointment.date}`}</span>
              </p>
              <p>
                Hora consulta<span>{` ${appointment.start}`}</span>
              </p>
              <p>
                <span>
                  {appointment.payment.status.pending
                    ? 'En curso'
                    : appointment.payment.status.confirmed
                    ? 'Pagado'
                    : appointment.payment.status.cancelled
                    ? 'Cancelado'
                    : 'NS'}
                </span>
              </p>
            </div>
          </div>
          <div className='modal-consulta'>
            <div className='modal-consulta-info'>
              <p>{appointment.title}</p>
              <p>{appointment.description}</p>
              {appointment.type === 'video' && appointment.isActive ? (
                <Link to={`/videoconsulta/${appointment.sessionID}`}>
                  Empezar Video Consulta
                </Link>
              ) : (
                <Link to='/compra/videoconsulta'>Comprar nueva consulta</Link>
              )}
            </div>
          </div>
        </div>
      )}
      <div className='modal-footer'>
        <button className='btn-flat waves-effect modal-close right blue white-text'>
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default ModalConsultaInfo;
