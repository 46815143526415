import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadFile2Client } from '../../redux/actions/uploadActions';

const DragNDrop = ({ userCase, userInfo }) => {
  const dispatch = useDispatch();
  const [isDragging, setIsDragging] = useState(false);
  const [hasFile, setHasFile] = useState(false);
  const [draggedFile, setDraggedFile] = useState({});

  const handleOnDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);
  const handleOnDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);
  const handleOnDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);
  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length !== 0) {
      setHasFile(true);
      setDraggedFile(e.dataTransfer.files[0]);
    }
  }, []);

  const removeDraggedFile = useCallback(() => {
    setDraggedFile({});
    setHasFile(false);
  }, []);

  const uploadFile = useCallback(
    (e) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append('file', draggedFile);
      formData.append(
        'additionalData',
        JSON.stringify({
          clientID: userInfo.user._id,
          case: userCase._id,
        })
      );
      dispatch(uploadFile2Client(formData));
      setTimeout(() => {
        removeDraggedFile();
      }, 1000);
    },
    [draggedFile, dispatch, userCase, userInfo, removeDraggedFile]
  );
  return (
    <div
      className={isDragging ? 'drag-n-drop-active' : 'drag-n-drop-container'}
      onDragEnter={handleOnDragEnter}
      onDragOver={handleOnDrag}
      onDragLeave={handleOnDragLeave}
      onDrop={handleDrop}
    >
      {hasFile ? (
        <div className='drag-n-drop-action'>
          <button className='btn-flat' onClick={removeDraggedFile}>
            <i className='material-icons red-text'>delete_forever</i>
          </button>
          <p className='black-text'>{draggedFile.name}</p>
        </div>
      ) : (
        <>
          <p>DEJA AQUI EL DOCUMENTO QUE QUIERES SUBIR. SOLO UNO A LA VEZ!</p>
          <p>FORMATOS ACCEPTADOS</p>
          <p>PDF | JPG | JPEG | PNG</p>
        </>
      )}
      {hasFile && (
        <button
          className='btn-flat blue white-text waves-effect'
          onClick={uploadFile}
        >
          subir
        </button>
      )}
    </div>
  );
};

export default DragNDrop;
