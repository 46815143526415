import React from 'react';
import AdminCalDays from './AdminCalDays';

const AdminCalendar = ({
  calendar,
  loadMoreDays,
  handleSelectedDate,
  toggleDayoff,
}) => {
  return (
    <div className='admin-cal-days-container'>
      {calendar.map((d) => (
        <AdminCalDays
          key={d._id}
          day={d}
          handleSelectedDate={handleSelectedDate}
          toggleDayoff={toggleDayoff}
        />
      ))}
      <div className='center'>
        <button
          className='btn-flat blue white-text waves-effect'
          onClick={loadMoreDays}
        >
          cargar mas
        </button>
      </div>
    </div>
  );
};

export default AdminCalendar;
