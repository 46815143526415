import React, { useEffect } from 'react';
import Calendar from '../calendar/Calendar';
import { useDispatch } from 'react-redux';
import { getCalendarForDay } from '../../../redux/actions/appointmentActions';
import moment from 'moment';

const Step2 = ({ handleActiveStep }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCalendarForDay(moment().format('DD-MM-YYYY')));
  }, [dispatch]);
  return (
    <div className='card'>
      <div className='card-content'>
        <Calendar handleActiveStep={handleActiveStep} />
        <div className='step-footer'>
          <button
            className='btn-flat waves-effect blue white-text'
            onClick={(e) => handleActiveStep('1')}
          >
            Volver
          </button>
        </div>
      </div>
    </div>
  );
};

export default Step2;
