import React from 'react';
import { useSelector } from 'react-redux';

const TabConsultas = ({ handleConsultaSel }) => {
  const appointments = useSelector((state) => state.appointment);
  return (
    <div className='card large'>
      <div className='card-content enable-card-overflow-y'>
        <table className='centered highlighted fixed-width-table'>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Hora</th>
              <th>Tipo</th>
              <th>Asunto</th>
              <th>Estado</th>
              <th>Categoria</th>
              <th>Info</th>
            </tr>
          </thead>
          <tbody>
            {appointments.appointments.map((app) => {
              return (
                <tr key={app._id}>
                  <td>{app.date}</td>
                  <td>{app.start}</td>
                  <td>{app.type === 'video' ? 'VIDEO' : 'PRESENCIAL'}</td>
                  <td>
                    <span className='trunk'>{app.title}</span>
                  </td>
                  <td>{app.isActive ? 'PENDIENTE' : 'FINALIZADA'}</td>
                  <td>{app.category}</td>
                  <td>
                    <a
                      href='#modalConsultaInfo'
                      className='modal-trigger'
                      onClick={() => handleConsultaSel(app)}
                    >
                      <i className='material-icons'>info</i>
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TabConsultas;
