import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { _login } from '../../redux/actions/authActions';

const Login = (props) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const loginMeIn = (e) => {
    e.preventDefault();
    const data = {
      email,
      password,
    };
    dispatch(_login(data));
  };
  return (
    <div className='container'>
      <div className='row'>
        <div className='col s12 m4 offset-m4'>
          <div className='card'>
            <div className='card-content'>
              <h5>Login</h5>
              <div className='input-field'>
                <input
                  type='text'
                  className='validate'
                  id='emailA'
                  name='emailA'
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor='emailA'>Email/Username</label>
              </div>
              <div className='input-field'>
                <input
                  type='password'
                  className='validate'
                  id='passwordA'
                  name='passwordA'
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label htmlFor='passwordA'>Password</label>
              </div>
              <button
                className='btn-flat waves-effect red darken-1 white-text'
                onClick={loginMeIn}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
