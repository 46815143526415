import React, { useState, useEffect } from 'react';
import AdminCalHours from './AdminCalHours';
import moment from 'moment';

const AdminCalDays = ({ day, handleSelectedDate, toggleDayoff }) => {
  const [isToday, setIsToday] = useState(false);

  useEffect(() => {
    let now = moment().format('DD-MM-YYYY');
    if (now.toString().includes(day.date)) {
      setIsToday(true);
    } else {
      setIsToday(false);
    }
  }, [day]);
  return (
    <div className='admin-cal-day-container'>
      <div className={`${isToday ? 'admin-cal-today' : 'admin-cal-date'}`}>
        <span>{day.day}</span>
        <span>{day.date}</span>
      </div>
      <div className='admin-cal-day-reset-dayoff'>
        <button
          className={day.dayOff ? 'btn-flat red-text' : 'btn-flat green-text'}
          onClick={() => toggleDayoff(day)}
        >
          {day.dayOff ? 'Cerrado' : 'Abierto'}
        </button>
      </div>
      {day.appointments.map((a) => (
        <AdminCalHours
          key={a._id}
          appointment={a}
          day={day}
          handleSelectedDate={handleSelectedDate}
        />
      ))}
    </div>
  );
};

export default AdminCalDays;
