import React from 'react';
import { NavLink } from 'react-router-dom';

const Sidenav = ({ id }) => {
  return (
    <ul className='sidenav' id={id}>
      <li>
        <NavLink exact to='/'>
          Inicio
        </NavLink>
      </li>
      <li>
        <NavLink exact to='/despacho'>
          Despacho
        </NavLink>
      </li>
      <li>
        <NavLink exact to='/areas'>
          Areas
        </NavLink>
      </li>
      <li>
        <NavLink exact to='/blog/pag/1'>
          Blog
        </NavLink>
      </li>
      <li>
        <NavLink exact to='/consultas'>
          Consultas
        </NavLink>
      </li>
      <li>
        <NavLink exact to='/contacto'>
          Contacto
        </NavLink>
      </li>
    </ul>
  );
};

export default Sidenav;
