import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react';
import moment from 'moment';
import M from 'materialize-css';
import { Link } from 'react-router-dom';
import MiniCard from './MiniCard';
import MiniCardFree from './MiniCardFree';
import AdminCalendar from '../adminCalendar/AdminCalendar';
import { useDispatch, useSelector } from 'react-redux';
import {
  _getCalendar,
  _getCalendarNext,
  _getAppointment,
  _deleteAppointment,
  _finishAppointment,
  _markDayOff,
  _markHourBusy,
} from '../../../redux/actions/appointmentActions';
import isEmpty from 'is-empty';
import { getStats } from '../../../redux/actions/miscActions';
import ModalConsulta from './ModalConsulta';
import ModalCalendar from './ModalCalendar';
import {
  deleteClientFile,
  downloadClientFile,
} from '../../../redux/actions/clientActions';
import MainContext from '../../context/MainContext';

const VideoMeeting = () => {
  const mainContext = useContext(MainContext);
  const dispatch = useDispatch();
  const appointment = useSelector((state) => state.appointment);
  const misc = useSelector((state) => state.misc);
  const [currentDayAppointments, setCurrentDayAppointments] = useState();
  const [selectedDateSide, setselectedDateSide] = useState(
    moment().format('DD-MM-YYYY')
  );
  const [totalCases, setTotalCases] = useState('0');
  const [totalClients, setTotalClients] = useState('0');
  const [totalMeetings, setTotalMeetings] = useState('0');
  const [totalUsers, setTotalUsers] = useState('0');
  const [selectedHourNew, setSelectedHourNew] = useState({});
  const [
    selectedAppointmentDetails,
    setSelectedAppointmentDetails,
  ] = useState();
  const [clientCases, setClientCases] = useState([]);
  const [clientCaseDocs, setClientCaseDocs] = useState([]);

  const datePickerREF = useRef();

  const handleSelectedDate = useCallback((date) => {
    setselectedDateSide(moment(date).format('DD-MM-YYYY'));
  }, []);

  useEffect(() => {
    dispatch(_getCalendar());
    dispatch(getStats());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(misc.stats)) {
      setTotalClients(misc.stats.totalClients);
      setTotalUsers(misc.stats.totalUsers);
      setTotalMeetings(misc.stats.totalMeetings);
      setTotalCases(misc.stats.totalCases);
    }
  }, [misc]);

  useEffect(() => {
    if (!isEmpty(appointment.calendar) && appointment.calendar.days !== 0) {
      let currentDayApp = appointment.calendar.days.filter(
        (a) => a.date === selectedDateSide
      )[0];
      setCurrentDayAppointments(currentDayApp);
    }
  }, [appointment, selectedDateSide]);

  useEffect(() => {
    if (!isEmpty(appointment.appointment)) {
      setSelectedAppointmentDetails(appointment.appointment);
      if (
        appointment.appointment.cases &&
        appointment.appointment.cases.length !== 0
      ) {
        setClientCases(appointment.appointment.cases);
        let docs = appointment.appointment.cases.map((c) => c.documents);
        setClientCaseDocs(docs[0]);
      } else {
        setClientCases([]);
      }
    }
  }, [appointment.appointment]);

  const handleModalIsOpen = useCallback(() => {
    setTimeout(() => {
      M.updateTextFields();
    }, 300);
  }, []);

  useEffect(() => {
    const modal = document.querySelectorAll('.modal');
    M.Modal.init(modal, { onOpenStart: handleModalIsOpen });
    const datePicker = document.querySelectorAll('.datepicker');
    datePickerREF.current = M.Datepicker.init(datePicker, {
      onSelect: handleSelectedDate,
      autoClose: true,
      firstDay: 1,
      i18n: {
        cancel: 'cancelar',
        clear: 'limpiar',
        months: [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre',
        ],
        monthsShort: [
          'Ene',
          'Feb',
          'Mar',
          'Abr',
          'May',
          'Jun',
          'Jul',
          'Ago',
          'Sep',
          'Oct',
          'Nov',
          'Dic',
        ],
        weekdays: [
          'Lunes',
          'Martes',
          'Miercoles',
          'Jueves',
          'Viernes',
          'Sabado',
          'Domingo',
        ],
        weekdaysShort: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
        weekdaysAbbrev: ['L', 'M', 'X', 'J', 'V', 'S', 'D'],
      },
      format: 'dd-mm-yyyy',
      minDate: new Date(),
    });
  }, [handleSelectedDate, handleModalIsOpen]);

  const openDatePicker = useCallback((e) => {
    datePickerREF.current[0].open();
  }, []);
  const nextDate = useCallback(() => {
    let now = moment(datePickerREF.current[0].el.textContent, 'DD-MM-YYYY').add(
      1,
      'day'
    );
    setselectedDateSide(now.format('DD-MM-YYYY'));
  }, []);
  const previousDate = useCallback(() => {
    let now = moment(
      datePickerREF.current[0].el.textContent,
      'DD-MM-YYYY'
    ).subtract(1, 'day');
    setselectedDateSide(now.format('DD-MM-YYYY'));
  }, []);

  const handleAppointmentDetails = useCallback(
    (app) => {
      dispatch(_getAppointment(app._id));
    },
    [dispatch]
  );

  const loadMoreDays = useCallback(() => {
    dispatch(_getCalendarNext(appointment.calendar.totalDays));
  }, [dispatch, appointment]);

  const handleSelectedHour = useCallback((date, hour) => {
    setSelectedHourNew({ date, hour });
  }, []);

  const handleCalSelectedDate = useCallback(
    (app, day) => {
      if (app.appointment) {
        dispatch(_getAppointment(app.appointment._id));
      }
    },
    [dispatch]
  );

  const toggleDayoff = useCallback(
    (day) => {
      dispatch(_markDayOff({ dayID: day._id }));
    },
    [dispatch]
  );
  const toggleBusyHour = useCallback(
    (app) => {
      dispatch(
        _markHourBusy({ hourID: app._id, dayID: currentDayAppointments._id })
      );
    },
    [dispatch, currentDayAppointments]
  );

  const delAppointment = useCallback(
    (app) => {
      dispatch(_deleteAppointment(app._id));
      setSelectedAppointmentDetails({});
    },
    [dispatch]
  );

  const handleFinishAppointment = useCallback(
    (app) => {
      dispatch(_finishAppointment(app));
      dispatch(_getAppointment(app));
    },
    [dispatch]
  );

  const deleteCaseFile = useCallback(
    (caseID, docID) => {
      dispatch(deleteClientFile({ caseID, docID }));
    },
    [dispatch]
  );
  const downloadCaseFile = useCallback(
    (caseID, docID, docName) => {
      dispatch(downloadClientFile(caseID, docID, docName));
    },
    [dispatch]
  );
  return (
    <div className='v-consulta-wrapper'>
      <div className='row'>
        <div className='col s12 header-info'>
          <div className='card card-info'>
            <div className='card-info-logo'>
              <i className='material-icons medium white-text'>assignment</i>
            </div>
            <div className='card-info-text'>
              <span>{totalCases}</span>
              <span>Casos</span>
            </div>
          </div>
          <div className='card card-info'>
            <div className='card-info-logo'>
              <i className='material-icons medium white-text'>assignment_ind</i>
            </div>
            <div className='card-info-text'>
              <span>{totalClients}</span>
              <span>Clientes</span>
            </div>
          </div>
          <div className='card card-info'>
            <div className='card-info-logo'>
              <i className='material-icons medium white-text'>event_note</i>
            </div>
            <div className='card-info-text'>
              <span>{totalMeetings}</span>
              <span>Citas</span>
            </div>
          </div>
          <div className='card card-info'>
            <div className='card-info-logo'>
              <i className='material-icons medium white-text'>account_circle</i>
            </div>
            <div className='card-info-text'>
              <span>{totalUsers}</span>
              <span>Usuarios</span>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 m8'>
          <div className='card large main-card'>
            {!isEmpty(selectedAppointmentDetails) ? (
              <div className='card-content'>
                <div className='row'>
                  <div className='col s12 m8 video-call-user-info'>
                    <div>
                      <p>
                        <i className='material-icons'>account_circle</i>
                        <span>{selectedAppointmentDetails.name}</span>
                      </p>
                      <p>
                        <i className='material-icons'>phone</i>
                        <span>{selectedAppointmentDetails.phone}</span>
                      </p>
                      <p>
                        <i className='material-icons'>email</i>
                        <a href={`mailto:${selectedAppointmentDetails.email}`}>
                          {selectedAppointmentDetails.email}
                        </a>
                      </p>
                    </div>
                    <div>
                      <p>{selectedAppointmentDetails.appointment.title}</p>
                      <p>{selectedAppointmentDetails.appointment.category}</p>
                      <p>
                        {selectedAppointmentDetails.appointment.description}
                      </p>
                    </div>
                  </div>
                  <div className='col s12 m4 video-call-actions'>
                    <div>
                      <span>ID Consulta</span>
                      <span>
                        {selectedAppointmentDetails.appointment.sessionID}
                      </span>
                      <span>duracion 1 hora</span>
                      <span>{`${selectedAppointmentDetails.appointment.start} - ${selectedAppointmentDetails.appointment.end}`}</span>
                    </div>
                    <div>
                      {selectedAppointmentDetails.appointment.isActive ? (
                        <Link
                          to={`/videoconsulta/${selectedAppointmentDetails.appointment.sessionID}`}
                        >
                          Empezar Video Consulta
                        </Link>
                      ) : (
                        <span className='grey-text'>Consulta Finalizada</span>
                      )}
                      <a href='#modalCalendar' className='modal-trigger'>
                        Modificar Consulta
                      </a>
                      <button
                        className='btn-flat red-text'
                        onClick={() =>
                          delAppointment(selectedAppointmentDetails.appointment)
                        }
                      >
                        Eliminar Consulta
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='no-date-selected'>
                <img src={mainContext.accountImages[3]} alt='no-data' />
                <div>
                  <span>
                    Selecciona una fecha en el calendario o crea una consulta
                    nueva
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='row'>
            <div className='col s12'>
              <div className='card small'>
                <div className='card-content'>
                  <h6>Ficheros</h6>
                  {clientCases.length !== 0 ? (
                    <div className='files-container'>
                      <ul>
                        {clientCaseDocs.map((doc) => (
                          <li key={doc._id}>
                            <div className='file-wrapper'>
                              <div>
                                <i className='material-icons orange-text'>
                                  folder
                                </i>
                                <div>
                                  <p>{doc.fileName}</p>
                                  <p>{doc.uploadDate.substring(0, 10)}</p>
                                </div>
                              </div>
                              <div>
                                <button
                                  onClick={() =>
                                    deleteCaseFile(clientCases[0]._id, doc._id)
                                  }
                                >
                                  <i className='material-icons red-text'>
                                    delete_forever
                                  </i>
                                </button>
                                <button>
                                  <i className='material-icons'>share</i>
                                </button>
                                <button
                                  onClick={() =>
                                    downloadCaseFile(
                                      clientCases[0]._id,
                                      doc._id,
                                      doc.fileName
                                    )
                                  }
                                >
                                  <i className='material-icons blue-text'>
                                    cloud_download
                                  </i>
                                </button>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <div className='files-container-no-data'>
                      <img src={mainContext.accountImages[2]} alt='no-data' />
                      <span>NO HAY NINGUN FICHERO</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col s12 m4'>
          <div className='card side-card'>
            <div className='hour-scheduler-nav z-depth-1'>
              <button
                className='btn-flat waves-effect blue-text white'
                onClick={previousDate}
              >
                <i className='material-icons'>chevron_left</i>
              </button>
              <div className='hour-scheduler-title'>
                <button
                  onClick={openDatePicker}
                  className='datepicker btn-flat white blue-text waves-effect'
                >
                  {selectedDateSide}
                </button>
                <span>consultas</span>
              </div>
              <button
                className='btn-flat waves-effect blue-text white'
                onClick={nextDate}
              >
                <i className='material-icons'>chevron_right</i>
              </button>
            </div>
            <div className='card-overflow'>
              {!isEmpty(currentDayAppointments) &&
                currentDayAppointments.appointments.map((ap) => {
                  if (ap.busy) {
                    return (
                      <MiniCard
                        key={ap._id}
                        appointment={ap}
                        handleAppointmentDetails={handleAppointmentDetails}
                        finishAppointment={handleFinishAppointment}
                        toggleBusyHour={toggleBusyHour}
                      />
                    );
                  } else {
                    return (
                      <MiniCardFree
                        key={ap._id}
                        appointment={ap}
                        currentDayAppointment={currentDayAppointments}
                        selectedHour={handleSelectedHour}
                        toggleBusyHour={toggleBusyHour}
                      />
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </div>
      <div className='admin-cal-container'>
        <div className='admin-cal-header'>
          <span>fecha</span>
          <span>08:00</span>
          <span>09:00</span>
          <span>10:00</span>
          <span>11:00</span>
          <span>12:00</span>
          <span>13:00</span>
          <span>14:00</span>
          <span>15:00</span>
          <span>16:00</span>
          <span>17:00</span>
          <span>18:00</span>
        </div>
        {!isEmpty(appointment.calendar) && (
          <AdminCalendar
            calendar={appointment.calendar.days}
            loadMoreDays={loadMoreDays}
            handleSelectedDate={handleCalSelectedDate}
            toggleDayoff={toggleDayoff}
          />
        )}
      </div>
      <ModalConsulta modalID='modalConsulta' selectedDate={selectedHourNew} />
      <ModalCalendar
        modalID='modalCalendar'
        selectedAppointment={selectedAppointmentDetails}
      />
    </div>
  );
};

export default VideoMeeting;
