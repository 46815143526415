import React, { useEffect, useState, useCallback } from 'react';
import M from 'materialize-css';
import SearchContainer from './SearchContainer';
import passGenerator from 'generate-password';
import { addClient, getClients } from '../../../redux/actions/clientActions';
import {
  deleteContact,
  getAllContacts,
} from '../../../redux/actions/contactActions';
import { useDispatch, useSelector } from 'react-redux';

const Clients = (props) => {
  const dispatch = useDispatch();
  const client = useSelector((state) => state.client);
  const contact = useSelector((state) => state.contact);
  // const [showResults, setShowResults] = useState(false);
  // const [searchTerm, setSearchTerm] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [legalID, setLegalID] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [phone, setPhone] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [country] = useState('España');
  const [showPass, setShowPass] = useState(false);
  const [sendMail, setSendMail] = useState(true);
  const [clients, setClients] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [filters] = useState(['contacto', 'cliente', 'caso']);
  const [activeFilter, setActiveFilter] = useState('contacto');

  useEffect(() => {
    dispatch(getClients());
    dispatch(getAllContacts());
  }, [dispatch]);

  useEffect(() => {
    if (client.clients.length !== 0) {
      setClients(client.clients);
    }
    if (contact.contacts.length !== 0) {
      setContacts(contact.contacts);
    }
  }, [client, contact]);

  useEffect(() => {
    const fSelect = document.querySelectorAll('select');
    M.FormSelect.init(fSelect, {});
    const modal = document.querySelectorAll('.modal');
    M.Modal.init(modal, {});
    setTimeout(() => {
      M.updateTextFields();
    }, 300);
  }, []);

  // useEffect(() => {
  //   if (searchTerm.length > 3) {
  //     setShowResults(true);
  //   }
  //   if (searchTerm.length < 3) {
  //     setShowResults(false);
  //   }
  // }, [searchTerm]);

  const handleSearchTerm = useCallback(
    (e) => {
      // setSearchTerm(e.target.value);
      if (activeFilter === 'contacto') {
        if (e.target.value.length < 3) {
          setContacts(contact.contacts);
        } else {
          const contactsFilter = contact.contacts.filter(
            (cli) =>
              cli.firstName.toLowerCase() === e.target.value.toLowerCase() ||
              cli.lastName.toLowerCase() === e.target.value.toLowerCase()
          );
          setContacts(contactsFilter);
        }
      } else if (activeFilter === 'cliente') {
        if (e.target.value.length < 3) {
          setClients(client.clients);
        } else {
          const clientsFilter = client.clients.filter(
            (cli) =>
              cli.firstName.toLowerCase() === e.target.value.toLowerCase() ||
              cli.lastName.toLowerCase() === e.target.value.toLowerCase()
          );
          setClients(clientsFilter);
        }
      }
    },
    [client, contact, activeFilter]
  );

  const handleTextChange = useCallback((e, field) => {
    switch (field) {
      case 'firstName':
        setFirstName(e.target.value);
        break;
      case 'lastName':
        setLastName(e.target.value);
        break;
      case 'legalID':
        setLegalID(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'password':
        setPassword(e.target.value);
        break;
      case 'password2':
        setPassword2(e.target.value);
        break;
      case 'phone':
        setPhone(e.target.value);
        break;
      case 'street':
        setStreet(e.target.value);
        break;
      case 'number':
        setNumber(e.target.value);
        break;
      case 'postalCode':
        setPostalCode(e.target.value);
        break;
      case 'city':
        setCity(e.target.value);
        break;
      default:
        break;
    }
  }, []);
  const createNewClient = useCallback(() => {
    const data = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      legalID: legalID.trim(),
      email: email.trim(),
      password: password.trim(),
      password2: password2.trim(),
      phone: phone.trim(),
      street: street.trim(),
      number: number.trim(),
      postalCode: postalCode.trim(),
      city: city.trim(),
      country: country.trim(),
      sendMail,
    };
    dispatch(addClient(data));
  }, [
    dispatch,
    firstName,
    lastName,
    legalID,
    email,
    password,
    password2,
    phone,
    street,
    number,
    postalCode,
    city,
    country,
    sendMail,
  ]);

  const genPass = useCallback(() => {
    let pass = passGenerator.generate({
      length: 8,
      numbers: true,
      uppercase: true,
    });
    setPassword(pass);
    setPassword2(pass);
    setTimeout(() => {
      M.updateTextFields();
    }, 300);
  }, []);

  const handleFilterSelection = useCallback((e) => {
    switch (e.target.value) {
      case 'cliente':
        setActiveFilter('cliente');
        break;
      case 'contacto':
        setActiveFilter('contacto');
        break;
      case 'caso':
        setActiveFilter('caso');
        break;
      default:
        break;
    }
  }, []);

  const handleSendMailCheck = useCallback(() => {
    setSendMail(!sendMail);
  }, [sendMail]);

  const delContact = useCallback(
    (contact) => {
      dispatch(deleteContact({ contactID: contact._id }));
    },
    [dispatch]
  );
  return (
    <div className='client-admin-container'>
      <div className='row'>
        <div className='col s12 center'>
          <h5 className='use-oswald-font'>BUSCADOR</h5>
          <p className='grey-text text-lighten-1'>
            Busca clientes, usuarios, casos etc.
          </p>
          <p>
            Si quieres añadir un nuevo cliente pulsa{' '}
            <a href='#newClientModal' className='modal-trigger'>
              aqui
            </a>
          </p>
          <div className='modal modal-fixed-footer' id='newClientModal'>
            <div className='modal-content'>
              <div className='row'>
                <div className='col s12 center'>
                  <h5>Nuevo Cliente</h5>
                </div>
              </div>
              <div className='row'>
                <div className='input-field col s12 m4'>
                  <input
                    type='text'
                    name='newCli_firstName'
                    id='newCli_firstName'
                    className='validate'
                    onChange={(e) => handleTextChange(e, 'firstName')}
                  />
                  <label htmlFor='newCli_firstName' className='active'>
                    Nombre
                  </label>
                </div>
                <div className='input-field col s12 m4'>
                  <input
                    type='text'
                    name='newCli_lastName'
                    id='newCli_lastName'
                    className='validate'
                    onChange={(e) => handleTextChange(e, 'lastName')}
                  />
                  <label htmlFor='newCli_lastName' className='active'>
                    Apellidos
                  </label>
                </div>
                <div className='input-field col s12 m4'>
                  <input
                    type='text'
                    name='newCli_ID'
                    id='newCli_ID'
                    className='validate'
                    onChange={(e) => handleTextChange(e, 'legalID')}
                  />
                  <label htmlFor='newCli_ID' className='active'>
                    NIE/NIF/CIF/DNI/Pasaporte
                  </label>
                </div>
                <div className='input-field col s12 m6'>
                  <input
                    type='email'
                    name='newCli_email'
                    id='newCli_email'
                    className='validate'
                    onChange={(e) => handleTextChange(e, 'email')}
                  />
                  <label htmlFor='newCli_email' className='active'>
                    Email
                  </label>
                </div>
                <div className='input-field col s12 m6'>
                  <input
                    type='text'
                    name='newCli_phone'
                    id='newCli_phone'
                    className='validate'
                    maxLength={9}
                    onChange={(e) => handleTextChange(e, 'phone')}
                  />
                  <label htmlFor='newCli_phone' className='active'>
                    Telefono
                  </label>
                </div>
                <div className='input-field col s12 m5'>
                  <input
                    type='text'
                    name='newCli_street'
                    id='newCli_street'
                    className='validate'
                    onChange={(e) => handleTextChange(e, 'street')}
                  />
                  <label htmlFor='newCli_street' className='active'>
                    Calle
                  </label>
                </div>
                <div className='input-field col s12 m1'>
                  <input
                    type='text'
                    name='newCli_number'
                    id='newCli_number'
                    className='validate'
                    onChange={(e) => handleTextChange(e, 'number')}
                  />
                  <label htmlFor='newCli_number' className='active'>
                    Nº
                  </label>
                </div>
                <div className='input-field col s12 m2'>
                  <input
                    type='text'
                    name='newCli_postalCode'
                    id='newCli_postalCode'
                    className='validate'
                    onChange={(e) => handleTextChange(e, 'postalCode')}
                  />
                  <label htmlFor='newCli_postalCode' className='active'>
                    CP
                  </label>
                </div>
                <div className='input-field col s12 m4'>
                  <input
                    type='text'
                    name='newCli_city'
                    id='newCli_city'
                    className='validate'
                    onChange={(e) => handleTextChange(e, 'city')}
                  />
                  <label htmlFor='newCli_city' className='active'>
                    Ciudad
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='col s12'>
                  <h6>Generar contraseña</h6>
                </div>
              </div>
              <div className='row'>
                <div className='input-field col s12 m4'>
                  <input
                    type={showPass ? 'text' : 'password'}
                    name='newCli_pass'
                    id='newCli_pass'
                    className='validate'
                    value={password}
                    onChange={(e) => handleTextChange(e, 'password')}
                  />
                  <label htmlFor='newCli_pass'>Contraseña</label>
                </div>
                <div className='input-field col s12 m4'>
                  <input
                    type={showPass ? 'text' : 'password'}
                    name='newCli_pass2'
                    id='newCli_pass2'
                    className='validate'
                    value={password2}
                    onChange={(e) => handleTextChange(e, 'password2')}
                  />
                  <label htmlFor='newCli_pass2'>Confirmar Contraseña</label>
                </div>
                <div className='input-field col s6 m1'>
                  <button
                    className='btn-flat waves-effect'
                    onClick={() => setShowPass(!showPass)}
                  >
                    <i className='material-icons'>
                      {showPass ? 'visibility' : 'visibility_off'}
                    </i>
                  </button>
                </div>
                <div className='input-field col s6 m3'>
                  <button
                    className='btn-flat blue white-text'
                    onClick={genPass}
                  >
                    Generar
                  </button>
                </div>
                <p>
                  <label>
                    <input
                      type='checkbox'
                      checked={sendMail}
                      onChange={handleSendMailCheck}
                    />
                    <span>Enviar datos por correo al cliente?</span>
                  </label>
                </p>
              </div>
              <div className='row'>
                <div className='col s12'>
                  <p>
                    <strong>!!!Alta nuevo cliente: </strong>
                    <i>
                      Cuando damos de alta un nuevo cliente, de forma automatica
                      se genera un usuario y una contraseña, que se manda por
                      correo al cliente. El cliente solo tiene que confirmar sus
                      datos (email etc.) y podra tener acceso al sistema donde
                      puede modificar o actualizar sus datos, ver casos abiertos
                      etc.
                    </i>
                  </p>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                className='btn-flat blue white-text left waves-effect'
                onClick={createNewClient}
              >
                guardar
              </button>
              <button className='btn-flat blue white-text right waves-effect modal-close'>
                cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='top-search-wrapper'>
            <div className='top-search-bar'>
              <select onChange={(e) => handleFilterSelection(e)}>
                {filters.map((f) => (
                  <option key={f} value={f}>
                    {f}
                  </option>
                ))}
              </select>
              <input
                type='text'
                name='search'
                id='search'
                placeholder='buscar'
                onChange={(e) => handleSearchTerm(e)}
              />
              <div className='top-search-action'>
                <button className='waves-effect white'>
                  <i className='material-icons'>search</i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <SearchContainer
          clients={clients}
          contacts={contacts}
          activeFilter={activeFilter}
          delContact={delContact}
        />
      </div>
    </div>
  );
};
export default Clients;
