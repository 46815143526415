import React from 'react';
import Truncate from 'react-truncate';

const TablePosts = ({ post, deletePost, selectPost, toggleHidePost }) => {
  const dateFormat = post.date.split('T');
  return (
    <tr>
      <td>
        <i className={`material-icons ${post.color}-text`}>bookmark</i>
      </td>
      <td>
        {post.featured ? (
          <i className='material-icons blue-text'>check</i>
        ) : (
          <i className='material-icons'>close</i>
        )}
      </td>
      <td>{dateFormat[0]}</td>
      <td>{dateFormat[1]}</td>
      <td>{post.category}</td>
      <td>{post.title}</td>
      <td>
        <div style={{ wordBreak: 'break-all' }}>
          <Truncate lines={1} width={300}>
            {post.description}
          </Truncate>
        </div>
      </td>
      <td>
        <div>
          <button
            className='btn-flat waves-effect'
            onClick={() => toggleHidePost(post._id)}
          >
            <i className='material-icons blue-text small'>
              {post.hidden ? 'visibility_off' : 'visibility'}
            </i>
          </button>
          <button
            className='btn-flat waves-effect'
            onClick={() => selectPost(post._id)}
          >
            <i className='material-icons green-text small'>edit</i>
          </button>
          <button
            className='btn-flat waves-effect'
            onClick={() => deletePost(post._id)}
          >
            <i className='material-icons red-text small'>delete</i>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default TablePosts;
