import React from 'react';
import { useSelector } from 'react-redux';

const TabPagos = (props) => {
  const appointment = useSelector((state) => state.appointment);

  return (
    <div className='card large'>
      <div className='card-content enable-card-overflow-y'>
        <table className='centered highlighted fixed-width-table'>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Euros</th>
              <th>Estado</th>
              <th>Consulta</th>
            </tr>
          </thead>
          <tbody>
            {appointment.appointments.map((app) => (
              <tr key={app._id}>
                <td>{app.date}</td>
                <td>50</td>
                <td>Pagado</td>
                <td>{app.sessionID}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TabPagos;
