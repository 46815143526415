import React, { useEffect, useState } from 'react';
import './App.css';
import '../node_modules/materialize-css/sass/materialize.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import { Provider } from 'react-redux';
import store from './redux/store';
import PrivateRoute from './utils/PrivateRoute';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './redux/actions/authActions';

//components
import Landing from './components/Layout/Landing';
import Footer from './components/Layout/Footer';
import Navbar from './components/Layout/Navbar';
import AdminDashboard from './components/dashboard/AdminDashboard';
import Despacho from './components/despacho/Despacho';
import Contacto from './components/contacto/Contacto';
import Blog from './components/blog/Blog';
import BlogFullPost from './components/blog/BlogFullPost';
import Areas from './components/areas/Areas';
import BlogAdmin from './components/dashboard/blogAdmin/BlogAdmin';
import Clients from './components/dashboard/clients/Clients';
import ScrollToTop from './components/misc/ScrollToTop';
import Legal from './components/legal/Legal';
import Cookies from './components/legal/Cookies';
import Consent from './components/misc/Consent';
import NotFound from './components/misc/NotFound';
import Dashboard from './components/user/Dashboard';
import VideoCall from './components/VideoCall/VideoCall';
import VideoMeeting from './components/dashboard/videocall/VideoMeeting';
import NewVideoMeeting from './components/payment/NewVideoMeeting';
import Login from './components/auth/Login';
import MainContextProvider from './components/context/MainContextProvider';
import Payments from './components/dashboard/payments/Payments';
import Consulta from './components/consulta/Consulta';
import Register from './components/auth/Register';

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = '/';
  }
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, {
  locale: 'es',
});

const App = (props) => {
  const [showConsent, setShowConsent] = useState(true);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('AJ_SES_SID');
    if (cookieConsent === 'true') {
      setShowConsent(false);
    }
  }, [showConsent]);
  return (
    <Provider store={store}>
      <MainContextProvider>
        <Elements stripe={stripePromise}>
          <Router>
            <ScrollToTop>
              <div className='App'>
                <Navbar />
                <Switch>
                  <Route exact path='/' component={Landing} />
                  <Route exact path='/despacho' component={Despacho} />
                  <Route exact path='/contacto' component={Contacto} />
                  <Route exact path='/areas' component={Areas} />
                  <Route exact path='/blog/pag/:pageId' component={Blog} />
                  <Route
                    exact
                    path='/blog/pag/:pagID/full/:postID'
                    component={BlogFullPost}
                  />
                  <Route exact path='/legal' component={Legal} />
                  <Route exact path='/cookies' component={Cookies} />
                  <Route exact path='/consultas' component={Consulta} />
                  <Route exact path='/admin/login' component={Login} />
                  <Route exact path='/register' component={Register} />
                  <PrivateRoute
                    exact
                    path='/blog/full/:postID'
                    component={BlogFullPost}
                  />
                  <PrivateRoute
                    exact
                    path='/admin/dashboard'
                    component={AdminDashboard}
                  />
                  <PrivateRoute
                    exact
                    path='/admin/blog-admin'
                    component={BlogAdmin}
                  />
                  <PrivateRoute
                    exact
                    path='/admin/clients'
                    component={Clients}
                  />
                  <PrivateRoute
                    exact
                    path='/admin/pagos'
                    component={Payments}
                  />
                  <PrivateRoute
                    exact
                    path='/admin/consultas'
                    component={VideoMeeting}
                  />
                  <PrivateRoute
                    exact
                    path='/compra/videoconsulta'
                    component={NewVideoMeeting}
                  />
                  <PrivateRoute exact path='/dashboard' component={Dashboard} />
                  <PrivateRoute
                    exact
                    path='/videoconsulta/:id'
                    component={VideoCall}
                  />
                  <Route path='*' component={NotFound} />
                </Switch>
                <Footer />
                {showConsent && <Consent />}
              </div>
            </ScrollToTop>
          </Router>
        </Elements>
      </MainContextProvider>
    </Provider>
  );
};

export default App;
