import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'is-empty';
import { updateNotifications } from '../../../redux/actions/profileActions';

const NotificationsCard = (props) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);

  const [newSignup, setNewSignup] = useState(false);
  const [newMeeting, setNewMeeting] = useState(false);

  useEffect(() => {
    if (!isEmpty(profile.profile)) {
      setNewSignup(profile.profile.notifications.email.receiveNewSignup);
      setNewMeeting(profile.profile.notifications.email.receiveNewMeeting);
    }
  }, [profile]);

  const saveData = useCallback(() => {
    const data = {
      notifications: {
        email: {
          receiveNewSignup: newSignup,
          receiveNewMeeting: newMeeting,
        },
      },
    };
    dispatch(updateNotifications(data));
  }, [dispatch, newSignup, newMeeting]);

  const handleChange = useCallback(
    (e, field) => {
      switch (field) {
        case 'signup':
          setNewSignup(!newSignup);
          break;
        case 'meeting':
          setNewMeeting(!newMeeting);
          break;
        default:
          break;
      }
    },
    [newSignup, newMeeting]
  );
  return (
    <div className='acc-r-wrapper'>
      <div className='row'>
        <div className='col s12 m4'>
          <h6>Correos</h6>
          <p>
            <label>
              <input
                type='checkbox'
                checked={newSignup}
                onChange={(e) => handleChange(e, 'signup')}
              />
              <span>Recibir correo nueva consulta</span>
            </label>
          </p>
          <p>
            <label>
              <input
                type='checkbox'
                checked={newMeeting}
                onChange={(e) => handleChange(e, 'meeting')}
              />
              <span>Recibir correo alta nueva de cliente</span>
            </label>
          </p>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <button
            className='btn-flat blue white-text waves-effect'
            onClick={saveData}
          >
            guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationsCard;
