import React, { useEffect, useState, useCallback } from 'react';
import M from 'materialize-css';
import { useDispatch, useSelector } from 'react-redux';
import {
  createNewCase,
  closeCase,
  deleteClientFile,
  downloadClientFile,
  hideShowFile,
} from '../../../../redux/actions/clientActions';
import isEmpty from 'is-empty';
import DragNDrop from '../../../misc/DragNDrop';

const CasosTab = ({ userInfo }) => {
  const dispatch = useDispatch();
  const upload = useSelector((state) => state.upload);
  const [caseNumber, setCaseNumber] = useState('');
  const [caseCategory, setCaseCategory] = useState('');
  const [caseTitle, setCaseTitle] = useState('');
  const [caseDescription, setCaseDescription] = useState('');
  const [categories] = useState([
    'CIVIL',
    'PENAL',
    'LABORAL',
    'FAMILIA',
    'EXTRANJERIA',
    'OTRO',
  ]);

  useEffect(() => {
    const modal = document.querySelectorAll('.modal');
    M.Modal.init(modal, {});
    const select = document.querySelectorAll('select');
    M.FormSelect.init(select, {});
  }, []);

  const createCase = useCallback(() => {
    const data = {
      number: caseNumber,
      category: caseCategory,
      title: caseTitle,
      description: caseDescription,
      clientID: userInfo.user._id,
    };
    dispatch(createNewCase(data));
  }, [
    dispatch,
    caseCategory,
    caseNumber,
    caseTitle,
    caseDescription,
    userInfo,
  ]);

  const handleTextChange = useCallback((e, field) => {
    switch (field) {
      case 'number':
        setCaseNumber(e.target.value);
        break;
      case 'category':
        setCaseCategory(e.target.value);
        break;
      case 'title':
        setCaseTitle(e.target.value);
        break;
      case 'description':
        setCaseDescription(e.target.value);
        break;
      default:
        break;
    }
  }, []);

  const closeTheCase = useCallback(
    (caseID) => {
      dispatch(closeCase({ caseID }));
    },
    [dispatch]
  );
  const deleteFile = useCallback(
    (c, doc) => {
      const data = {
        caseID: c._id,
        docID: doc._id,
      };
      dispatch(deleteClientFile(data));
    },
    [dispatch]
  );

  const toggleHideShowFile = useCallback(
    (c, doc) => {
      const data = {
        caseID: c._id,
        docID: doc._id,
      };
      dispatch(hideShowFile(data));
    },
    [dispatch]
  );

  const downloadFile = useCallback(
    (c, doc) => {
      dispatch(downloadClientFile(c._id, doc._id, doc.fileName));
    },
    [dispatch]
  );
  return (
    <div className='tab-cli'>
      <div className='row'>
        <div className='col s12'>
          {!isEmpty(userInfo) && userInfo.cases.length === 0 ? (
            <div className='no-case-wrapper'>
              <span>No hay ningun caso para este cliente</span>
              <a
                href='#newCaseModal'
                className='modal-trigger btn-flat waves-effect blue white-text'
              >
                Crear nuevo caso
              </a>
              <div className='modal modal-fixed-footer' id='newCaseModal'>
                <div className='modal-content'>
                  <div className='row'>
                    <div className='col s12 center'>
                      <h5>Nuevo Caso</h5>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='input-field col s12 m2'>
                      <input
                        type='text'
                        name='newCase_number'
                        id='newCase_number'
                        className='validate'
                        onChange={(e) => handleTextChange(e, 'number')}
                      />
                      <label htmlFor='newCase_number' className='active'>
                        Nº
                      </label>
                    </div>
                    <div className='input-field col s12 m4'>
                      <select onChange={(e) => handleTextChange(e, 'category')}>
                        {categories.map((cat) => (
                          <option key={cat} value={cat}>
                            {cat}
                          </option>
                        ))}
                      </select>
                      <label>Categoria</label>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='input-field col s12'>
                      <input
                        type='text'
                        name='newCase_title'
                        id='newCase_title'
                        className='validate'
                        onChange={(e) => handleTextChange(e, 'title')}
                      />
                      <label htmlFor='newCase_title' className='active'>
                        Asunto
                      </label>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='input-field col s12'>
                      <textarea
                        id='newCase_txtArea'
                        className='materialize-textarea'
                        onChange={(e) => handleTextChange(e, 'description')}
                      />
                      <label htmlFor='newCase_txtArea' className='active'>
                        Descripcion del caso
                      </label>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    className='btn-flat waves-effect blue white-text left modal-close'
                    onClick={createCase}
                  >
                    guardar caso
                  </button>
                  <button className='btn-flat waves-effect blue white-text right modal-close'>
                    cerrar
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className='meetings-list-wrapper'>
              <table className='centered highlight'>
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Numero</th>
                    <th>Estado</th>
                    <th>Categoria</th>
                    <th>Asunto</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {userInfo.cases.map((c) => (
                    <tr key={c._id}>
                      <td>{c.date}</td>
                      <td>{c.number}</td>
                      <td>
                        <span
                          className={
                            c.status === 'cerrado' ? 'red-text' : 'blue-text'
                          }
                        >
                          {c.status}
                        </span>
                      </td>
                      <td>{c.category}</td>
                      <td>
                        <span className='truncate'>{c.title}</span>
                      </td>
                      <td>
                        <a href='#meetModal' className='modal-trigger'>
                          <i className='material-icons blue-text'>visibility</i>
                        </a>
                        <a href='#filesModal' className='modal-trigger'>
                          <i className='material-icons orange-text'>folder</i>
                        </a>
                        <div
                          className='modal modal-fixed-footer'
                          id='meetModal'
                        >
                          <div className='modal-content'>
                            <div className='modal-title-container'>
                              <span>{c.category}</span>
                              <span>{c.number}</span>
                            </div>
                            <div className='row'>
                              <div className='col s12'>
                                <h6>{c.title}</h6>
                                <span>{c.description}</span>
                              </div>
                            </div>
                          </div>
                          <div className='modal-footer'>
                            {c.status === 'abierto' && (
                              <button
                                className='btn-flat red white-text waves-effect modal-close left'
                                onClick={() => closeTheCase(c._id)}
                              >
                                cerrar caso
                              </button>
                            )}
                            <button className='btn-flat blue white-text waves-effect modal-close right'>
                              cerrar
                            </button>
                          </div>
                        </div>
                        <div
                          className='modal modal-fixed-footer'
                          id='filesModal'
                        >
                          <div className='modal-content'>
                            <div className='modal-title-container'>
                              <span>{c.category}</span>
                              <span>{c.number}</span>
                            </div>
                            <DragNDrop userCase={c} userInfo={userInfo} />
                            {upload.uploading && (
                              <div className='progress'>
                                <div
                                  className='determinate blue'
                                  style={{
                                    width: `${parseInt(
                                      upload.uploadProgress * 100
                                    )}%`,
                                  }}
                                ></div>
                              </div>
                            )}
                            <div className='row'>
                              {c.documents.length !== 0 &&
                                c.documents.map((doc) => (
                                  <div
                                    className='case-file-wrapper'
                                    key={doc._id}
                                  >
                                    <div className='case-file-left'>
                                      <div>
                                        <span>{doc.fileName}</span>
                                        <span>
                                          {doc.uploadDate.substr(0, 10)}
                                        </span>
                                        <span>
                                          {doc.fileExtension.replace('.', '')}
                                        </span>
                                      </div>
                                    </div>
                                    <div className='case-file-right'>
                                      <button
                                        onClick={() => deleteFile(c, doc)}
                                      >
                                        <i className='material-icons red-text'>
                                          delete_forever
                                        </i>
                                      </button>
                                      <button
                                        onClick={() =>
                                          toggleHideShowFile(c, doc)
                                        }
                                      >
                                        <i className='material-icons blue-text'>
                                          {doc.visible
                                            ? 'visibility'
                                            : 'visibility_off'}
                                        </i>
                                      </button>
                                      <button
                                        onClick={() => downloadFile(c, doc)}
                                      >
                                        <i className='material-icons'>
                                          cloud_download
                                        </i>
                                      </button>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                          <div className='modal-footer'>
                            <button className='btn-flat blue white-text waves-effect modal-close right'>
                              cerrar
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className='row'>
                <div className='col s12'>
                  <a
                    href='#newCaseModal'
                    className='modal-trigger btn-flat waves-effect blue white-text right'
                  >
                    Crear nuevo caso
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='modal modal-fixed-footer' id='newCaseModal'>
        <div className='modal-content'>
          <div className='row'>
            <div className='col s12 center'>
              <h5>Nuevo Caso</h5>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m2'>
              <input
                type='text'
                name='newCase_number'
                id='newCase_number'
                className='validate'
                onChange={(e) => handleTextChange(e, 'number')}
              />
              <label htmlFor='newCase_number' className='active'>
                Nº
              </label>
            </div>
            <div className='input-field col s12 m4'>
              <select onChange={(e) => handleTextChange(e, 'category')}>
                {categories.map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>
                ))}
              </select>
              <label>Categoria</label>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12'>
              <input
                type='text'
                name='newCase_title'
                id='newCase_title'
                className='validate'
                onChange={(e) => handleTextChange(e, 'title')}
              />
              <label htmlFor='newCase_title' className='active'>
                Asunto
              </label>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12'>
              <textarea
                id='newCase_txtArea'
                className='materialize-textarea'
                onChange={(e) => handleTextChange(e, 'description')}
              />
              <label htmlFor='newCase_txtArea' className='active'>
                Descripcion del caso
              </label>
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button
            className='btn-flat waves-effect blue white-text left modal-close'
            onClick={createCase}
          >
            guardar caso
          </button>
          <button className='btn-flat waves-effect blue white-text right modal-close'>
            cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default CasosTab;
