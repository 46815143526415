import React, { useCallback, useContext, useEffect, useState } from 'react';
import M from 'materialize-css';
import { Link } from 'react-router-dom';
import { zoomIn, flipInX, pulse } from 'react-animations';
import { StyleSheet, css } from 'aphrodite';
import ScrollToTopBtn from '../misc/ScrollToTopBtn';
import { Helmet } from 'react-helmet';
import MainContext from '../../components/context/MainContext';

const Landing = () => {
  const mainContext = useContext(MainContext);
  const [headerImg, setHeaderImg] = useState('');
  const [showGotoTop, setShowGotoTop] = useState(false);
  const [showCivil, setShowCivil] = useState(false);
  const [showFamily, setShowFamily] = useState(false);
  const [showLaboral, setShowLaboral] = useState(false);
  const [showPenal, setShowPenal] = useState(false);
  const [showExtranjeria, setShowExtranjeria] = useState(false);
  const [below600, setBelow600] = useState(false);

  const adjustHeaderImg = useCallback(
    (e) => {
      if (window.innerWidth < 600) {
        setHeaderImg(mainContext.landingImages[0]);
        setBelow600(true);
      } else {
        setHeaderImg(mainContext.landingImages[1]);
        setBelow600(false);
      }
    },
    [mainContext]
  );

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 400) {
      setShowGotoTop(true);
      setShowCivil(true);
    } else {
      setShowGotoTop(false);
    }

    if (window.pageYOffset > 800) {
      setShowFamily(true);
    }
    if (window.pageYOffset > 1200) {
      setShowLaboral(true);
    }
    if (window.pageYOffset > 1800) {
      setShowPenal(true);
    }
    if (window.pageYOffset > 2200) {
      setShowExtranjeria(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', adjustHeaderImg);
    window.addEventListener('scroll', handleScroll);
    setHeaderImg(mainContext.landingImages[0]);
    const coverSlider = document.querySelectorAll('.slider');
    M.Slider.init(coverSlider, {
      indicators: false,
      height: 1000,
      duration: 800,
      interval: 4000,
    });
    const parallax = document.querySelectorAll('.parallax');
    M.Parallax.init(parallax, {});
    return () => {
      window.removeEventListener('resize', adjustHeaderImg);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [adjustHeaderImg, handleScroll, mainContext]);

  useEffect(() => {
    let innerWidth = window.innerWidth;
    if (innerWidth <= 600) {
      setBelow600(true);
    } else {
      setBelow600(false);
    }
  }, []);
  return (
    <div className='landing-container'>
      <Helmet>
        <title>
          Avocat Roman in Madrid Alina Wagner | Abogado Rumano en Madrid
        </title>
        <meta
          name='keywords'
          content='abogado,abogada,rumano,madrid,avocat,roman,alina,wagner,jiverdeanu,divorcio,derecho,civil,laboral,extranjeria,spania,españa,alcala henares,guadalajara,declaratii,nie,divort,consulat,ambasada,traduceri,traduciones,coslada'
        />
      </Helmet>
      {showGotoTop && <ScrollToTopBtn />}
      <div className='landing-wrapper'>
        <div className='row'>
          <div className='col s12'>
            <div className='parallax-container custom-landing-container'>
              <div className='parallax'>
                <img src={headerImg} alt='' className='responsive-img' />
                <div
                  className='center'
                  style={{
                    position: 'absolute',
                    top: '100px',
                    left: '20px',
                    right: '20px',
                    bottom: '0px',
                  }}
                >
                  <div className='row'>
                    <div
                      className='col l7 s12'
                      style={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
                    >
                      <h3
                        className='black-text'
                        style={{
                          fontFamily: 'Cinzel',
                          textAlign: 'center',
                        }}
                      >
                        Abogada
                      </h3>
                      <h3
                        className='black-text'
                        style={{
                          fontFamily: 'Cinzel',
                          textAlign: 'center',
                          borderBottom: '2px solid red',
                        }}
                      >
                        Alina Wagner
                      </h3>
                    </div>
                  </div>
                  <div className='row'>
                    <div
                      className='col l7 s12'
                      style={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
                    >
                      <div className={css(styles.flipInY)}>
                        <span className={css(styles.motto, styles.mottoSmall)}>
                          ASESORAMIENTO LEGAL DE CALIDAD
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div
                      className='col l7 s12'
                      style={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
                    >
                      <h5
                        className='light black-text text-lighten-3'
                        style={{ fontFamily: 'Playfair' }}
                      >
                        Como Letrada, lo mejor de mi profesión es defender lo
                        justo!
                      </h5>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col l7 s12 center'>
                      <br />
                      <div className={css(styles.pulse)}>
                        <Link
                          to='/consultas'
                          className='btn red white-text waves-effect'
                        >
                          Pide una consulta!
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className='bottom-h-reviews'>
                    <div className='row'>
                      <div className='col s12 l7'>
                        {/* <p
                            style={{
                              fontSize: '1.5rem',
                              color: 'grey',
                              fontFamily: 'Cinzel',
                            }}>
                            TESTIMONIOS
                          </p> */}
                        <div className='slider'>
                          <ul className='slides transparent'>
                            <li>
                              <div className='card reviews-card'>
                                <div className='card-content'>
                                  <p
                                    style={{
                                      fontStyle: 'italic',
                                      fontFamily: 'Cinzel',
                                    }}
                                  >
                                    "De mare ajutor sa avem un asa avocat in
                                    Madrid, pe mine ma ajutat foarte mult si
                                    repede. Multumesc Dna. Avocat Wagner!"
                                  </p>
                                  <p
                                    style={{
                                      fontSize: '1.2rem',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Gabriel Romașcanu
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className='card reviews-card'>
                                <div className='card-content'>
                                  <p
                                    style={{
                                      fontStyle: 'italic',
                                      fontFamily: 'Cinzel',
                                    }}
                                  >
                                    "He acudido al despacho para conseguir la
                                    tarjeta de reagrupación y me lo consiguieron
                                    rapidisimo. Gracias por le trabajo
                                    realizado!"
                                  </p>
                                  <p
                                    style={{
                                      fontSize: '1.2rem',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Laydi Rivas Moreno
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className='card reviews-card'>
                                <div className='card-content'>
                                  <p
                                    style={{
                                      fontStyle: 'italic',
                                      fontFamily: 'Cinzel',
                                    }}
                                  >
                                    "Muy buen trabajo, nacionalidad española
                                    conseguida enseguida. Gracias!"
                                  </p>
                                  <p
                                    style={{
                                      fontSize: '1.2rem',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Laura Sánchez Garcia
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className='card reviews-card'>
                                <div className='card-content'>
                                  <p
                                    style={{
                                      fontStyle: 'italic',
                                      fontFamily: 'Cinzel',
                                    }}
                                  >
                                    "Mi tarjeta comunitaria me ha salido en poco
                                    tiempo. Gracias!"
                                  </p>
                                  <p
                                    style={{
                                      fontSize: '1.2rem',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Enrique Fernández
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className='card reviews-card'>
                                <div className='card-content'>
                                  <p
                                    style={{
                                      fontStyle: 'italic',
                                      fontFamily: 'Cinzel',
                                    }}
                                  >
                                    "Trabajo profesional y rápido con mucha
                                    comunicación con el cliente. Lo recomiendo y
                                    le llamaré para futuros servicios."
                                  </p>
                                  <p
                                    style={{
                                      fontSize: '1.2rem',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    ArNay Gamer
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className='card reviews-card'>
                                <div className='card-content'>
                                  <p
                                    style={{
                                      fontStyle: 'italic',
                                      fontFamily: 'Cinzel',
                                    }}
                                  >
                                    "Mulțumim frumos pentru încredere!"
                                  </p>
                                  <p
                                    style={{
                                      fontSize: '1.2rem',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Ana Craciun
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='center' style={{ marginBottom: '10px' }}>
        <span
          style={{
            fontFamily: 'Cinzel',
            fontSize: '3rem',
            textShadow: '2px 4px lightgrey',
            borderBottom: '2px solid red',
          }}
        >
          servicios legales profesionales
        </span>
      </div>
      <div className='parallax-container'>
        <div className='parallax'>
          <img
            src={mainContext.landingImages[2]}
            alt=''
            className='responsive-img'
            style={{ filter: 'grayscale(100%)' }}
          />
          {!below600 && (
            <div className='p-info-r'>
              <div className='p-info-text-r'>
                {showCivil && (
                  <div className={css(styles.zoomIn)}>
                    <h3 className='p-info-l-text-title'>Derecho Civil</h3>
                    <p>
                      Amplia e importante rama del Derecho que contiene la
                      regulación de gran parte de los asuntos o casos que
                      afectan a la esfera privada de las personas y que se
                      revelan tanto en sus relaciones de carácter económico como
                      en su esfera personal.
                    </p>
                    <ul>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span className='p-info-check'>Compraventa</span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Arrendamientos Urbanos
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>Desahucios</span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Reclamaciones Derivadas de Compraventa de Inmuebles
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Reclamaciones Judiciales Derivadas de Contratos
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Incumplimientos Contractuales
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Reclamaciones de todo tipo ...
                        </span>
                      </li>
                    </ul>
                    <br />
                    <div className='center'>
                      <Link
                        to='/areas'
                        className='btn-flat waves-effect waves-light red white-text'
                      >
                        Mas Informacion sobre derecho Civil
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {below600 && (
        <div className='section white'>
          <div className='row'>
            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
              {showCivil && (
                <div className={css(styles.zoomIn)}>
                  <h3 className='p-info-l-text-title'>Derecho Civil</h3>
                  <p>
                    Amplia e importante rama del Derecho que contiene la
                    regulación de gran parte de los asuntos o casos que afectan
                    a la esfera privada de las personas y que se revelan tanto
                    en sus relaciones de carácter económico como en su esfera
                    personal.
                  </p>
                  <ul>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span className='p-info-check'>Compraventa</span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Arrendamientos Urbanos
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>Desahucios</span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Reclamaciones Derivadas de Compraventa de Inmuebles
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Reclamaciones Judiciales Derivadas de Contratos
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Incumplimientos Contractuales
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Reclamaciones de todo tipo ...
                      </span>
                    </li>
                  </ul>
                  <br />
                  <div className='center'>
                    <Link
                      to='/areas'
                      className='btn-flat waves-effect waves-light red white-text'
                    >
                      Mas Informacion sobre derecho Civil
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className='parallax-container'>
        <div className='parallax'>
          <img
            src={mainContext.landingImages[3]}
            alt=''
            className='responsive-img'
            style={{ filter: 'grayscale(100%)' }}
          />
          {!below600 && (
            <div className='p-info-l'>
              <div className='p-info-text-l'>
                {showFamily && (
                  <div className={css(styles.zoomIn)}>
                    <h3 className='p-info-l-text-title'>Derecho de Familia</h3>
                    <p>
                      Como Letrada especialista en derecho de familia en Alcala
                      de Henares, velamos por tus intereses y te defendemos en
                      tus asuntos jurídico familiares.
                    </p>
                    <ul>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Divorcio contencioso
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          {`Separación matrimonial o divorcio de común acuerdo (con o sin hijos y con liquidación de bienes)`}
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Nulidades matrimoniales civiles
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Liquidación de sociedades de gananciales
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Capitulaciones matrimoniales
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Conflictos de parejas de hecho
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>Y mas ...</span>
                      </li>
                    </ul>
                    <br />
                    <div className='center'>
                      <Link
                        to='/areas'
                        className='btn-flat waves-effect waves-light red white-text'
                      >
                        Mas Informacion sobre derecho de familia
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {below600 && (
        <div className='section white'>
          <div className='row'>
            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
              {showFamily && (
                <div className={css(styles.zoomIn)}>
                  <h3 className='p-info-l-text-title'>Derecho de Familia</h3>
                  <p>
                    Como Letrada especialista en derecho de familia en Alcala de
                    Henares, velamos por tus intereses y te defendemos en tus
                    asuntos jurídico familiares.
                  </p>
                  <ul>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Divorcio contencioso
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        {`Separación matrimonial o divorcio de común acuerdo (con o sin hijos y con liquidación de bienes)`}
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Nulidades matrimoniales civiles
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Liquidación de sociedades de gananciales
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Capitulaciones matrimoniales
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Conflictos de parejas de hecho
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>Y mas ...</span>
                    </li>
                  </ul>
                  <br />
                  <div className='center'>
                    <Link
                      to='/areas'
                      className='btn-flat waves-effect waves-light red white-text'
                    >
                      Mas Informacion sobre derecho de familia
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className='parallax-container'>
        <div className='parallax'>
          <img
            src={mainContext.landingImages[4]}
            alt=''
            className='responsive-img'
            style={{ filter: 'grayscale(100%)' }}
          />
          {!below600 && (
            <div className='p-info-r'>
              <div className='p-info-text-r'>
                {showLaboral && (
                  <div className={css(styles.zoomIn)}>
                    <h3 className='p-info-l-text-title'>Derecho Laboral</h3>
                    <p>
                      Rama del Derecho que regula las relaciones de las
                      disposiciones laborales, ya sea como trabajador por cuenta
                      ajena o por cuenta propia, entre otras áreas importantes.
                    </p>
                    <ul>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Acciones de reclamación de salarios
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Acciones de despido
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Infracciones y sanciones
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Acciones de extinción de contrato por causas
                          objetivas, económicas, etc.
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Vulneración de derechos fundamentales. Derecho a la
                          intimidad etc.
                        </span>
                      </li>
                      {/* <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Tramitación de expedientes de extinción de contratos
                          de trabajo
                        </span>
                      </li> */}
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Otras acciones y derechos ...
                        </span>
                      </li>
                    </ul>
                    <br />
                    <div className='center'>
                      <Link
                        to='/areas'
                        className='btn-flat waves-effect waves-light red white-text'
                      >
                        Mas Informacion sobre derecho Laboral
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {below600 && (
        <div
          style={{
            marginLeft: '10px',
            marginRight: '10px',
            marginBottom: '20px',
          }}
        >
          {showLaboral && (
            <div className={css(styles.zoomIn)}>
              <h3 className='p-info-l-text-title'>Derecho Laboral</h3>
              <p>
                Rama del Derecho que regula las relaciones de las disposiciones
                laborales, ya sea como trabajador por cuenta ajena o por cuenta
                propia, entre otras áreas importantes.
              </p>
              <ul>
                <li>
                  <i className='material-icons red-text'>done</i>
                  <span style={{ fontFamily: 'Cinzel' }}>
                    Acciones de reclamación de salarios
                  </span>
                </li>
                <li>
                  <i className='material-icons red-text'>done</i>
                  <span style={{ fontFamily: 'Cinzel' }}>
                    Acciones de despido
                  </span>
                </li>
                <li>
                  <i className='material-icons red-text'>done</i>
                  <span style={{ fontFamily: 'Cinzel' }}>
                    Infracciones y sanciones
                  </span>
                </li>
                <li>
                  <i className='material-icons red-text'>done</i>
                  <span style={{ fontFamily: 'Cinzel' }}>
                    Acciones de extinción de contrato por causas objetivas,
                    económicas, etc.
                  </span>
                </li>
                <li>
                  <i className='material-icons red-text'>done</i>
                  <span style={{ fontFamily: 'Cinzel' }}>
                    Vulneración de derechos fundamentales. Derecho a la
                    intimidad etc.
                  </span>
                </li>
                {/* <li>
                  <i className='material-icons red-text'>done</i>
                  <span style={{ fontFamily: 'Cinzel' }}>
                    Tramitación de expedientes de extinción de contratos de
                    trabajo
                  </span>
                </li> */}
                <li>
                  <i className='material-icons red-text'>done</i>
                  <span style={{ fontFamily: 'Cinzel' }}>
                    Otras acciones y derechos ...
                  </span>
                </li>
              </ul>
              <br />
              <div className='center'>
                <Link
                  to='/areas'
                  className='btn-flat waves-effect waves-light red white-text'
                >
                  Mas Informacion sobre derecho Laboral
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
      <div className='parallax-container'>
        <div className='parallax'>
          <img
            src={mainContext.landingImages[5]}
            alt=''
            className='responsive-img'
            style={{ filter: 'grayscale(100%)' }}
          />
          {!below600 && (
            <div className='p-info-l'>
              <div className='p-info-text-l'>
                {showPenal && (
                  <div className={css(styles.zoomIn)}>
                    <h3 className='p-info-l-text-title'>Derecho Penal</h3>
                    <p>
                      Actuamos en defensa de quienes hayan podido resultar
                      perjudicados por cualquier actuación considerada delictiva
                      por la Ley.
                    </p>
                    <ul>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Derecho Informatico
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Delito Informatico
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Delitos contra la integridad corporal
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Delitos contra la intimidad propia, imagen e
                          inviolabilidad del domicilio
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Delitos contra el Honor
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Delitos contra el patrimonio
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Otros delitos y derechos ...
                        </span>
                      </li>
                    </ul>
                    <br />
                    <div className='center'>
                      <Link
                        to='/areas'
                        className='btn-flat waves-effect waves-light red white-text'
                      >
                        Mas Informacion sobre derecho Penal
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {below600 && (
        <div className='section white'>
          <div className='row'>
            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
              {showPenal && (
                <div className={css(styles.zoomIn)}>
                  <h3 className='p-info-l-text-title'>Derecho Penal</h3>
                  <p>
                    Actuamos en defensa de quienes hayan podido resultar
                    perjudicados por cualquier actuación considerada delictiva
                    por la Ley.
                  </p>
                  <ul>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Derecho Informatico
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Delito Informatico
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Delitos contra la integridad corporal
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Delitos contra la intimidad propia, imagen e
                        inviolabilidad del domicilio
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Delitos contra el Honor
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Delitos contra el patrimonio
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Otros delitos y derechos ...
                      </span>
                    </li>
                  </ul>
                  <br />
                  <div className='center'>
                    <Link
                      to='/areas'
                      className='btn-flat waves-effect waves-light red white-text'
                    >
                      Mas Informacion sobre derecho Penal
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className='parallax-container'>
        <div className='parallax'>
          <img
            src={mainContext.landingImages[6]}
            alt=''
            className='responsive-img'
            style={{ filter: 'grayscale(100%)' }}
          />
          {!below600 && (
            <div className='p-info-r'>
              <div className='p-info-text-r'>
                {showExtranjeria && (
                  <div className={css(styles.zoomIn)}>
                    <h3 className='p-info-l-text-title-e'>Extranjeria</h3>
                    <p>
                      Hacemos uso extensivo de las nuevas tecnologías para
                      automatizar y acelerar el trabajo del despacho en los
                      expedientes de extranjería.
                    </p>
                    <ul>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Solicitud de nacionalidad española
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Permiso de residencia
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Reagrupacion familiar
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Certificacion de Residente Comunitario
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Cancelacion antecedentes penales
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Homologacion estudios
                        </span>
                      </li>
                      <li>
                        <i className='material-icons red-text'>done</i>
                        <span style={{ fontFamily: 'Cinzel' }}>
                          Recursos Contencioso Administrativo
                        </span>
                      </li>
                    </ul>
                    <br />
                    <div className='center'>
                      <Link
                        to='/areas'
                        className='btn-flat waves-effect waves-light red white-text'
                      >
                        Mas Informacion
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {below600 && (
        <div className='section white'>
          <div className='row'>
            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
              {showExtranjeria && (
                <div className={css(styles.zoomIn)}>
                  <h3 className='p-info-l-text-title-e'>Extranjeria</h3>
                  <p>
                    Hacemos uso extensivo de las nuevas tecnologías para
                    automatizar y acelerar el trabajo del despacho en los
                    expedientes de extranjería.
                  </p>
                  <ul>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Solicitud de nacionalidad española
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Permiso de residencia
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Reagrupacion familiar
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Certificacion de Residente Comunitario
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Cancelacion antecedentes penales
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Homologacion estudios
                      </span>
                    </li>
                    <li>
                      <i className='material-icons red-text'>done</i>
                      <span style={{ fontFamily: 'Cinzel' }}>
                        Recursos Contencioso Administrativo
                      </span>
                    </li>
                  </ul>
                  <br />
                  <div className='center'>
                    <Link
                      to='/areas'
                      className='btn-flat waves-effect waves-light red white-text'
                    >
                      Mas Informacion
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  zoomIn: {
    animationName: zoomIn,
    animationDuration: '4s',
  },
  flipInY: {
    animationName: flipInX,
    animationDuration: '4s',
  },
  pulse: {
    animationName: pulse,
    animationDuration: '1s',
    animationIterationCount: 'infinite',
  },
  motto: {
    color: 'black',
    textAlign: 'justify',
    fontFamily: 'Cinzel',
    fontSize: '3.6rem',
    textShadow: '2px 4px lightgrey',
  },
  mottoSmall: {
    '@media (max-width: 600px)': {
      color: 'black',
      textAlign: 'justify',
      fontFamily: 'Cinzel',
      fontSize: '2.6rem',
      textShadow: '2px 4px lightgrey',
    },
  },
});
export default Landing;
