import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AllPostsTab from './BlogTabs/AllPostsTab';
import CreatePostTab from './BlogTabs/CreatePostTab';
import {
  getAllPosts,
  _getPostById,
  clearThePost,
  deletePost,
  toggleShowHidePost,
} from '../../../redux/actions/blogActions';

const BlogAdmin = () => {
  const dispatch = useDispatch();
  const [showNewPostTab, setShowNewPostTab] = useState(true);
  const [showAllPostsTab, setShowAllPostsTab] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    dispatch(getAllPosts());
  }, [dispatch]);

  const handleTabSwitch = useCallback(
    (tab) => {
      switch (tab) {
        case 'all':
          if (editMode) {
            setEditMode(false);
            setShowAllPostsTab(true);
            setShowNewPostTab(false);
            dispatch(clearThePost());
          } else {
            setShowAllPostsTab(true);
            setShowNewPostTab(false);
          }
          break;
        case 'new':
          setShowAllPostsTab(false);
          setShowNewPostTab(true);
          break;
        default:
          break;
      }
    },
    [editMode, dispatch]
  );
  const selectedPost = useCallback(
    (postID) => {
      dispatch(_getPostById(postID));
      setShowNewPostTab(true);
      setShowAllPostsTab(false);
      setEditMode(true);
    },
    [dispatch]
  );
  const handleExitEditMode = useCallback(() => {
    if (editMode) {
      setEditMode(false);
      dispatch(clearThePost());
    }
  }, [editMode, dispatch]);

  const deleteThePost = useCallback(
    (postID) => {
      dispatch(deletePost({ postID }));
    },
    [dispatch]
  );
  const toggleHidePost = useCallback(
    (postID) => {
      dispatch(toggleShowHidePost({ postID }));
    },
    [dispatch]
  );
  return (
    <div className='blog-admin-container'>
      <div className='tab-admin-tabs'>
        <button
          className={
            showNewPostTab
              ? editMode
                ? 'tab-btn-blog-admin-edit'
                : 'tab-btn-blog-admin-active'
              : 'tab-btn-blog-admin'
          }
          onClick={() => handleTabSwitch('new')}
        >
          <i className='material-icons'>filter_none</i>
          <span>{editMode ? 'editar publicacion' : 'nueva publicacion'}</span>
        </button>
        <button
          className={
            showAllPostsTab ? 'tab-btn-blog-admin-active' : 'tab-btn-blog-admin'
          }
          onClick={() => handleTabSwitch('all')}
        >
          <i className='material-icons'>collections</i>
          <span>todas las publicaciones</span>
        </button>
      </div>
      <div className='row'>
        {showNewPostTab ? (
          <CreatePostTab
            exitEditMode={handleExitEditMode}
            isEditMode={editMode}
          />
        ) : (
          <AllPostsTab
            selectedPost={selectedPost}
            deletePost={deleteThePost}
            toggleHidePost={toggleHidePost}
          />
        )}
      </div>
    </div>
  );
};

export default BlogAdmin;
