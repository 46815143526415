import {
  SET_CURRENT_USER,
  SET_REQ_VERIFICATION,
  SET_USER_EMAIL_VERIFIED,
} from '../actions/types';
import isEmpty from '../../utils/is-empty';

const initialState = {
  isAuthenticated: false,
  user: {},
  requireVerification: false,
  verified: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case SET_REQ_VERIFICATION:
      return {
        ...state,
        requireVerification: action.payload,
      };
    case SET_USER_EMAIL_VERIFIED:
      return {
        ...state,
        verified: action.payload,
        requireVerification: false,
      };
    default:
      return state;
  }
};
export default authReducer;
