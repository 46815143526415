import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import MainContext from '../context/MainContext';
import ScrollToTopBtn from '../misc/ScrollToTopBtn';

const Despacho = (props) => {
  const mainContext = useContext(MainContext);
  const [scrollPosition, setScrollPosition] = useState('');

  const handleScroll = useCallback((e) => {
    setScrollPosition(window.pageYOffset);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);
  return (
    <div className='despacho-container'>
      <Helmet>
        <title>Despacho Abogada Alina Wagner</title>
        <link rel='canonical' href='https://awagner.es/despacho' />
        <meta property='og:title' content='Despacho Abogada Alina Wagner' />
        <meta property='og:url' content='https://awagner.es/despacho' />
        <meta property='og:type' content='website' />
        <meta
          property='og:description'
          content='Este Despacho, fue fundado en 2017 por Dª Alina Wagner, Licenciada en Derecho por la Facultad de Derecho de Alcalá de Henares, cuenta además con el Master ...'
        />
        <meta
          property='og:image'
          content='https://www.awagner.es/public/main_aw.webp'
        />
        <meta name='twitter:card' content='summary_large_image' />
        <meta property='twitter:domain' content='awagner.es' />
        <meta property='twitter:url' content='https://awagner.es/despacho' />
        <meta name='twitter:title' content='Despacho Abogada Alina Wagner' />
        <meta
          name='twitter:description'
          content='Este Despacho, fue fundado en 2017 por Dª Alina Wagner, Licenciada en Derecho por la Facultad de Derecho de Alcalá de Henares, cuenta además con el Master ...'
        />
        <meta
          name='twitter:image'
          content='https://www.awagner.es/public/main_aw.webp'
        />
      </Helmet>
      <ScrollToTopBtn />
      <div className='despacho-title-container'>
        <div className='despacho-title-left'>
          <span>ALINA</span>
          <span>WAGNER</span>
        </div>
        <div className='despacho-title-right'>
          <span>
            Abogado Alina Wagner Dogaru es la titular de este despacho de
            abogados, con sede en Alcalá de Henares, multidisciplinar y con
            operatividad en toda la geografía nacional.
          </span>
        </div>
      </div>
      <div
        className={
          scrollPosition > 60
            ? 'scroll-img-scaled move-text-up-100'
            : 'scroll-img-full'
        }
      >
        <img src={mainContext.despachoImages[0]} alt='oficina' />
        <div className='despacho-details'>
          <div>
            <span />
            <h5>DESPACHO</h5>
            <span />
          </div>
          <p>
            Este Despacho, fue fundado en 2017 por Dª Alina Wagner Dogaru,
            Licenciada en Derecho por la Facultad de Derecho de Alcalá de
            Henares, cuenta además con el Master Universitario en Acceso a la
            Profesión de Abogado por la Universidad de Alcalá. Numerosos
            clientes ya, tanto empresas como particulares, han confiado en los
            servicios que presta este despacho, cuyas principales líneas de
            actuación están inspiradas en la profesionalidad y el respeto a la
            deontología profesional.{' '}
            <span className='white-text'>
              Abogado rumano Madrid Alcalá de Henares
            </span>
          </p>
          <p>
            {`"Toda persona tiene derecho a un recurso efectivo, ante los
                tribunales nacionales competentes, que la ampare contra actos
                que violen sus derechos fundamentales reconocidos por la
                constitución o por la ley" - Artículo 8 DH`}
          </p>
          <div>
            <span />
            <h5>ABOGADO</h5>
            <span />
          </div>
          <p>
            Avocat Alina Wagner Dogaru, avocat român cu sediul în Madrid, Alcalá
            de Henares. Acest Birou a fost fondat în 2017 de Dª Alina Wagner
            Dogaru, unul dintre puținii avocați din Spania cu pregătire
            academică în Drept Spaniol, dar şi recunoscută de Consiliul European
            al Avocaților, licențiată în Drept pe teritoriul Spaniei, în cadrul
            Facultății de Drept, Alcalá de Henares, dar şi cu Masterul în Acces
            la Profesia de Avocat, realizat în Universitatea din Alcalá.
          </p>
          <div>
            <img src={mainContext.despachoImages[1]} alt='oficina' />
            <div>
              <p>
                Clienții, atât companii cât și persoane fizice, se bazează pe
                serviciile oferite de acest birou individual de avocatură, al
                cărui linii principale de acțiune sunt inspirate de
                profesionalism și de respectarea eticii profesionale.
              </p>
              <p>
                Venim în sprijinul clienţilor cu un program încarcat şi oferim
                consultanţă juridică, studiu documente, redactare acte juridice
                specifice, asistare şi reprezentare, în domeniile alăturate.
                Pentru mai multe informaţii privind serviciile complete oferite
                de biroul nostru accesaţi pagina noastra. Utilizarea
                informaţiilor, vă poate oferi un suport informativ şi este
                recomandată consultarea ulterioara a unui avocat care să vă
                ofere ajutorul profesional şi legal, în funcţie de specificul şi
                particularităţile problemei dumneavoastră. Birou de avocat Alina
                Wagner vă va oferi întotdeauna soluţia viabilă pentru rezolvarea
                problemelor dumneavoastră juridice.
              </p>
            </div>
          </div>
          <div>
            <p>
              Avocatul isi desfasoara activitatea avand ca principiu de baza
              confidentialitatea tuturor datelor, responsabilitatea si
              operativitatea, oferind tuturor clientilor respect si atentia
              cuvenita prin servicii competente in diferite domenii de drept.
            </p>
            <p>
              Una dintre specializările de bază este Dreptul Familiei. Soluţii
              optime pentru conflictele din familie; divorțuri prin acord comun
              și litigii, pensii alimente minori, custodie comună,adopţii,
              încălcarea acordurilor, neplata pensiilor, separarea bunurilor,
              tutelă, asistență de îngrijire; acţiuni legale de drept civil,
              precum şi dosare naţionalitate, omologări studii, documente
              rezidenţă, nie, reclamaţii salariale, declaraţii, asistenţă
              juridică şi reprezentarea în faţa Organelor de urmărire penală
              Comisariat şi Tribunal. Avocat roman Madrid Alcala de Henares.
            </p>
            <p>
              En el Despacho Jurídico de Alina Wagner Dogaru se garantiza que
              todos los intereses cuya defensa se nos encomiende, será
              personalmente dirigida y realizada por la titular del Despacho.
              Además pondremos a su disposición toda la información necesaria
              para la toma de decisiones en defensa de sus intereses.
            </p>
            <p>
              Finalmente queremos lograr que quién entre en nuestra página no
              considere o descarte pedir nuestra asistencia por el hecho de ser
              abogados de Alcalá de Henares, ya que trabajamos en toda España
              aparte de contar con numerosos colaboradores.
            </p>
            <Link to='/areas' className='btn-flat red white-text waves-effect'>
              especialidades
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Despacho;
