import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const BlogFooter = ({ post, pageNumber }) => {
  const [postDate, setPostDate] = useState('');
  useEffect(() => {
    let date = post.date.split('T');
    setPostDate(date[0]);
  }, [post]);
  return (
    <div>
      <img
        src={`${post.cover.relativePath}${post.cover.pseudoName}${post.cover.fileExtension}`}
        alt=''
      />
      <Link to={`/blog/pag/${pageNumber}/full/${post._id}`}>
        <p>{post.title}</p>
        <p>{postDate}</p>
      </Link>
    </div>
  );
};

export default BlogFooter;
