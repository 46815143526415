import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faTwitter,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import isEmpty from 'is-empty';

const BlogPost = ({ post, currentPage }) => {
  const [postDate, setPostDate] = useState('');

  useEffect(() => {
    let date = post.date.split('T');
    setPostDate(date[0]);
  }, [post.date]);

  return (
    <div className='blog-post-container'>
      <div>
        <p>{post.category}</p>
        <p>{post.title}</p>
        <div>
          <span />
          <p>{postDate}</p>
          <span />
        </div>
      </div>
      <div>
        {!isEmpty(post) && post.cover && (
          <img
            src={`${post.cover.relativePath}${post.cover.pseudoName}${post.cover.fileExtension}`}
            alt=''
          />
        )}
      </div>
      <div>
        <p>{post.description}</p>
        <div>
          <Link to={`/blog/pag/${currentPage}/full/${post._id}`}>
            continua leyendo
          </Link>
        </div>
      </div>
      <div>
        <span />
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=https://ajiverdeanu.es/blog/full/${post._id}`}
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a
          href={`https://twitter.com/intent/tweet?text={TITLE}&url=https://ajiverdeanu.es/blog/full/${post._id}`}
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=https://ajiverdeanu.es/blog/full/${post._id}`}
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a
          href={`mailto:?subject=${post.title}&body=https://ajiverdeanu.es/blog/full/${post._id}`}
        >
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
        <span />
      </div>
    </div>
  );
};

export default BlogPost;
