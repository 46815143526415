import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import MainContext from '../context/MainContext';

const Consulta = (props) => {
  const mainContext = useContext(MainContext);
  return (
    <div className='appointment-container'>
      <Helmet>
        <title>
          Video Consulta | Avocat Roman in Madrid Alina Wagner | Abogado Rumano
          en Madrid
        </title>
      </Helmet>
      <div className='row'>
        <div className='col s12 center'>
          <h4>
            VIDEO <span className='red-text'>CONSULTA</span>
          </h4>
        </div>
      </div>
      <div className='app-hero-wrapper'>
        <div>
          <img src={mainContext.consultaImages[0]} alt='laptop' />
        </div>
        <div>
          <div className='hero-price-card-wrapper'>
            <div>
              <img src={mainContext.logosImages[1]} alt='logo' />
            </div>
            <div>
              <span>
                Necesitas hablar con un abogado de forma urgente y sin moverte
                de casa?
              </span>
            </div>
            <div>
              <div>
                <span>NUEVO SERVICIO</span>
              </div>
              <div>
                <span>VIDEO</span>
                <span>CONSULTA</span>
              </div>
            </div>
            <div>
              <span>50€</span>
              <span>duracion 60 minutos</span>
            </div>
            <div>
              <Link to='/register'>Registrate ahora</Link>
              <p>Precio con IVA(21%) incluido. (precio sin IVA 41.32 euros)</p>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 use-dark-bg center'>
          <h6 className='white-text'>
            <span className='red-text text-darken-1'>MUY IMPORTANTE!</span>{' '}
            Recuerda que tu PC tiene que tener disponible una WEBCAM y MICROFONO
            para realizar la video consulta.
          </h6>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='row'>
            <div className='col s12 m8'>
              <h5>
                <strong>QUE ES UNA VIDEO-CONSULTA?</strong>
              </h5>
              <p>
                Nuestro servicio de video consulta, te permite contactar con
                nuestro despacho de forma mas rapida y confortable. Puedes hacer
                una video consulta directamente desde tu casa sin tener que
                acudir a nuestro despacho. Te podemos aclarar todas las dudas
                que tienes al instante, es como si el abogado estuviera en tu
                casa.
              </p>
            </div>
            <div className='col s12 m4'>
              <img
                src={mainContext.consultaImages[1]}
                className='responsive-img'
                alt='videochat-logo'
              />
            </div>
          </div>

          <div className='row'>
            <div className='col s12 m6'>
              <img
                src={mainContext.consultaImages[2]}
                className='responsive-img'
                alt='pay'
              />
            </div>
            <div className='col s12 m6'>
              <h5>
                <strong>COMO PUEDO COMPRAR UNA VIDEO-CONSULTA?</strong>
              </h5>
              <p>
                Es muy sencillo! Solo tienes que registrarte en nuestra web con
                tus datos. Una vez registrado recibiras un correo con un PIN
                para la confirmacion de tus datos y el correo. Una vez
                verificado, podras acceder a tu cuenta personal. Dentro de tu
                cuenta personal podras comprar una video consulta y siguiendo
                los pasos necesarios para la tramitacion y confirmacion de la
                consulta. Recuerda que tambien puedes comprar una consulta
                presencial si lo estimas oportuno. Ademas te ofrecemos la
                posibilidad de ver la evolucion de tu caso directamente desde tu
                cuenta. Puedes entrar y descargar los documentos que el despacho
                pone a tu disposicion.
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col s12'>
              <div className='steps-container'>
                <div>
                  <span>1</span>
                  <span>Registrate en nuesta web.</span>
                </div>
                <div>
                  <span>2</span>
                  <span>
                    Describre brevemente el motivo de la consulta y elige el
                    tipo de consulta.
                  </span>
                </div>
                <div>
                  <span>3</span>
                  <span>
                    Elige la fecha y la hora que mejor te viene para la video
                    consulta.
                  </span>
                </div>
                <div>
                  <span>4</span>
                  <span>
                    Realiza el pago y LISTO! Recibiras un correo con la
                    confirmacion del pago y los datos de la consulta.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col s12 m6'>
              <h5>
                <strong>CUANTO CUESTA UNA VIDEO CONSULTA?</strong>
              </h5>
              <p>
                Cada video consulta tiene un coste de 50 euros(IVA 21% Inc.) y
                tiene una duracion de 60 minutos.
              </p>
              <h5>
                <strong>QUE TIPOS DE PAGO ACEPTAIS?</strong>
              </h5>
              <p>
                Aceptamos el pago telematico con cualquier tipo de tarjetas de
                credito/debito VISA y MASTERCARD de todo el mundo.
              </p>
              <h5>
                <strong>ES SEGURO?</strong>
              </h5>
              <p>
                Nuestra plataforma digital, utiliza todas las tecnologias
                actuales para el proceso de pago seguro con Tarjeta. Hacemos uso
                de certificados SSL para la encriptacion del trafico de nuestra
                pagina web.
              </p>
              <h5>
                <strong>QUIEN PUEDE COMPRAR?</strong>
              </h5>
              <p>
                Este servicio esta dedicado a las persona mayores de 18 años. No
                nos hacemos responsable por el uso inadecuado o por el acceso a
                esta pagina web por personas que no cumplen estos requisitos.
                Mas informacion sobre los termiso de uso y la politica de
                privacidad <Link to='/legal'>aqui</Link>.
              </p>
            </div>
            <div className='col s12 m6'>
              <img
                src={mainContext.consultaImages[3]}
                className='responsive-img'
                alt='secured'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Consulta;
