import React from 'react';

const ProgressStep = (props) => {
  return (
    <div>
      <div className='payment-progress-container'>
        <div className='progress'>
          <div className='determinate blue' style={{ width: '100%' }} />
        </div>
        <div className='step-one'>
          <i className='material-icons medium blue-text'>assignment_ind</i>
          <span className='blue-text'>DETALLES</span>
        </div>
        <div className='step-two'>
          <i className='material-icons medium blue-text'>event_note</i>
          <span className='blue-text'>FECHA</span>
        </div>
        <div className='step-three'>
          <i className='material-icons medium blue-text'>payment</i>
          <span className='blue-text'>PAGO</span>
        </div>
        <div className='step-four'>
          <i className='material-icons medium blue-text'>check</i>
          <span className='blue-text'>CONFIRMACION</span>
        </div>
      </div>
    </div>
  );
};

export default ProgressStep;
