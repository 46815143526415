import React, { useState, useEffect, useCallback } from 'react';
import BlogPost from './BlogPost';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPostsByDateLimited } from '../../redux/actions/blogActions';
import Pagination from '../misc/Pagination';
import ScrollToTopBtn from '../misc/ScrollToTopBtn';
import { Helmet } from 'react-helmet';

const Blog = (props) => {
  const dispatch = useDispatch();
  const blog = useSelector((state) => state.blog);
  const [dbPosts, setDbPosts] = useState([]);
  const [dbPostsPagination, setDbPostsPagination] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [showGotoTop, setShowGotoTop] = useState(false);

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 400) {
      setShowGotoTop(true);
    } else {
      setShowGotoTop(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    dispatch(getAllPostsByDateLimited(100));
  }, [dispatch]);

  useEffect(() => {
    if (blog.postsByDateLimited.length !== 0) {
      setDbPosts(blog.postsByDateLimited);
      let indexOfLastPost = currentPage * postsPerPage;
      let indexOfFirstPost = indexOfLastPost - postsPerPage;
      let currentPosts = dbPosts.slice(indexOfFirstPost, indexOfLastPost);
      setDbPostsPagination(currentPosts);
    }
  }, [blog, currentPage, dbPosts, postsPerPage]);

  const paginate = useCallback(
    (pageNumber) => {
      setCurrentPage(pageNumber);
      dispatch(getAllPostsByDateLimited(100));
    },
    [dispatch]
  );

  return (
    <div className='blog-container'>
      <Helmet>
        <title>
          Blog | Avocat Roman in Madrid Alina Wagner | Abogado Rumano en Madrid
        </title>
      </Helmet>
      {dbPostsPagination.length !== 0 &&
        dbPostsPagination.map((post) => {
          return (
            <BlogPost post={post} key={post._id} currentPage={currentPage} />
          );
        })}
      <div className='row'>
        <div className='col s12'>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={dbPosts.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      </div>
      {showGotoTop && <ScrollToTopBtn />}
    </div>
  );
};
export default Blog;
