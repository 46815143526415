import React from 'react';
import { convertFromRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

const ReadOnlyPost = ({ storedState }) => {
  const storedS = convertFromRaw(JSON.parse(storedState));
  const content = EditorState.createWithContent(storedS);

  return (
    <>
      <Editor toolbarHidden editorState={content} readOnly={true} />
    </>
  );
};

export default ReadOnlyPost;
