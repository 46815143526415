import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faTwitter,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPostById,
  getAllPostsByCategory,
} from '../../redux/actions/blogActions';
import isEmpty from 'is-empty';
import ScrollToTopBtn from '../misc/ScrollToTopBtn';
import ReadOnlyPost from './ReadOnlyPost';
import { Link } from 'react-router-dom';
import BlogFooter from './BlogFooter';
import { Helmet } from 'react-helmet';

const BlogFullPost = (props) => {
  const dispatch = useDispatch();
  const blog = useSelector((state) => state.blog);
  const [showGotoTop, setShowGotoTop] = useState(false);
  const [post, setPost] = useState({});
  const [postDate, setPostDate] = useState('');
  const [sameCatPosts, setSameCatPosts] = useState([]);

  const metaTagsREF = useRef();

  useEffect(() => {
    dispatch(getPostById(props.match.params.postID));
  }, [dispatch, props]);

  useEffect(() => {
    if (!isEmpty(blog.post)) {
      setPost(blog.post);
      let date = blog.post.date.split('T');
      setPostDate(date[0]);
      dispatch(getAllPostsByCategory(blog.post.category, 3));
      metaTagsREF.current = blog.post.tags.map((t) => t).join(',');
    }
  }, [blog.post, dispatch]);

  useEffect(() => {
    if (blog.postsByDateLimited.length !== 0) {
      setSameCatPosts(blog.postsByDateLimited);
    }
  }, [blog.postsByDateLimited, dispatch]);

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 400) {
      setShowGotoTop(true);
    } else {
      setShowGotoTop(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <div className='blog-full-post'>
      <Helmet>
        <meta name='description' content={post.description} />
        <title>{post.title}</title>
        <meta name='keywords' content={metaTagsREF.current} />
      </Helmet>
      <div className='blog-full-title'>
        <div>
          <p>
            <Link
              to={`/blog/pag/${props.match.params.pagID}`}
              className='grey-text'
            >
              volver
            </Link>
            {` / ${post.category}`}
          </p>
          <p>{post.title}</p>
          <div>
            <span />
            <span>{postDate}</span>
            <span />
          </div>
        </div>
      </div>
      <div className='blog-full-content'>
        <div>
          {!isEmpty(post) && (
            <img
              src={`${post.cover.relativePath}${post.cover.pseudoName}${post.cover.fileExtension}`}
              alt=''
            />
          )}
        </div>
        <div>
          <div>
            <Link to='/consultas'>VIDEO-CONSULTA</Link>
          </div>
          <div>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=https://ajiverdeanu.es/blog/full/${post._id}`}
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              href={`https://twitter.com/intent/tweet?text={TITLE}&url=https://ajiverdeanu.es/blog/full/${post._id}`}
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              href={`https://www.linkedin.com/shareArticle?mini=true&url=https://ajiverdeanu.es/blog/full/${post._id}`}
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a
              href={`mailto:?subject=${post.title}&body=https://ajiverdeanu.es/blog/full/${post._id}`}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </div>
        </div>
        <div>
          {!isEmpty(post) && <ReadOnlyPost storedState={post.content} />}
        </div>
        <div>
          {!isEmpty(post) &&
            post.tags.map((tag) => (
              <Link to='/' key={tag}>
                {tag}
              </Link>
            ))}
        </div>
      </div>
      <div className='blog-full-footer'>
        <div className='blog-full-footer-header'>
          <span />
          <span>Te puede interesar</span>
          <span />
        </div>
        <div className='blog-full-footer-content'>
          {sameCatPosts.length !== 0 &&
            sameCatPosts.map((p) => (
              <BlogFooter
                key={p._id}
                post={p}
                pageNumber={props.match.params.pagID}
              />
            ))}
        </div>
      </div>
      {showGotoTop && <ScrollToTopBtn />}
    </div>
  );
};
export default BlogFullPost;
