import React from 'react';
import { Link } from 'react-router-dom';

const Familia = () => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col s12 center'>
          <h6>
            <strong>DERECHO DE FAMILIA</strong>
          </h6>
          <p>
            <strong>
              Letrada especialista en derecho de familia en Alcalá de Henares.
            </strong>
          </p>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 m6'>
          <div className='areas-items'>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Divorcio contencioso</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>{`Separación matrimonial o divorcio de común acuerdo (con o sin hijos y con liquidación de bienes)`}</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Nulidades matrimoniales civiles</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Liquidación de sociedades de gananciales</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Capitulaciones matrimoniales</span>
              </label>
            </p>
            <p>
              <label>
                <input type='checkbox' checked={true} readOnly />
                <span>Conflictos de parejas de hecho</span>
              </label>
            </p>
          </div>
        </div>
        <div className='col s12 m6'>
          <p>
            Te asesoramos de la mejor opción para tu caso en particular Los
            abogados de familia somos profesionales cuyo trabajo es asesorar
            jurídicamente a todas aquellas personas que requieran de nuestros
            servicios técnicos, relacionados con el entorno parental.
          </p>
          <h5>Qué es el derecho de familia</h5>
          <p>
            Primeramente, debes saber en qué consiste esta rama del Derecho. Es
            un conjunto de normas jurídicas que se encargan de regular las
            relaciones propias de cada persona, así como las patrimoniales,
            entre los componentes de una familia.
          </p>
        </div>
      </div>
      <div className='col s12'>
        <p>
          Estas normas tienen como objetivo la solución o prevención de
          problemas que, habitualmente, surgen entre los cónyuges o personas que
          conviven.
        </p>
        <p>
          Somos los especialistas en separaciones que estás buscando, para
          asesorarte de forma personalizada sobre los distintos modelos de
          divorcio y las opciones que tienes. Contamos con profesionales
          expertos en el derecho matrimonial. Así pues, en caso de que quieras
          poner fin a tu matrimonio, no dudes en acudir para que te ayudemos a
          negociar un acuerdo beneficioso.
        </p>
      </div>
      <div className='row'>
        <div className='col s12 center'>
          <h5>
            Si necesitas una respuesta efectiva a tu divorcio, contratar los
            servicios de un abogado especializado en derecho de familia. Que sea
            capaz de ofrecerte una solución integral a tu separación, es el
            primer paso para conseguirlo.
          </h5>
          <Link
            to='/contacto'
            className='btn-flat red white-text darken-1 waves-effect'>
            NECESITO MAS INFORMACION
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Familia;
