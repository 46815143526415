import React, { useState, useCallback, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserGraduate,
  faBriefcase,
  faGavel,
  faPassport,
  faUniversity,
} from '@fortawesome/free-solid-svg-icons';
import Laboral from './AreasTabs/Laboral';
import Civil from './AreasTabs/Civil';
import Penal from './AreasTabs/Penal';
import Familia from './AreasTabs/Familia';
import Extranjeria from './AreasTabs/Extranjeria';
import { Helmet } from 'react-helmet';
import MainContext from '../context/MainContext';

const Areas = (props) => {
  const mainContext = useContext(MainContext);
  const [showLaboral, setShowLaboral] = useState(true);
  const [showCivil, setShowCivil] = useState(false);
  const [showPenal, setShowPenal] = useState(false);
  const [showFamilia, setShowFamilia] = useState(false);
  const [showExtranjeria, setShowExtranjeria] = useState(false);

  const handleTabSelection = useCallback((e, tab) => {
    switch (tab) {
      case 'laboral':
        setShowLaboral(true);
        setShowCivil(false);
        setShowPenal(false);
        setShowFamilia(false);
        setShowExtranjeria(false);
        break;
      case 'civil':
        setShowLaboral(false);
        setShowCivil(true);
        setShowPenal(false);
        setShowFamilia(false);
        setShowExtranjeria(false);
        break;
      case 'penal':
        setShowLaboral(false);
        setShowCivil(false);
        setShowPenal(true);
        setShowFamilia(false);
        setShowExtranjeria(false);
        break;
      case 'familia':
        setShowLaboral(false);
        setShowCivil(false);
        setShowPenal(false);
        setShowFamilia(true);
        setShowExtranjeria(false);
        break;
      case 'extranjeria':
        setShowLaboral(false);
        setShowCivil(false);
        setShowPenal(false);
        setShowFamilia(false);
        setShowExtranjeria(true);
        break;
      default:
        break;
    }
  }, []);

  const handleTabRender = useCallback(() => {
    let component = null;

    if (showLaboral) {
      component = <Laboral />;
    } else if (showCivil) {
      component = <Civil />;
    } else if (showPenal) {
      component = <Penal />;
    } else if (showFamilia) {
      component = <Familia />;
    }
    if (showExtranjeria) {
      component = <Extranjeria />;
    }
    return component;
  }, [showLaboral, showCivil, showPenal, showFamilia, showExtranjeria]);
  return (
    <div className='areas-container'>
      <Helmet>
        <title>
          Areas | Avocat Roman in Madrid Alina Wagner | Abogado Rumano en Madrid
        </title>
      </Helmet>
      <div className='areas-header'>
        <img src={mainContext.areasImages[0]} alt='zamora' />
      </div>
      <div className='areas-tabs-container'>
        <div className='areas-tabs-title'>
          <h5>areas de derecho</h5>
          <span>
            {`"Todos los seres humanos nacen libres e iguales en dignidad
                      y derechos y, dotados como están de razón y conciencia,
                      deben comportarse fraternalmente los unos con los otros." - Artículo 1 DH`}
          </span>
        </div>
        <div className='areas-tabs-wrapper'>
          <button onClick={(e) => handleTabSelection(e, 'laboral')}>
            <FontAwesomeIcon icon={faBriefcase} />
            <span>LABORAL</span>
          </button>
          <button onClick={(e) => handleTabSelection(e, 'civil')}>
            <FontAwesomeIcon icon={faUniversity} />
            <span>CIVIL</span>
          </button>
          <button onClick={(e) => handleTabSelection(e, 'penal')}>
            <FontAwesomeIcon icon={faGavel} />
            <span>PENAL</span>
          </button>
          <button onClick={(e) => handleTabSelection(e, 'familia')}>
            <FontAwesomeIcon icon={faUserGraduate} />
            <span>FAMILIA</span>
          </button>
          <button onClick={(e) => handleTabSelection(e, 'extranjeria')}>
            <FontAwesomeIcon icon={faPassport} />
            <span>EXTRANJERIA</span>
          </button>
        </div>
        <div className='areas-tabs-content'>{handleTabRender()}</div>
      </div>
    </div>
  );
};

export default Areas;
