import {
  SEND_NEW_CONTACT_MESSAGE,
  NEW_CONTACT_MESSAGE_SENDING,
  GET_ALL_CONTACTS,
} from '../actions/types';

const initialState = {
  message: {},
  sendingMessage: false,
  contacts: [],
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_NEW_CONTACT_MESSAGE:
      return {
        ...state,
        message: action.payload,
        sendingMessage: false,
      };
    case NEW_CONTACT_MESSAGE_SENDING:
      return {
        ...state,
        sendingMessage: true,
      };
    case GET_ALL_CONTACTS:
      return {
        ...state,
        sendingMessage: false,
        contacts: action.payload,
      };
    default:
      return state;
  }
};
export default contactReducer;
