import React, { useContext, useEffect, useRef } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Sidenav from './Sidenav';
import M from 'materialize-css';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../redux/actions/authActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import AuthModal from '../auth/AuthModal';
import EmailVerificationModal from '../auth/EmailVerificationModal';
import MainContext from '../context/MainContext';

const Navbar = (props) => {
  const mainContext = useContext(MainContext);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const modalCodeRef = useRef();
  const modalAuthRef = useRef();

  useEffect(() => {
    const navbar = document.querySelectorAll('.sidenav');
    M.Sidenav.init(navbar, {});
    const modal = document.querySelectorAll('.modal');
    M.Modal.init(modal, {});
    const verifyEmail = document.querySelector('#verifyEmailModal');
    modalCodeRef.current = M.Modal.getInstance(verifyEmail);
    const authEmail = document.querySelector('#loginModal');
    modalAuthRef.current = M.Modal.getInstance(authEmail);
  }, []);

  useEffect(() => {
    if (auth.requireVerification) {
      modalCodeRef.current.open();
      modalAuthRef.current.close();
    }
  }, [auth.requireVerification]);

  useEffect(() => {
    if (auth.verified) {
      modalCodeRef.current.destroy();
      modalAuthRef.current.open();
    }
  }, [auth.verified]);

  const logOut = (e) => {
    e.preventDefault();
    dispatch(logoutUser());
  };
  const adminLinks = (
    <React.Fragment>
      <nav className='white'>
        <div className='nav-wrapper'>
          <Link to='/' className='brand-logo main-logo'>
            <img src={mainContext.logosImages[0]} alt='wagner-logo' />
          </Link>
          <a href='!#' data-target='mobile' className='sidenav-trigger'>
            <i className='material-icons red-text'>menu</i>
          </a>
          <ul className='right hide-on-med-and-down'>
            <li>
              <NavLink
                exact
                to='/admin/dashboard'
                activeClassName='active-nav-link'
              >
                <span className='black-text navbar-top-menu'>TABLERO</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to='/admin/clients'
                activeClassName='active-nav-link'
              >
                <span className='black-text navbar-top-menu'>CLIENTES</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to='/admin/pagos'
                activeClassName='active-nav-link'
              >
                <span className='black-text navbar-top-menu'>PAGOS</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to='/admin/consultas'
                activeClassName='active-nav-link'
              >
                <span className='black-text navbar-top-menu'>CONSULTAS</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to='/admin/blog-admin'
                activeClassName='active-nav-link'
              >
                <span className='black-text navbar-top-menu'>BLOG</span>
              </NavLink>
            </li>
            <li>
              <a href='#!'>
                <span className='black-text navbar-top-menu' onClick={logOut}>
                  SALIR
                </span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <Sidenav id='mobile' />
    </React.Fragment>
  );
  const userLinks = (
    <React.Fragment>
      <nav className='white'>
        <div className='nav-wrapper'>
          <Link to='/' className='brand-logo main-logo'>
            <img src={mainContext.logosImages[0]} alt='' />
          </Link>
          <a href='!#' data-target='mobile' className='sidenav-trigger'>
            <i className='material-icons red-text'>menu</i>
          </a>
          <ul className='right hide-on-med-and-down'>
            <li>
              <NavLink exact to='/dashboard' activeClassName='active-nav-link'>
                <span className='black-text navbar-top-menu'>CUENTA</span>
              </NavLink>
            </li>
            <li>
              <a href='#!'>
                <span className='black-text navbar-top-menu' onClick={logOut}>
                  SALIR
                </span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <Sidenav id='mobile' />
    </React.Fragment>
  );

  const guestLinks = (
    <React.Fragment>
      <nav className='white'>
        <div className='nav-wrapper'>
          <Link to='/' className='brand-logo main-logo'>
            <img src={mainContext.logosImages[0]} alt='' />
          </Link>
          <a href='!#' data-target='mobile' className='sidenav-trigger'>
            <i className='material-icons red-text'>menu</i>
          </a>
          <ul className='right hide-on-med-and-down'>
            <li>
              <NavLink exact to='/' activeClassName='active-nav-link'>
                <span className='black-text navbar-top-menu'>INICIO</span>
              </NavLink>
            </li>
            <li>
              <NavLink exact to='/despacho' activeClassName='active-nav-link'>
                <span className='black-text navbar-top-menu'>DESPACHO</span>
              </NavLink>
            </li>
            <li>
              <NavLink exact to='/areas' activeClassName='active-nav-link'>
                <span className='black-text navbar-top-menu'>AREAS</span>
              </NavLink>
            </li>
            <li>
              <NavLink exact to='/blog/pag/1' activeClassName='active-nav-link'>
                <span className='black-text navbar-top-menu'>BLOG</span>
              </NavLink>
            </li>
            <li>
              <NavLink exact to='/consultas' activeClassName='active-nav-link'>
                <span className='black-text navbar-top-menu'>CONSULTAS</span>
              </NavLink>
            </li>
            <li>
              <NavLink exact to='/contacto' activeClassName='active-nav-link'>
                <span className='black-text navbar-top-menu'>CONTACTO</span>
              </NavLink>
            </li>
            <li>
              <Link
                to='/contacto'
                className='btn-flat waves-effect waves-red hover-phone'
                style={{ border: '1px solid black' }}
              >
                {'(+34) 642 767 633'}
              </Link>
            </li>
            <li
              style={{
                borderLeftColor: 'lightgrey',
                borderLeftWidth: '0.2px',
                borderLeftStyle: 'solid',
              }}
            >
              <button
                className='black-text modal-trigger btn-flat hover-acc login-btn-custom'
                data-target='loginModal'
                style={{ marginLeft: '10px', marginRight: '10px' }}
              >
                <FontAwesomeIcon icon={faUser} size='lg' />
                Entrar
              </button>
            </li>
          </ul>
        </div>
      </nav>
      <Sidenav id='mobile' />
    </React.Fragment>
  );
  return (
    <>
      <div className='navbar-wrapper navbar-fixed'>
        {auth.isAuthenticated
          ? auth.user.isAdmin
            ? adminLinks
            : userLinks
          : guestLinks}
      </div>
      <AuthModal modalID='loginModal' />
      <EmailVerificationModal modalID='verifyEmailModal' />
    </>
  );
};
export default Navbar;
