import React from 'react';

const Cookies = () => {
  return (
    <div className='container'>
      <h4>Politica de Cookies</h4>
      <p>{`El objetivo de esta política es informar a los interesados de las cookies que emplea esta página web, de conformidad con lo establecido en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, y el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016.`}</p>
      <div>
        <ul>
          <li>
            <h5>{`Uso de cookies. ¿Qué son las cookies?`}</h5>
            <p>{`Las cookies son ficheros que se descargan en su Ordenador, Smartphone o Tablet al acceder a determinadas páginas web. La utilización de cookies, ofrece numerosas ventajas en la prestación de servicios de la Sociedad de la Información, puesto que, entre otras: (a) facilita la navegación del usuario en el Sitio Web; (b) facilita al usuario el acceso a los diferentes servicios que ofrece el Sitio Web; (c) evita al usuario volver a configurar características generales predefinidas cada vez que accede al Sitio Web; (d) favorece la mejora del funcionamiento y de los servicios prestados a través del Sitio Web, tras el correspondiente análisis de la información obtenida a través de las cookies instaladas; (e) permiten a un Sitio Web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.

La mayoría de los navegadores aceptan como estándar a las cookies y, con independencia de las mismas, permiten o impiden en los ajustes de seguridad  las cookies temporales o memorizadas.

Ten en cuenta que para poder utilizar y contar con una mejor experiencia de navegación,  es necesario que tengas habilitadas las cookies, especialmente aquellas de carácter técnico que resultan necesarias para que pueda identificarte como usuario registrado cada vez que accedas a la presente web.

En caso de no querer recibir cookies, por favor, configure su navegador de internet, para que las borre del disco duro de su ordenador, las bloquee o le avise en su caso de instalación de las mismas.`}</p>
          </li>
          <li>
            <h5>{`¿Qué tipos de cookies utiliza esta página web?`}</h5>
            <h6>{`Cookies utilizadas en este sitio web.`}</h6>
            <p>{`Siguiendo las directrices de la Agencia Española de Protección de Datos, procedemos a detallar el uso de las cookies que esta página web emplea,  con el fin de proporcionarle la máxima información posible.`}</p>
            <h6>{`Cookies Propias:`}</h6>
            <p>{`Son aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.`}</p>
            <p>{`A continuación la lista de las que utilizamos:`}</p>
          </li>
          <li>
            <table className='centered'>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Tipo</th>
                  <th>Descripcion</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>AJ_SES_SID</td>
                  <td>Persistentes</td>
                  <td>{`Quedan permanentemente instaladas en tu navegador y son activadas cada vez que visitas el site, siempre y cuando no se desactive su uso.`}</td>
                </tr>
              </tbody>
            </table>
          </li>
          <li>
            <h6>{`Cookies de Terceros:`}</h6>
            <p>
              {`Son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.

Está página Web utiliza Google Analytics.

Google Analytics es una herramienta gratuita de análisis web de Google que principalmente permite que los propietarios de sitios web conozcan cómo interactúan los usuarios con su sitio web. Asimismo, habilita cookies en el dominio del sitio en el que te encuentras y utiliza un conjunto de cookies denominadas "__utma" y "__utmz" para recopilar información de forma anónima y elaborar informes de tendencias de sitios web sin identificar a usuarios individuales.

Para realizar las estadísticas de uso de esta Web utilizamos las cookies con la finalidad de conocer el nivel de recurrencia de nuestros visitantes y los contenidos que resultan más interesantes. De esta manera podemos concentrar nuestros esfuerzos en mejorar las áreas más visitadas y hacer que el usuario encuentre más fácilmente lo que busca. En esta Web puede utilizarse la información de su visita para realizar evaluaciones y cálculos estadísticos sobre datos anónimos, así como para garantizar la continuidad del servicio o para realizar mejoras en sus sitios Web. Para más detalles, consulte en el siguiente enlace la política de privacidad `}
              <a href='http://www.google.com/intl/es/policies/privacy/'>{`www.google.com/intl/es/policies/privacy/`}</a>
            </p>
          </li>
          <li>
            <h6>{`ALINA JIVERDEANU no se hace responsable, en ningún caso ni del contenido ni de la veracidad de las políticas y/o condiciones de uso y privacidad de los terceros, incluidos, a través de los enlaces, en esta política.`}</h6>
          </li>
          <li>
            <h5>{`Consentimiento`}</h5>
            <p>{`En relación con la utilización de cookies de este sitio web descritos en el apartado anterior, el usuario autoriza y consiente su uso de la siguiente forma: Cuando el usuario accede a cualquier página de la web, verá un aviso donde se indica que la página web de ALINA JIVERDEANU utiliza cookies, pudiendo el usuario aceptar o rechazar el uso de las mismas a través de la configuración de su navegador. Si el usuario no configura su navegador para que las cookies no se activen, al navegar por el sitio web de ALINA JIVERDEANU y utilizar sus servicios, el usuario acepta el uso que se hace de las cookies.`}</p>
          </li>
          <li>
            <h5>{`Cómo bloquear o eliminar las cookies instaladas`}</h5>
            <p>{`Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones de su navegador. Puede encontrar información sobre cómo hacerlo, en relación con los navegadores más comunes en los links que se incluyen a continuación:`}</p>
            <p>
              {`Explorer: `}
              <a href='https://support.microsoft.com/es-es/kb/278835'>
                {`https://support.microsoft.com/es-es/kb/278835`}
              </a>
            </p>

            <p>
              {`Microsoft Edge: `}
              <a href='https://privacy.microsoft.com/es-es/windows-10-microsoft-edge-and-privacy'>
                {`https://privacy.microsoft.com/es-es/windows-10-microsoft-edge-and-privacy`}
              </a>
            </p>
            <p>
              {`Chrome: `}
              <a
                href={`https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=esttp://support.google.com/chrome/bin/answer.py?hl=es&answer=95647`}>
                {`https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=esttp://support.google.com/chrome/bin/answer.py?hl=es&answer=95647`}
              </a>
            </p>
            <p>
              {`Firefox: `}
              <a href='http://support.mozilla.org/es/kb/Borrar%20cookies'>
                {`http://support.mozilla.org/es/kb/Borrar%20cookies`}
              </a>
            </p>
            <p>
              {`Safari: `}
              <a href='http://support.apple.com/kb/ph5042.'>
                {`http://support.apple.com/kb/ph5042.`}
              </a>
            </p>
            <p>{`Le informamos, no obstante, de la posibilidad de que la desactivación de alguna cookie impida o dificulte la navegación o la prestación de los servicios ofrecidos en la página web.`}</p>
          </li>
          <li>
            <h5>{`Modificaciones`}</h5>
            <p>{`La presente política de cookies puede verse modificada en función de las exigencias legales establecidas o con la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de Protección de Datos.`}</p>
            <p>{`Por esta razón, aconsejamos a los usuarios que  visiten periódicamente nuestra política de cookies.`}</p>
            <p>{`Cuando se produzcan cambios significativos en esta política, en la medida de nuestras posibilidades, comunicaremos a los usuarios estos cambios mediante un aviso en nuestra página web.`}</p>
            <p>
              {`Si tiene dudas acerca de esta política de cookies, puede contactar con ALINA JIVERDEANU a través del siguiente correo electrónico `}{' '}
              <a href='mailto:abogada@ajiverdeanu.es'>abogada@jiverdeanu.es</a>
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Cookies;
