import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import M from 'materialize-css';
import UploadPic from './UploadPic';
import { uploadPostPic } from '../../../../redux/actions/uploadActions';
import { addPost, publishPost } from '../../../../redux/actions/blogActions';
import isEmpty from 'is-empty';

const CreatePostTab = ({ exitEditMode, isEditMode }) => {
  const dispatch = useDispatch();
  const blog = useSelector((state) => state.blog);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [selectedCategory, setSelectedCategory] = useState('Elige categoria');
  const [postName, setPostName] = useState('');
  const [postDescription, setPostDescription] = useState('');
  const [postTags, setPostTags] = useState([]);
  const [postTag, setPostTag] = useState('');
  const [postID, setPostID] = useState('');
  const [postCover, setPostCover] = useState('');
  const [categories] = useState([
    'CIVIL',
    'LABORAL',
    'LEGAL',
    'FAMILIA',
    'PENAL',
    'EXTRANJERIA',
    'OTROS',
  ]);

  useEffect(() => {
    if (!isEmpty(blog.post)) {
      setPostName(blog.post.title);
      setPostDescription(blog.post.description);
      setSelectedCategory(blog.post.category);
      setPostTags(blog.post.tags);
      setPostID(blog.post._id);
      if (!isEmpty(blog.post.content)) {
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(blog.post.content))
          )
        );
      }
      if (blog.post.cover) {
        setPostCover(
          `${blog.post.cover.relativePath}${blog.post.cover.pseudoName}${blog.post.cover.fileExtension}`
        );
      }
      setTimeout(() => {
        const select = document.querySelectorAll('select');
        M.FormSelect.init(select, {});
      }, 300);
    }
  }, [blog.post]);

  useEffect(() => {
    setTimeout(() => {
      M.updateTextFields();
    }, 300);
    const select = document.querySelectorAll('select');
    M.FormSelect.init(select, {});
  }, []);

  const onEditorStateChange = useCallback((state) => {
    setEditorState(state);
  }, []);

  const handleDraftData = useCallback(() => {
    let rawData = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    const data = {
      postID,
      postContent: rawData,
    };
    dispatch(publishPost(data));
    exitEditMode();
  }, [editorState, dispatch, postID, exitEditMode]);

  const handleTextChange = useCallback((e, field) => {
    switch (field) {
      case 'title':
        setPostName(e.target.value);
        break;
      case 'description':
        setPostDescription(e.target.value);
        break;
      case 'category':
        setSelectedCategory(e.target.value);
        break;
      case 'tag':
        setPostTag(e.target.value);
        break;
      default:
        break;
    }
  }, []);

  const addTag = useCallback(() => {
    let tags = [...postTags, postTag];
    setPostTags([...new Set(tags)]);
    setPostTag('');
  }, [postTags, postTag]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        let tags = [...postTags, postTag];
        setPostTags([...new Set(tags)]);
        setPostTag('');
      }
    },
    [postTags, postTag]
  );
  const handleTagRemoval = useCallback(
    (tag) => {
      let updatedTags = postTags.filter(
        (t) => t.toLowerCase() !== tag.toLowerCase()
      );
      setPostTags(updatedTags);
    },
    [postTags]
  );

  const handlePostPic = useCallback(
    (e, draggedFile) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append('file', draggedFile);
      formData.append('additionalData', JSON.stringify({ postID }));
      dispatch(uploadPostPic(formData));
    },
    [dispatch, postID]
  );
  const createNewPost = useCallback(() => {
    const data = {
      title: postName,
      description: postDescription,
      category:
        selectedCategory === 'Elige categoria' ? 'OTROS' : selectedCategory,
      tags: postTags,
      postID,
    };
    dispatch(addPost(data));
  }, [dispatch, postName, postDescription, selectedCategory, postTags, postID]);

  return (
    <div className='tab-admin-new'>
      <div className='row'>
        <div className='col s12 center'>
          <h5 className='use-oswald-font'>Paso 1</h5>
          <p>Completa los datos de la publicacion</p>
        </div>
      </div>
      <div className='row'>
        <div className='input-field col s12 m4 offset-m4'>
          <input
            type='text'
            name='newPost_title'
            id='newPost_title'
            className='validate'
            value={postName}
            onChange={(e) => handleTextChange(e, 'title')}
          />
          <label htmlFor='newPost_title' className='active'>
            Nombre
          </label>
        </div>
        <div className='input-field col s12 m4 offset-m4'>
          <textarea
            name='newPost_desc'
            id='newPost_desc'
            className='validate materialize-textarea'
            value={postDescription}
            onChange={(e) => handleTextChange(e, 'description')}
          />
          <label htmlFor='newPost_desc' className='active'>
            Descripcion
          </label>
        </div>
        <div className='input-field col s12 m4 offset-m4'>
          <select onChange={(e) => handleTextChange(e, 'category')}>
            <option value={selectedCategory}>{selectedCategory}</option>
            {categories.map((cat) => (
              <option key={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>
          <label>Categoria</label>
        </div>
        <div className='input-field col s12 m4 offset-m4'>
          <input
            type='text'
            name='newPost_tags'
            id='newPost_tags'
            className='validate'
            value={postTag}
            onChange={(e) => handleTextChange(e, 'tag')}
            onKeyPress={handleKeyPress}
          />
          <label htmlFor='newPost_tags' className='active'>
            Tags
          </label>
          <button className='btn-flat input-inside-btn' onClick={addTag}>
            <i className='material-icons'>add</i>
          </button>
        </div>
        <div className='col s12 m4 offset-m4 chip-container'>
          {postTags.length !== 0 &&
            postTags.map((tag) => (
              <button
                key={tag}
                className='custom-chip'
                onClick={() => handleTagRemoval(tag)}
              >
                <span>{tag}</span>
                <i className='material-icons'>close</i>
              </button>
            ))}
        </div>
        <div className='row'>
          <div className='col s12 m4 offset-m4 center'>
            <button
              className='btn-flat blue white-text'
              onClick={createNewPost}
            >
              guardar
            </button>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 center'>
          <h5 className='use-oswald-font'>Paso 2</h5>
          <p>Seleccionar la imagen de la publicacion</p>
        </div>
        <div className='col s12 m6 offset-m3'>
          <UploadPic savePostPicture={handlePostPic} />
        </div>
      </div>
      {!isEmpty(postCover) && (
        <div className='row'>
          <div className='post-img-container'>
            <img src={postCover} alt='cover' height='400px' width='400px' />
          </div>
        </div>
      )}
      <div className='row'>
        <div className='col s12 center'>
          <h5 className='use-oswald-font'>Paso 3</h5>
          <p>Introducir el contenido de la publicacion</p>
        </div>
      </div>
      <div className='editor-container'>
        <Editor
          editorState={editorState}
          toolbarClassName='toolbarClassName'
          wrapperClassName='wrapperClassName'
          editorClassName='editorClassName'
          toolbar={{
            options: [
              'colorPicker',
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'link',
              'embedded',
              'emoji',
              'image',
              'remove',
              'history',
            ],
          }}
          onEditorStateChange={onEditorStateChange}
          localization={{ locale: 'es' }}
          hashtag={{
            separator: ' ',
            trigger: '#',
          }}
        />
      </div>
      <div className='row'>
        <div className='col s12 center'>
          <h5 className='use-oswald-font'>Paso 4</h5>
          <p>
            Revisa la publicacion y pulsa PUBLICAR para publicarla en el blog.
          </p>
          <button
            className='btn-flat blue white-text'
            onClick={handleDraftData}
          >
            publicar
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreatePostTab;
