import React, { useEffect, useState } from 'react';
import M from 'materialize-css';
import PaymentProgress from './progress/PaymentProgress';
import StepsContainer from './steps/StepsContainer';

const NewVideoMeeting = (props) => {
  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    const datePicker = document.querySelectorAll('.datepicker');
    M.Datepicker.init(datePicker, {
      autoClose: true,
      firstDay: 1,
      i18n: {
        cancel: 'cancelar',
        clear: 'limpiar',
        months: [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre',
        ],
        monthsShort: [
          'Ene',
          'Feb',
          'Mar',
          'Abr',
          'May',
          'Jun',
          'Jul',
          'Ago',
          'Sep',
          'Oct',
          'Nov',
          'Dic',
        ],
        weekdays: [
          'Lunes',
          'Martes',
          'Miercoles',
          'Jueves',
          'Viernes',
          'Sabado',
          'Domingo',
        ],
        weekdaysShort: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
        weekdaysAbbrev: ['L', 'M', 'X', 'J', 'V', 'S', 'D'],
      },
      format: 'dd/mm/yyyy',
      minDate: new Date(),
    });
  }, []);

  const handleActiveStep = (step) => {
    switch (step) {
      case '1':
        setActiveStep(1);
        break;
      case '2':
        setActiveStep(2);
        break;
      case '3':
        setActiveStep(3);
        break;
      case '4':
        setActiveStep(4);
        break;
      default:
        break;
    }
  };
  return (
    <div>
      <div className='buy-title center'>
        <h5>Nueva Consulta</h5>
      </div>
      <PaymentProgress activeStep={activeStep} />
      <div className='buy-steps-container container'>
        <StepsContainer
          activeStep={activeStep}
          handleActiveStep={handleActiveStep}
        />
      </div>
    </div>
  );
};

export default NewVideoMeeting;
