import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='container'>
      <div className='center'>
        <h1 className='grey-text'>Esta pagina no existe!</h1>
        <h5 className='grey-text'>{`Lo sentimos pero la pagina que busca no existe :(`}</h5>
        <Link to='/'>
          <h4 className='red-text'>Volver a la pagina principal</h4>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
