import React, { useEffect } from 'react';
import M from 'materialize-css';
import Client from './Client';
import Contact from './Contact';

const SearchContainer = ({ clients, contacts, activeFilter, delContact }) => {
  useEffect(() => {
    const collapsible = document.querySelectorAll('.collapsible');
    M.Collapsible.init(collapsible, {});
  }, []);
  return (
    <div className='clients-table-wrapper'>
      <div className='clients-table'>
        <ul className='collapsible popout'>
          {activeFilter === 'cliente'
            ? clients.map((client) => (
                <Client key={client._id} userInfo={client} />
              ))
            : activeFilter === 'contacto'
            ? contacts.map((contact) => (
                <Contact
                  key={contact._id}
                  contactInfo={contact}
                  delContact={delContact}
                />
              ))
            : null}
        </ul>
      </div>
    </div>
  );
};

export default SearchContainer;
