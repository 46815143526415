import React, { useEffect, useState, useCallback } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import visa from '../../../assets/logo/visa_logo.png';
import masterCard from '../../../assets/logo/mc_logo.png';
import stripeLogo from '../../../assets/logo/powered_by_stripe_logo.png';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'is-empty';
import { getCurrentProfile } from '../../../redux/actions/profileActions';
import M from 'materialize-css';
import {
  createNewAppointment,
  createAppointment,
} from '../../../redux/actions/appointmentActions';

const Step3 = ({ handleActiveStep }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const appointment = useSelector((state) => state.appointment);
  const payment = useSelector((state) => state.payment);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [tosAgreed, setTosAgreed] = useState(false);
  const [allowBuyClick, setAllowBuyClick] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      M.updateTextFields();
    }, 300);
  }, []);

  useEffect(() => {
    if (isEmpty(profile.profile)) {
      dispatch(getCurrentProfile());
    }
    if (!isEmpty(profile.profile)) {
      setFullName(`${profile.profile.firstName} ${profile.profile.lastName}`);
      setEmail(profile.profile.user.email);
      setPhone(profile.profile.phone);
      setAddress(
        `${profile.profile.address.street} ${profile.profile.address.number}, ${profile.profile.address.city}, ${profile.profile.address.postalCode}, ${profile.profile.address.country}`
      );
    }
  }, [profile, dispatch]);

  useEffect(() => {
    if (!isEmpty(payment.payment)) {
      if (payment.payment.purchaseCode) {
        (async () => {
          const { error, paymentIntent } = await stripe.confirmCardPayment(
            payment.payment.purchaseCode,
            {
              payment_method: {
                card: elements.getElement(CardElement),
              },
            }
          );
          if (error) {
            setTimeout(() => {
              M.toast({
                html:
                  'Ha habído un error con tu pago. Por favor intentalo de nuevo! ',
                classes: 'red white-text',
              });
              setAllowBuyClick(true);
            }, 7000);
          } else {
            if (paymentIntent.status === 'succeeded') {
              setAllowBuyClick(false);
              handleActiveStep('4');
            }
          }
        })();
      }
    }
  }, [payment, handleActiveStep, elements, stripe]);

  const handleTextChange = useCallback((e, field) => {
    switch (field) {
      case 'name':
        setFullName(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'phone':
        setPhone(e.target.value);
        break;
      default:
        break;
    }
  }, []);

  const handleBuyBtn = useCallback(() => {
    setAllowBuyClick(false);
    const data = {
      tosAgreed,
    };
    dispatch(createNewAppointment(data));
    setTimeout(() => {
      dispatch(createAppointment(appointment.newAppointment));
    }, 2000);
  }, [dispatch, tosAgreed, appointment]);

  const handleTosCheck = useCallback(() => {
    setTosAgreed(!tosAgreed);
    setAllowBuyClick(true);
  }, [tosAgreed]);

  return (
    <div className='card'>
      <div className='card-content'>
        <div className='row'>
          <div className='col s12 m6'>
            <div className='row'>
              <div className='input-field col s12'>
                <input
                  disabled
                  type='text'
                  name='nameP'
                  id='nameP'
                  className='validate'
                  defaultValue={fullName}
                  onChange={(e) => handleTextChange(e, 'name')}
                />
                <label htmlFor='nameP'>Nombre Completo</label>
              </div>
              <div className='input-field col s12'>
                <input
                  disabled
                  type='email'
                  name='emailP'
                  id='emailP'
                  className='validate'
                  defaultValue={email}
                  onChange={(e) => handleTextChange(e, 'email')}
                />
                <label htmlFor='emailP'>Email</label>
              </div>
              <div className='input-field col s12'>
                <input
                  disabled
                  type='text'
                  name='phoneP'
                  id='phoneP'
                  className='validate'
                  maxLength={9}
                  defaultValue={phone}
                  onChange={(e) => handleTextChange(e, 'phone')}
                />
                <label htmlFor='phoneP'>Telefono</label>
              </div>
              <div className='col s12 direccion-label'>
                <h6>Direccion</h6>
              </div>
              <div className='col s12 step3-location'>
                <p>
                  <label>
                    <input type='checkbox' checked={true} readOnly />
                    <span>{address}</span>
                  </label>
                </p>
                <p>
                  <span className='grey-text'>
                    Si quieres actualizar tus datos pulsa{' '}
                    <Link to='/dashboard'>aqui</Link>
                  </span>
                </p>
              </div>
              <div className='row'>
                <div className='col s12 buy-wrapper'>
                  <p>Datos tarjeta credito/debito</p>
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#424770',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#9e2146',
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col s12 m6'>
            <div className='pay-info'>
              <div>
                <p>TOTAL</p>
              </div>
              <div>
                <p>Precio base</p>
                <p>41.32€</p>
              </div>
              <div>
                <p>{`Impuestos (21%)`}</p>
                <p>8.68€</p>
              </div>
              <div>
                <p>Precio total</p>
                <p>50€</p>
              </div>
              <div>
                <p>
                  Todos los pagos estan procesados por una pasarela de pago
                  seguro ofrecida por STRIPE con un cifrado SSL. Los datos de la
                  tarjeta bancaria nunca se guardaran bajo ningun concepto en
                  nuestros servidores. Si tiene alguna duda sobre la proteccion
                  de datos o el proceso de pago por favor lea detenidamente las
                  <Link to='/legal'> Condiciones de servicio</Link>.
                </p>
              </div>
            </div>
            <div className='pay-logos'>
              <img src={visa} alt='visa-logo' />
              <img src={masterCard} alt='mastercard-logo' />
              <img src={stripeLogo} alt='stripe-logo' />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col s12 center'>
            <p>
              <label>
                <input
                  type='checkbox'
                  checked={tosAgreed}
                  onChange={handleTosCheck}
                />
                <span>
                  He leido y estoy de acuerdo con los{' '}
                  <Link to='/legal'>Terminos de Servicio</Link>.
                </span>
              </label>
            </p>
            <button
              disabled={tosAgreed && allowBuyClick ? false : true}
              className={
                tosAgreed
                  ? 'btn-flat white-text blue buy-btn'
                  : 'btn-flat white-text grey buy-btn'
              }
              onClick={handleBuyBtn}
            >
              comprar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
